import { render, staticRenderFns } from "./soft-feedback-dialog.vue?vue&type=template&id=a605d894&scoped=true&"
import script from "./soft-feedback-dialog.vue?vue&type=script&lang=js&"
export * from "./soft-feedback-dialog.vue?vue&type=script&lang=js&"
import style0 from "./soft-feedback-dialog.vue?vue&type=style&index=0&id=a605d894&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a605d894",
  null
  
)

export default component.exports