const BRAND_WORD = {
  //查询关键词
  getBrandWords: "/api/v1/brand-words/",
  //取消品牌词
  deleteBrandWordsCancel: "/api/v1/brand-words/cancel",
  // 重置品牌词
  putBrandWordsReset: "/api/v1/brand-words/reset",
  //获取词元素列表
  getWordElements: "/api/v1/word-elements",
  //自动过滤开关
  putWordElementsFiltered: "/api/v1/word-elements/{id}/filtered/{status}",
  //自动过滤开关
  putWordElementsAccuracy: "/api/v1/word-elements/{id}/accuracy/{status}",
  //刷新关键词数
  postWordElementsRefresh: "/api/v1/word-elements/refresh",
  //品类列表
  getKeywordsTradeName: "/api/v1/keywords/trade-names",
  //获取备注列表
  getKeywordsRemarks: "/api/v1/keywords/trades/labels",
  //导出
  exportWordElementExcel: "/api/v1/word-elements/excel",
  //批量翻译
  putBatchTranslate: "/api/v1/word-elements/translate/batch",
  // 批量判定不可用
  putBatchUnavailable: "/api/v1/word-elements/filtered/batch",
};
export default BRAND_WORD;
