import eventBus from "@/common/js/eventBus";

const component = eventBus;

export const prompt = {
  // 普通消息
  infoMsg(message) {
    component.$message({
      showClose: true,
      message: message,
      type: "info",
    });
  },

  // 成功消息
  successMsg(message) {
    component.$message({
      showClose: true,
      message: message,
      type: "success",
    });
  },

  // 警告消息
  warnMsg(message) {
    component.$message({
      showClose: true,
      message: message,
      type: "warning",
    });
  },

  // 错误消息
  errorMsg(error) {
    component.$message({
      showClose: true,
      message: error,
      type: "error",
    });
  },
};
