import productGroup from "./group";
import photoBank from "./photoBank";
import published from "./published";
import banner from "./banner";
import detailReplace from "./detailReplace";
import optimization from "./optimization";
import newProduct from "./newProduct";
import attribute from "./attribute";
import template from "./template";
import detailTemplate from "./detail-template";
import collectProducts from "./collect-products";

const PRODUCT_API = {
  collectProducts,
  productGroup,
  photoBank,
  published,
  banner,
  detailReplace,
  optimization,
  newProduct,
  attribute,
  template,
  detailTemplate,
};

export default PRODUCT_API;
