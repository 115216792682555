// let EXTENSION_ID;
// if (process.env.NODE_ENV === "production") {
//   EXTENSION_ID = "eiloldiefkfjmhfcoghlddibiopnfmco";
// } else {
//   EXTENSION_ID = "blkcdlehldfaholjamckmnoobmjidhfk";
// }
import { MessageBox } from "element-ui";
import reportApi from "@/pages/index/api/report-conclusion";
import _ from "lodash";

const URL =
  "https://docs.qq.com/doc/p/426a652d3f2fe5a76ed97e2e232dff78f99253e7";
const PLUGIN_MESSAGE = "插件版本过低，请升级到新版";
const PLUGIN_PROMPT =
  "未检测到插件，一些功能将无法正常使用！若已安装插件,请刷新页面后重试（window操作系统：按CTRL + F5; Mac系统：按Shift + Command + R)";
const PLUGIN_OTHER_PROMPT = "检测到您当前插件的版本较低，建议您安装最新版本！";

let chromeExtensionId = localStorage.getItem("ai-plugin-id");
let href = window.location.href;
console.log("chromeExtensionId:", chromeExtensionId);

/**
 * 不检测，插件type为loginCheck ,imageShieldCheck
 */
const pluginTypes = ["loginCheck", "imageShieldCheck"];

const throttleNoPlugin = _.throttle((prompt) => {
  return MessageBox.confirm(prompt, "提示", {
    confirmButtonText: "立即安装",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      return window.open(URL, "_blank");
    })
    .catch(() => {
      return undefined;
    });
}, 2000);

function noPluginPrompt(prompt) {
  return throttleNoPlugin(prompt);
}

export function getNewPluginVersion() {
  return reportApi.getLatestVersion().then((res) => {
    return res.data.data;
  });
}

// async function diffPluginVersion(res) {
//     const version = await getPluginVersion();
//     console.log("plugin version:", version);
//     let versionCopy = version;
//     res = res.value;
//     let resCopy = res;
//     versionCopy = versionCopy.split(".").join("");
//     resCopy = resCopy.split(".").join("");
//     if (resCopy < versionCopy) {
//         return MessageBox.confirm(
//             `当前插件版本为${res}，检测到您当前插件的版本较低，建议您更新到最新版本${version}。` +
//             `<a href=${URL} target="_blank" style="color: #3da8f5">点击前往安装</a>`,
//             "提示",
//             {
//                 dangerouslyUseHTMLString: true,
//                 confirmButtonText: "执行",
//                 cancelButtonText: "取消",
//                 type: "warning"
//             }
//         ).then(() => {
//             return true;
//         }).catch(() => {
//             return undefined;
//         })
//     } else {
//         return true;
//     }
// }

export async function checkBookmarks() {
  const message = {
    type: "check_bookmarks",
  };
  return new Promise((resolve) => {
    try {
      window.chrome.runtime.sendMessage(
        chromeExtensionId,
        Object.assign(message, { href }),
        (res) => {
          if (res && res.success) {
            resolve(true);
          }
          resolve(false);
        }
      );
    } catch (e) {
      resolve(false);
    }
  });
}
export async function getCurrentPluginVersion() {
  const message = {
    type: "getVersion",
  };
  return new Promise((resolve) => {
    try {
      window.chrome.runtime.sendMessage(
        chromeExtensionId,
        Object.assign(message, { href }),
        (res) => {
          if (res && res.success) {
            resolve(res.value);
          }
          resolve(false);
        }
      );
    } catch (e) {
      resolve(false);
    }
  });
}

export async function isUsePlugin() {
  const result = await getCurrentPluginVersion();
  if (result) {
    return result;
  }
  return noPluginPrompt(PLUGIN_PROMPT);
}

export const sendMessage = async (message, callback, notRuntineCallback) => {
  if (!chromeExtensionId) {
    return noPluginPrompt(PLUGIN_PROMPT);
  }
  if (window.chrome.runtime) {
    if (pluginTypes.includes(message.type)) {
      window.chrome.runtime.sendMessage(
        chromeExtensionId,
        Object.assign(message, { href }),
        (res) => {
          callback(res);
        }
      );
    } else {
      const result = await isUsePlugin();
      if (result) {
        // let queryMessage = message;
        window.chrome.runtime.sendMessage(
          chromeExtensionId,
          Object.assign(message, { href }),
          (res) => {
            // const uniqueId = `id-${Math.random()
            //   .toString(36)
            //   .substr(2, 9)}-${new Date().getTime()}`;
            // console.log(`${uniqueId} chrome extension request: `, queryMessage);
            const { message, success } = res;
            // console.log(`${uniqueId} chrome extension result: `, res);
            if (!success && message === PLUGIN_MESSAGE) {
              callback(noPluginPrompt(PLUGIN_OTHER_PROMPT));
            } else {
              callback(res);
            }
          }
        );
      } else {
        callback(undefined);
      }
    }
  } else if (notRuntineCallback) {
    notRuntineCallback();
  }
};
