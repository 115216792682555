const ROUTER_TYPES = {
  PUBLISHED_PRODUCT: "published-product",
  BANNER_MANAGE: "banner-manage",
  NEW_PRODUCT: "new-product",
  PRODUCT_ATTRIBUTE: "product-attribute",
  DETAIL_TEMPLATE: "detail-template",
  PHOTO_BANK: "photo-bank",
  VIDEO_BANK: "video-bank",
  PHOTO_SHOP: "photo-shop",
  PRODUCT_GROUP: "product-group",
  RELEASE_RECORD: "release-record",
};

const PUBLISHED_PRODUCT = {
  name: "已发布产品",
  path: `/${ROUTER_TYPES.PUBLISHED_PRODUCT}`,
};

const BANNER_MANAGE = {
  name: "爆款关联",
  path: `/${ROUTER_TYPES.BANNER_MANAGE}`,
};

const NEW_PRODUCT = {
  name: "待发布产品",
  path: `/${ROUTER_TYPES.NEW_PRODUCT}`,
};

const DETAIL_TEMPLATE = {
  name: "详情模板",
  path: `/${ROUTER_TYPES.DETAIL_TEMPLATE}`,
};

const PHOTO_BANK = {
  name: "图片银行",
  path: `/${ROUTER_TYPES.PHOTO_BANK}`,
};
const VIDEO_BANK = {
  name: "视频银行",
  path: `/${ROUTER_TYPES.VIDEO_BANK}`,
};
const PHOTO_SHOP = {
  name: "图片处理",
  path: `/${ROUTER_TYPES.PHOTO_SHOP}`,
};

const PRODUCT_GROUP = {
  name: "产品分组",
  path: `/${ROUTER_TYPES.PRODUCT_GROUP}`,
};

const RELEASE_RECORD = {
  name: "发品记录",
  path: `/${ROUTER_TYPES.RELEASE_RECORD}`,
};

const types = {
  NEW_PRODUCT,
  PUBLISHED_PRODUCT,
  PRODUCT_GROUP,
  DETAIL_TEMPLATE,
  PHOTO_BANK,
  PHOTO_SHOP,
  VIDEO_BANK,
  BANNER_MANAGE,
  RELEASE_RECORD,
};

export { ROUTER_TYPES };
export default types;
