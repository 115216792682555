const state = {
  showAside: true,
  showHeader: true,
  account: {
    diagnosisRunning: false,
    accountType: "",
    id: null,
    init: false,
    keywordChannel: {
      hotWord: true,
      myWord: true,
      p4pSearch: true,
    },
    name: "",
    nick: null,
    hyperlinkHost: "1",
    promotionInfo: {
      status: true,
      startWeek: 1,
      startHour: 0,
      endWeek: 7,
      endHour: 24,
    },
    qq: "",
    telephone: "",
    bookmarkAdded: false,
  },
  /**
   * 贴牌信息
   */
  marketingApp: {},
  globalStatus: {
    globalLoad: false,
  },
  currentPluginVersion: "",
  newPluginVersion: "",
  product: {
    logisticsPropertyOptions: [],
    boxPackagingOptions: [],

    currentProductColletConfig: "",
  },
};

export default state;
