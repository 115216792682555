import { isDef, isDate } from "@/common/js/utils";

const Time = {
  //获取当前时间戳
  getUnix: function () {
    let date = new Date();
    return date.getTime();
  },
  //获取今天 0 点 0分 0秒的时间戳
  getTodayUnix: function (date = new Date()) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  },
  //获取今年 1月 1 日 0点 0分 0秒的时间戳
  getYearUnix: function () {
    let date = new Date();
    date.setMonth(0);
    date.setDate(0);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  },
  //获取标准年月日
  getFormatDate: function (date = new Date()) {
    if (!isDef(date)) return "";
    if (!isDate(date)) date = new Date(date);
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return date.getFullYear() + "-" + month + "-" + day;
  },
  // 获取datetime
  getFormatDateTime: function (date = new Date(), ingnorSecond = false) {
    const FLAG = "00";
    if (!isDef(date)) return "";
    if (!isDate(date)) date = new Date(date);
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let seconds =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    let time =
      hour === FLAG && minutes === FLAG && seconds === FLAG
        ? ""
        : seconds === FLAG || ingnorSecond
        ? [hour, minutes].join(":")
        : [hour, minutes, seconds].join(":");
    return date.getFullYear() + "-" + month + "-" + day + " " + time;
  },
  getFormatTime: function (timestamp) {
    let now = this.getUnix(); //当前时间戳
    let today = +this.getTodayUnix(); //今天 0 点时间戳
    // let year = this.getYearUnix();  //今年 0 点时间戳
    let timer = (now - timestamp) / 1000; // 转换为秒级时间戳
    let tip = "";
    if (timer <= 0) {
      tip = "刚刚";
    } else if (Math.floor(timer / 60) <= 0) {
      tip = "刚刚";
    } else if (timer < 3600) {
      tip = Math.floor(timer / 60) + "分钟前";
    } else if (timer >= 3600 && timestamp - today >= 0) {
      tip = Math.floor(timer / 3600) + "小时前";
    } else if (timer / 86400 <= 31) {
      tip = Math.ceil(timer / 86400) + "天前";
    } else {
      tip = this.getFormatDate(timestamp);
    }
    return tip;
  },
  // 每个月有几个完整的周
  getWeekOfMonth() {
    const arr = [];
    let time = new Date();
    time.setDate(1);
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let date = time.getDate();
    let day = time.getDay();

    let diff = day === 0 ? 1 : 7 - day;
    let currDay = date + diff;
    time.setDate(currDay);

    // 判断是否下一年或者前一年
    y = m > 11 ? y + 1 : m < 0 ? y - 1 : y;
    // 判断是否下一个月或者前一个月
    m = m > 11 ? 0 : m < 0 ? 11 : m;
    m -= 1;

    // 获取当月最后一天
    const lastDay = new Date(new Date(y, m, 1) - 1).getDate();

    while (currDay + 6 <= lastDay) {
      arr.push([new Date(y, m, currDay), new Date(y, m, currDay + 6)]);
      currDay += 7;
    }

    return arr;
  },
  //获取前1~4周
  getFourWeeksAgo() {
    let arr = [];
    let now = new Date();
    let dayOfWeek = now.getDay();
    let endDay = new Date(
      now.getTime() -
        3600 * 1000 * 24 * (dayOfWeek < 2 ? dayOfWeek + 8 : dayOfWeek + 1)
    );
    for (let i = 0; i < 4; i++) {
      arr.push([
        new Date(endDay.getTime() - 3600 * 1000 * 24 * (6 + 7 * i)),
        new Date(endDay.getTime() - 3600 * 1000 * 24 * 7 * i),
      ]);
    }
    return arr;
  },
};

export default Time;
