<template>
  <section class="checkbox-group">
    <el-checkbox
      v-for="(country, index) in countryList"
      :key="index"
      class="checkbox-item"
      :label="country.label"
    >
      <span>{{ country.label }}</span>
    </el-checkbox>
  </section>
</template>

<script>
export default {
  name: "CountryTabs",
  props: {
    optionsCountry: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      countryList: [],
    };
  },
  watch: {
    optionsCountry: {
      handler(val) {
        this.countryList = val;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.checkbox-group {
  display flex
  flex-wrap wrap
  width 100%
}
.checkbox-item {
  display flex
  align-items center
  width 25%
  margin .2rem 0
  padding 0

  span {
    display inline-block
    width 8rem
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
  }
}
</style>
