import Axios from "@/common/js/ajax";

export default {
  // 搜索榜单产品
  searchRankProducts(q) {
    return Axios({
      url: "/api/v1/meili/rank-products/search",
      method: "post",
      params: {
        q,
      },
    });
  },
};
