import request from "common/utils/request";
import notify from "@/common/js/notify";

const inviteUsers = {
  getHistoryInviteList() {
    return request({
      url: "/api/v1/invite-records",
      method: "get",
    });
  },
  getBuyAiPackage(params) {
    return request({
      url: "/api/v1/ali-pay/trade-page-pay",
      method: "get",
      params,
    });
  },
  getPayPageTest: function () {
    return request({
      url: "/api/v1/ali-pay/trade-page-pay",
      method: "get",
      params: {
        type: "ImageTranslation_500",
      },
    }).then((res) => {
      //支付宝支付
      if (res.status === 200) {
        // 添加之前先删除一下，如果单页面，页面不刷新，添加进去的内容会一直保留在页面中，二次调用form表单会出错
        let payFormDiv = document.getElementById("ali-pay-form-div");
        if (payFormDiv) {
          document.body.removeChild(payFormDiv);
        }
        const div = document.createElement("div");
        div.setAttribute("Id", "ali-pay-form-div");
        div.innerHTML = res.data; // data就是接口返回的form 表单字符串
        document.body.appendChild(div);
        let aliPayForm = document.querySelector("form[name='punchout_form']");
        aliPayForm.setAttribute("target", "_blank");
        aliPayForm.submit();
      } else {
        notify.err("渲染异常,请稍后再试");
      }
    });
  },
};

export default inviteUsers;
