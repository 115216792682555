export const PRODUCT_RELEASE_OR_UPDATE_LIMIT = 200;

export const PRODUCT_PER_RELEASE_LIMIT = PRODUCT_RELEASE_OR_UPDATE_LIMIT;

export const PRODUCT_PER_UPDATE_LIMIT = PRODUCT_RELEASE_OR_UPDATE_LIMIT;

export const GPT_SUBJECT_GENERATE_NAME = "gptSubjectGenerate";

export const GPT_SUBJECT_OPTIMIZE_NAME = "gptSubjectOptimize";

export const GPT_CHAT = "gptChat";

export const PLUS_VERSION = "NORMAL";

export const BASIC_VERSION = "BASIC";

export const YEAR_VERSION = "YEAR";

export const SERVICE_QRCODE =
  "https://assets.maoniux.com/images/customer-service-vincent-qrcode.jpg";
