const ROUTER_TYPES = {
  PROMOTION_PLAN_OVERVIEW: "promotion-plan-overview",
  HIGH_TRAFFIC_KEYWORDS: "high-traffic-keywords",
  HIGH_TRAFFIC_PRODUCTS: "high-traffic-products",
  BID_LOG_NEW: "bid-log-new",
};
const PROMOTION_PLAN_OVERVIEW = {
  name: "分时推广和自动竞价",
  path: `/${ROUTER_TYPES.PROMOTION_PLAN_OVERVIEW}`,
};
const HIGH_TRAFFIC_KEYWORDS = {
  name: "高流量关键词",
  path: `/${ROUTER_TYPES.HIGH_TRAFFIC_KEYWORDS}`,
};
const HIGH_TRAFFIC_PRODUCTS = {
  name: "高流量产品",
  path: `/${ROUTER_TYPES.HIGH_TRAFFIC_PRODUCTS}`,
};
const BID_LOG_NEW = {
  name: "出价日志",
  path: `/${ROUTER_TYPES.BID_LOG_NEW}`,
};
const types = {
  PROMOTION_PLAN_OVERVIEW,
  HIGH_TRAFFIC_KEYWORDS,
  HIGH_TRAFFIC_PRODUCTS,
  BID_LOG_NEW,
};
export { ROUTER_TYPES };
export default types;
