import request from "@/common/utils/request";
import axios from "axios";

const PREFIX_URL = "/api/v1/published-products";

const api = {
  // 查询已发布产品
  getProductList(params) {
    return request({
      url: "/api/v1/published-products",
      method: "get",
      params,
    });
  },
  // 查询已发布产品 (新接口，返回标准结构)
  getProductPage(params) {
    return request({
      url: "/api/v1/published-products/page",
      method: "get",
      params,
    });
  },
  getCannotEditedProductPage(params) {
    return request({
      url: "/api/v1/published-products/cannot-edited",
      method: "get",
      params,
    });
  },
  // 搜索参数（分组，类目）
  getSearchItem() {
    return request({
      url: "/api/v1/published-products/search-item",
      method: "get",
    });
  },
  // 获取发布产品所需的图片
  getAllImages(data) {
    return request({
      url: "/api/v1/published-products/get-all-images",
      method: "post",
      data,
    });
  },
  getProductImages(params) {
    return request({
      url: "/api/v1/published-products/proper-images",
      method: "post",
      data: params,
    });
  },
  quotePeerProduct(data) {
    return request({
      url: "/api/v1/published-products/peer-product/quote",
      method: "put",
      data,
    });
  },
  updateFields(data) {
    return request({
      url: "/api/v1/published-products/fields",
      method: "put",
      data,
    });
  },
  // 保存产品发布所需的图片信息
  saveProductUploadImages(data) {
    return request({
      url: "/api/v1/published-products/uploaded-images",
      method: "post",
      data,
    });
  },
  // 删除产品
  delete(data) {
    return axios({
      url: "/api/v1/published-products",
      method: "delete",
      data,
    });
  },
  // 下架产品
  offShelf(data) {
    return axios({
      url: "/api/v1/published-products/status",
      method: "put",
      data,
    });
  },
  updateShelf(data, status) {
    return axios({
      url: `/api/v1/published-products/status/${status}`,
      method: "put",
      data,
    });
  },
  // 保存图片
  saveImage(data) {
    return request({
      url: "/api/v1/published-products/images",
      method: "post",
      data,
    });
  },
  imageNewOne(params) {
    return request({
      url: `/api/v1/published-products/${params.productId}/images/one`,
      method: "get",
      params: {
        first: params.first,
      },
    });
  },
  acRefreshDescription(data) {
    return request({
      url: "/api/v1/products/descriptions",
      method: "post",
      data,
    });
  },
  refreshDescription(params) {
    return request({
      url: "/api/v1/published-products/descriptions",
      method: "get",
      params,
    });
  },
  // 检查类目是否相同
  checkCategory(data) {
    return request({
      url: "/api/v1/published-products/isSameCategory",
      method: "post",
      data,
    });
  },
  // 批量修改标题关键词
  modifyTitleAndKeyword(data) {
    return request({
      url: "/api/v1/published-products/title-keyword",
      method: "put",
      data,
    });
  },
  // 类目id获取类目属性
  getCategoryAttribute(cid) {
    return request({
      url: "/api/v1/published-products/category-attribute",
      method: "get",
      params: { categoryId: cid },
    });
  },
  //类目id获取类目schema
  getCategorySchema(cid) {
    return request({
      url: "/api/v1/published-products/schema",
      method: "get",
      params: { categoryId: cid },
    });
  },
  checkProductType(data) {
    return request({
      url: "/api/v1/published-products/product-type",
      method: "post",
      data,
    });
  },
  // 批量修改类目属性
  modifyCategoryAttribute(data) {
    return request({
      url: "/api/v1/published-products/category-attribute",
      method: "put",
      data,
    });
  },
  // 批量修改其他信息
  modifyOtherInformation(data) {
    return request({
      url: "/api/v1/published-products/other-information",
      method: "put",
      data,
    });
  },
  // 批量修改(类目和其他一起)
  modifyAll(data, params) {
    return request({
      url: "/api/v1/published-products/edit",
      method: "put",
      data,
      params,
    });
  },
  // 获取产品详情
  getDetail(id) {
    return request({
      url: "/api/v1/published-products/detail",
      method: "get",
      params: { productId: id },
    });
  },
  // 修改产品详情
  modifyProductDetail(data) {
    return request({
      url: "/api/v1/published-products/detail",
      method: "put",
      data,
    });
  },
  // 批量修改备注
  setRemark(data) {
    return request({
      url: "/api/v1/published-products/remark",
      method: "put",
      data,
    });
  },
  // 爆款关联
  bannerAssociate(data) {
    return request({
      url: "/api/v1/published-products/banner",
      method: "put",
      data,
    });
  },
  bannerCancel(data) {
    return request({
      url: "/api/v1/published-products/banner",
      method: "delete",
      data,
    });
  },
  // 运费模板
  getShippingLine(params) {
    return request({
      url: "/api/v1/published-products/shipping-line",
      method: "get",
      params,
    });
  },
  /**
   *
   * @param {{categoryId}} params
   * @returns
   */
  getBoxPackagingOptions(params) {
    return request({
      url: `/api/v1/published/products/box-packaging/options`,
      method: "get",
      params,
    });
  },
  // 查询选中产品中最早的同步时间
  getEarliestDate(params) {
    return request({
      url: "/api/v1/published-products/modified-time",
      method: "get",
      params,
    });
  },
  //导出excel
  exportByStream(data) {
    return request({
      url: "/api/v1/published-products/excel-stream",
      method: "post",
      responseType: "arraybuffer",
      data: data,
    });
  },
  //导入excel
  importExcel: "/api/v1/published-products/excels",
  updateSubject(data) {
    return request({
      url: "/api/v1/published-products/titles",
      method: "put",
      data: data,
    });
  },
  updateSubjectByAliId(data) {
    return request({
      url: "/api/v1/published-products/titles",
      method: "put",
      data: data,
    });
  },
  // 添加到属性表
  addToAttribute(data) {
    return request({
      url: "/api/v1/published-products/attributes",
      method: "post",
      data,
    });
  },
  // 自动转换价格
  convertPrice(data) {
    return request({
      url: "/api/v1/published-products/prices/fob-to-ladder",
      method: "put",
      data,
    });
  },
  // 产品属性更新
  updateProductAttribute(data) {
    return request({
      url: "/api/v1/published-products/attributes",
      method: "put",
      data,
    });
  },
  //刷新主图
  refreshImage(data) {
    return request({
      url: "/api/v1/published-products/images",
      method: "put",
      data,
    });
  },
  // 刷新视频
  refreshVideo(data) {
    return request({
      url: "/api/v1/published-products/videos/refresh",
      method: "put",
      data,
    });
  },
  // 获取产品id
  getProductId(params) {
    return request({
      url: "/api/v1/published-products/ids",
      method: "get",
      params,
    });
  },
  // 将智能编辑转化为非智能编辑
  convertSmartEdit(data) {
    return request({
      url: "/api/v1/published-products/descriptions/smart-edit",
      method: "put",
      data,
    });
  },
  // 产品详情更新
  updateProductDescription(data) {
    return request({
      url: "/api/v1/published-products/descriptions",
      method: "put",
      data,
    });
  },
  // 发布相似产品
  createNewOldProduct(data) {
    return request({
      url: `${PREFIX_URL}/to/products`,
      method: "post",
      data,
    });
  },
  // 关联视频
  relateVideos(data) {
    return request({
      url: "/api/v1/published-products/videos",
      method: "put",
      data,
    });
  },
  // 同步视频到阿里
  updateVideoToAli(data) {
    return request({
      url: "/api/v1/published-products/videos/toAli",
      method: "put",
      data,
    });
  },
  // AI处理零效果 未下架150天
  handleNoEffectProduct(data) {
    return request({
      url: "/api/v1/published-products/no-effect",
      method: "post",
      data,
    });
  },
  // AI处理零效果 已下架180天
  handleNoEffectUnderProduct(data) {
    return request({
      url: "/api/v1/published-products/no-effect-180",
      method: "post",
      data,
    });
  },
  // 标记信息 设置备注
  setRemark2(data) {
    return request({
      url: "/api/v1/published-products/remark2",
      method: "put",
      data,
    });
  },
  getCategoryWithId() {
    return request({
      url: "/api/v1/published-products/categories-with-id",
      method: "get",
    });
  },
  // 同步到阿里 ws
  syncToAli: "/api/v1/published-products/update",
  // 同步产品数据 ws
  syncPublishedProduct: "/api/v1/published-products/sync",
  // 发布类似
  postProducts(id) {
    return request({
      url: `/api/v1/published-products/to/products/${id}`,
      method: "post",
    });
  },
  // 同步近期发布的阿里产品信息
  putSyncRecentProduct() {
    return request({
      url: `/api/v1/published-products/published-products/sync-recent`,
      method: "put",
    });
  },
  // 获取最近发布的产品个数
  getRecentPublishProductCount() {
    return request({
      url: `/api/v1/published-products/get-recent-published-detail`,
      method: "get",
    });
  },
  // 获取采集品货源信息
  getCollectProductSourceInfos() {
    return request({
      url: `/api/v1/published-products/source`,
      method: "get",
    });
  },
  // 保存采集品店铺信息
  postCollectProductSourceInfos(data) {
    return request({
      url: `/api/v1/published-products/webproduct-company-info`,
      method: "post",
      data,
    });
  },
  // 获取产品质量分数据
  postPublishProductScoreInfos(data) {
    return request({
      url: `/api/v1/published-products/product-score-infos`,
      method: "post",
      data,
    });
  },
  // 保存产品质量分数据
  putPublishProductScoreInfos(data) {
    return request({
      url: `/api/v1/published-products/product-score-infos`,
      method: "put",
      data,
    });
  },
  // 批量修改产品型号
  putPublishedProductModel(data) {
    return request({
      url: `/api/v1/published-products/product-model`,
      method: "put",
      data,
    });
  },
  rtsToCustomized(data) {
    return request({
      url: `/api/v1/published-products/rts-to-customize`,
      method: "put",
      data,
    });
  },
  bindMultiBanners(data) {
    return request({
      url: `/api/v1/published-products/description/banners`,
      method: "put",
      data,
    });
  },
  gptSubjectOptimize(id) {
    return request({
      url: `/api/v1/published-products/${id}/subject/gpt-optimize`,
      method: "post",
    });
  },
  updateTradeableSourcing(data) {
    return request({
      url: PREFIX_URL + "/trade-type/tradeable-sourcing",
      method: "put",
      data,
    });
  },
};

export default api;
