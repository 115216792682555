import KEYWORD from "./keyword";
import ELIMINATE_KEYWORD from "./eliminate-keyword";
import CORE_WORD from "./core-word";
import BRAND_WORD from "./brand-word";
import CATEGORY_ELEMENT from "./category-element";
import RECORD from "./peer-keyword";

export const KEYWORD_CONTROLLER = {
  ...KEYWORD,
  ...ELIMINATE_KEYWORD,
  ...CORE_WORD,
  ...BRAND_WORD,
  ...CATEGORY_ELEMENT,
  ...RECORD,
};
