export const PRODUCT_RELEASE_DETAIL_VIDEO_FAST_VERSION =
  "https://assets.maoniux.com/videos/%E4%BA%A7%E5%93%81%E5%8F%91%E5%B8%83%E8%AE%B2%E8%A7%A3%E5%8A%A0%E9%80%9F%E7%89%88.mp4";

export const PRODUCT_RELEASE_AI_HELPER_VERSION =
  "https://assets.maoniux.com/videos/ai-product-helper.mp4";

export const AI_SUPERMAN_VIDEO_FULL_VERSION =
  "https://assets.maoniux.com/videos/AI%E6%93%8D%E7%9B%98%E6%89%8B%E5%AE%8C%E6%95%B4%E7%89%88.mp4";

// 最新版教程视频  2023-12-11 10:56:34
export const AI_SUPERMAN_VIDEO_ARTIFACT_VERSION =
  "https://assets.maoniux.com/videos/AI%E6%93%8D%E7%9B%98%E5%9B%BD%E9%99%85%E7%AB%99%E7%A5%9E%E5%99%A8.mp4";
