import {
  newTypes,
  ROUTER_TYPES as KEYWORD_TYPES,
} from "@/pages/keyword/router/router-types";
import index from "@/pages/index/router/router-types";
import product from "@/pages/product/router/router-types";
// import showcase from "pages/showcase/router/router-types";
import dataReport from "@/pages/data-report/router/router-types";
import p4pNew from "@/pages/p4p-new/router/router-types";

export const PAGES = [
  {
    name: "AI首页",
    href: "/index.html",
    isNew: true,
    till: "2019-12-01",
    children: index,
  },
  { name: "教程", href: "//www.maoniunet.com/help", target: "_blank" },
  {
    name: "数据",
    href: "/data-report.html",
    isNew: true,
    till: "2023-05-01",
    children: dataReport,
  },
  {
    name: "关键词",
    href: "/keyword.html#/" + KEYWORD_TYPES.KEYWORD_LIBRARY,
    isNew: true,
    till: "2019-12-01",
    children: newTypes,
  },
  {
    name: "产品",
    href: "/product.html",
    children: product,
  },
  // {
  //   name: "橱窗",
  //   href: "/showcase.html",
  //   children: showcase,
  // },

  {
    name: "直通车",
    href: "/p4p-new.html",
    isNew: true,
    till: "2019-12-01",
    children: p4pNew,
  },
  // {
  //   name: "直通车(旧)",
  //   href: "/p4p.html",
  //   isNew: true,
  //   till: "2019-12-01",
  //   children: p4p
  // }
];
