import axios from "axios";
// import Logan from "common/utils/logan";
import qs from "qs";

// 创建axios实例
const service = axios.create({});

// request拦截器(暂时没用)
service.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      // get请求，且params是数组如arr=[1,2],则转换成arr=1&arr=2
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    if (response.status >= 300) {
      return Promise.reject(response);
    } else {
      return response;
    }
  },
  (error) => {
    console.log({ error });
    return Promise.reject(error);
  }
);

export default service;
