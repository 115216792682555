import videoPlayerDialog from "@/pages/product/views/video-bank/dialog/videoPlayerDialog.vue";
import { AI_SUPERMAN_VIDEO_ARTIFACT_VERSION } from "@/common/const/video-course";

export default {
  components: { videoPlayerDialog },

  data() {
    return {
      showVideoPlayerDialog: false,
      videoPath: AI_SUPERMAN_VIDEO_ARTIFACT_VERSION,
    };
  },
};
