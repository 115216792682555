import { AbilityBuilder } from "@casl/ability";
import permission from "./permission";
import * as role from "./role";

function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }

  return item.vip;
}

export default AbilityBuilder.define(
  {
    subjectName,
  },
  (can) => {
    can(permission[role.VIP], role.VIP);
    can(permission[role.CUSTOMER], role.CUSTOMER);
  }
);
