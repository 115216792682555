import { KEYWORD_CONTROLLER } from "@/pages/keyword/api";
// import { P4P_CONTROLLER } from "@/pages/p4p/api";
import PRODUCT_API from "@/pages/product/api/index";
import { DATA_ANALYSIS } from "pages/index/api";
import DATA_REPORT_API from "@/pages/data-report/api";
import { TASK_CONTROLLER } from "./task-controller";
import { USER_CONTROLLER } from "./user-controller";
import WS_CONTROLLER from "./ws-controller";
import { SOFT_FEEDBACK_CONTROLLER } from "./soft-feedback-controller";
// import api from "@/pages/p4p-new/api";

const Api = {
  ...KEYWORD_CONTROLLER,
  // ...P4P_CONTROLLER,
  ...PRODUCT_API,
  ...DATA_ANALYSIS,
  ...TASK_CONTROLLER,
  ...USER_CONTROLLER,
  ...WS_CONTROLLER,
  ...SOFT_FEEDBACK_CONTROLLER,
  ...DATA_REPORT_API,
};

export default Api;
