import api from "@/api";
export const initLogisticsPropertyOptionsAction = (context) => {
  const allLogisticsProperty = localStorage.getItem("allLogisticsProperty");
  if (allLogisticsProperty) {
    context.commit(
      "updateLogisticsPropertyOptions",
      JSON.parse(allLogisticsProperty)
    );
  } else {
    return api.newProduct
      .getAllLogisticsProperty()
      .then((res) => {
        localStorage.setItem("allLogisticsProperty", JSON.stringify(res.data));
        context.commit("updateLogisticsPropertyOptions", res.data);
      })
      .catch((err) => {
        console.log(`获取所有物流属性失败了:${err}`);
      });
  }
};

export const switchShowAside = (context, show) => {
  // let elements = document.querySelectorAll("aside, .tabs");
  // for (let i = 0; i < elements.length; i++) {
  //   elements[i].style.display = show ? "block" : "none";
  // }
  // let wrappers = document.getElementsByClassName("router-wrapper");
  // for (let i = 0; i < wrappers.length; i++) {
  //   wrappers[i].classList.remove("router-wrapper");
  // }
  // const marketElement = document.getElementsByClassName("market");
  // marketElement[0].style.display = "none";
  context.commit("setShowAside", show);
  context.commit("setShowHeader", show);
};

export const setAppHeight = (context, value) => {
  const appNode = document.querySelector("#app");
  appNode.style.height = value;
};
