<template>
  <div>
    <el-descriptions>
      <el-descriptions-item v-if="currentPluginVersion" label="当前版本">{{
        currentPluginVersion
      }}</el-descriptions-item>
      <el-descriptions-item v-if="newPluginVersion" label="最新版本">{{
        newPluginVersion
      }}</el-descriptions-item>
      <!-- <el-descriptions-item label="教程">
        <el-link type="primary" :href="pluginCourseUrl" target="_blank"
          >插件安装教程</el-link
        >
      </el-descriptions-item> -->
    </el-descriptions>
    <!-- <div
      v-if="!currentPluginVersion || currentPluginVersion < newPluginVersion"
    >
      确认插件已安装但没生效的请重新刷新页面
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentPluginVersion", "newPluginVersion"]),
  },
};
</script>
