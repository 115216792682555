const CORE_WORD = {
  // 查询词收集
  getCoreWords: "/api/v1/core-words",
  // 新增词收集
  postCoreWords: "/api/v1/core-words",
  // 编辑词收集
  putCoreWords: "/api/v1/core-words/{id}",
  // 删除词收集
  deleteCoreWords: "/api/v1/core-words/{id}",
  // 收集词
  postGatherKeywords: "/api/v1/core-words/{id}/keywords/gather",
  // 保存插件抓取的热搜词数据
  postHotWords: "/api/v1/core-words/hot-words",
  // 收集词 websocket
  postCoreWordsKeywordsGather: "/api/v1/core-words/{id}/keywords/gather",
  postCoreWordsKeywordsGatherSubscribe: "/user/{id}/reply/gather",
  // 添加关键词 websocket
  postCoreWordsKeywordsWebsocket: "/api/v1/core-words/keywords/append",
  postCoreWordsKeywordsSubscribe: "/user/{id}/reply/append",
  // 手工添加
  postCoreWordsKeywordsManually: "/api/v1/core-words/{id}/keywords/manually",
  // 覆盖更新（接口必须传递所有参数）
  postCoreWordsAll: "/api/v1/core-words/all",
  // 添加关键词
  postCoreWordsKeywords: "/api/v1/core-words/keywords",
  // 按筛选条件添加关键词
  postCoreWordsKeywordsByParam: "/api/v1/core-words/core-keywords",
  // 导出收集关键词
  getCoreWordsKeywordsExcel: "/api/v1/core-words/keywords/excel",
  // 导出收集关键词 (流)
  getCoreWordsKeywordsExcelStream: "/api/v1/core-words/keywords/excel-stream",
  // 收集词结果
  getCollectedKeywordsCount: "/api/v1/core-words/core-keywords/state/count",
  // 查询收集词(分页)
  getCollectedKeywordsSingleColumn: "/api/v1/core-words/core-keywords",
  getCollectedKeywordsMultiColumn: "/api/v1/core-words/core-keywords/table",
  //导出同行关键词
  postPeerKeywords: "/api/v1/keywords/peerKeywords",
  //保存同行关键词
  savePeerKeywords: "/api/v1/keywords/industry",
  // 词收集-获取收集的词
  getCollectedKeywords: "/api/v1/core-words/collected-keywords",
  // 词收集-获取店铺品牌词
  getShopBrandWords: "/api/v1/core-words/all-brand-words",
  // 词收集-保存不可用词
  saveEliminateKeyword: "/api/v1/core-words/append-keyword/eliminate-keyword",
  // 词收集-保存过滤后的数据
  saveFilterBrandWordKeywordData:
    "/api/v1/core-words/append-keyword/filtered-brand-word-data",
};

export default CORE_WORD;
