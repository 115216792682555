const INTELLIGENT_DIAGNOSIS = {
  // 查看诊断结果
  getDiagnosis: "/api/v1/diagnosis",
  // 一键诊断 (webscoket)
  postDiagnosis: "/api/v1/diagnosis",
  postDiagnosisSubscribeUrl: "/user/{id}/reply/diagnosis",
  getDiagnosisHandle: "/api/v1/diagnosis/handle",
  putDiagnosisHandleStatus: "/api/v1/diagnosis/handle/{status}",
  putDiagnosisExpertStatus: "/api/v1/diagnosis/handle/expert/{status}",
  getDiagnosisHandleOptions: "/api/v1/diagnosis/handle/options",
  getDiagnosisHandleResult: "/api/v1/diagnosis/handle/result",
  putDiagnosisEntryDone: "/api/v1/diagnosis/entry/done",
  putDiagnosisEntriesDone: "/api/v1/diagnosis/entries",
  postDiagnosisHandleEntry: "/api/v1/diagnosis/handle/entry",
};
export default INTELLIGENT_DIAGNOSIS;
