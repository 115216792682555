<!-- by LinHuiMing -->

<template>
  <div class="mark"></div>
</template>

<script>
export default {
  components: {},

  props: {},
  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style lang="stylus" scoped>
.mark
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  background-color hsla(0, 0%, 100%, .9)
  z-index $z-index
  // opacity .05
  width 100%
  height 100%
</style>
