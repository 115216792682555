import request from "@/common/utils/request";
import axios from "axios";
import { getNested } from "@/common/js/utils";

const api = {
  // 待发布列表
  getList(params) {
    return request({
      url: "/api/v1/products",
      method: "get",
      params,
    });
  },
  // 根据型号返回图标列表
  getModelImage(params) {
    return request({
      url: "/api/v1/products/images",
      method: "get",
      params: {
        model: params.model,
      },
    });
  },
  // 获取筛选条件的值
  getOptions() {
    return request({
      url: "/api/v1/products/options-values",
      method: "get",
    });
  },
  // 词数和建议数
  suggestCount(params) {
    return request({
      url: "/api/v1/products/count",
      method: "get",
      params,
    });
  },
  // 批量设置备注
  batchSetRemark(data) {
    return request({
      url: "/api/v1/products/remark",
      method: "put",
      data: data,
    });
  },
  //批量设置品类
  batchSetCategory(data) {
    return request({
      url: "/api/v1/products/tradeName",
      method: "put",
      data: data,
    });
  },
  // 批量编辑
  batchModify(data) {
    return request({
      url: "/api/v1/products/title-keyword",
      method: "put",
      data,
    });
  },
  // 删除
  delete(data) {
    return axios({
      url: "/api/v1/products",
      method: "delete",
      data,
    });
  },
  // 刷新发布建议
  refreshSuggest(data) {
    return request({
      url: "/api/v1/products/refresh",
      method: "post",
      data,
    });
  },
  // 刷新主图
  refreshImage(data) {
    return request({
      url: "/api/v1/products/images",
      method: "put",
      data,
    });
  },
  // 刷新视频
  refreshVideo(data) {
    return request({
      url: "/api/v1/products/videos/refresh",
      method: "put",
      data,
    });
  },
  imageNewOne(params) {
    return request({
      url: `/api/v1/products/${params.productId}/images/one`,
      method: "get",
      params: {
        first: params.first,
      },
    });
  },
  // 保存图片
  saveImage(data) {
    return request({
      url: "/api/v1/products/images",
      method: "post",
      data,
    });
  },
  // 手动设置型号
  setModel(data) {
    return request({
      url: "/api/v1/products/models",
      method: "put",
      data,
    });
  },
  // 自动匹配型号
  setModelAuto(data) {
    return request({
      url: "/api/v1/products/models/auto",
      method: "put",
      data,
    });
  },
  // 刷新标题
  refreshTitle(data) {
    return request({
      url: "/api/v1/products/subjects",
      method: "put",
      data,
    });
  },
  refreshTitleWithGPT(data) {
    return request({
      url: "/api/v1/products/subjects/gpt",
      method: "put",
      data,
    });
  },
  refreshKeywords(data) {
    return request({
      url: "/api/v1/products/keywords",
      method: "put",
      data,
    });
  },
  refreshDescription(params) {
    return request({
      url: "/api/v1/products/descriptions",
      method: "get",
      params,
    });
  },
  acRefreshDescription(data) {
    return request({
      url: "/api/v1/products/descriptions",
      method: "post",
      data,
    });
  },
  translateDescription(data) {
    return request({
      url: "/api/v1/words-translate",
      method: "post",
      data,
    });
  },
  translateTextToEnglish(text) {
    return request({
      url: "/api/v1/words-translate/english-words",
      method: "get",
      params: {
        text,
      },
    });
  },
  // 导入excel
  importExcel: "/api/v1/products/excels",
  // 导出excel
  exportExcel(data) {
    return request({
      url: "/api/v1/products/excel",
      method: "post",
      data,
    });
  },
  // 流导出
  exportByStream(data) {
    return request({
      url: "/api/v1/products/excel-stream",
      method: "post",
      data,
      responseType: "arraybuffer",
    });
  },
  // 检查类目是否相同
  checkCategory(data) {
    return request({
      url: "/api/v1/products/isSameCategory",
      method: "post",
      data,
    });
  },

  // 产品发布
  release(data) {
    return request({
      url: "/api/v1/products/release",
      method: "post",
      data,
    });
  },
  // 新发产品 ws
  releaseProduct: "/api/v1/products/release",
  // 更新发布
  update(data) {
    return request({
      url: `/api/v1/products/${data.id}`,
      method: "put",
      data,
    });
  },
  updateFields(data) {
    return request({
      url: "/api/v1/products/fields",
      method: "put",
      data,
    });
  },
  addNewRecord() {
    return request({
      url: "/api/v1/products",
      method: "post",
    });
  },
  getDetail(id) {
    return request({
      url: "/api/v1/products/detail",
      method: "get",
      params: { productId: id },
    });
  },
  // 修改产品详情
  modifyProductDetail(data) {
    return request({
      url: "/api/v1/products/detail",
      method: "put",
      data,
    });
  },
  duplicateProduct(id) {
    return request({
      url: `/api/v1/products/${id}/duplicate`,
      method: "post",
    });
  },
  batchModifyCategory(data) {
    return request({
      url: `/api/v1/products/category/data`,
      method: "put",
      data,
    });
  },
  getNoCoverKeywords(params) {
    return request({
      url: `/api/v1/products/recommendation-keywords`,
      metho: "get",
      params,
    });
  },
  exportNoCoverKeywords(params) {
    return request({
      url: `/api/v1/products/recommendation-keywords/export`,
      metho: "post",
      params,
      responseType: "arraybuffer",
    });
  },
  aiSubject(data) {
    return request({
      url: `/api/v1/keywords/ai/subject`,
      method: "post",
      data,
    });
  },
  addWebProduct(data) {
    return request({
      url: `/api/v1/products/web-products`,
      method: "post",
      data,
    });
  },
  setCollectProductConfigModelInfo(id, data) {
    return request({
      url: `/api/v1/products/collect-product-config/${id}/left-model-infos`,
      method: "put",
      data,
    });
  },
  getCollectProductConfigModelInfo(id) {
    return request({
      url: `/api/v1/products/collect-product-config/${id}/left-model-infos`,
      method: "get",
    });
  },
  publishNewProduct(data) {
    return request({
      url: `/api/v1/products/newProducts`,
      method: "post",
      data,
    });
  },
  batchCategory(data, params) {
    return request({
      url: `/api/v1/products/edit`,
      method: "put",
      data,
      params,
    });
  },
  batchUpdateDetailTemplate(data) {
    return request({
      url: "/api/v1/products/detailTemplate",
      method: "put",
      data,
    });
  },
  getTemplate(id) {
    return request({
      url: `/api/v1/detail-template/${id}`,
      method: "get",
    });
  },
  addSimilarProducts(data) {
    return request({
      url: "/api/v1/products/similarProducts",
      method: "post",
      data,
    });
  },
  // 关联视频
  relatesVideos(data) {
    return request({
      url: "/api/v1/products/videos",
      method: "put",
      data,
    });
  },
  // 获取发布产品所需的所有图片
  getAllImages(params, data) {
    return request({
      url: "/api/v1/products/get-all-images",
      method: "post",
      params,
      data,
    });
  },
  // 保存产品发布所需的图片信息
  saveProductUploadImages(data) {
    return request({
      url: "/api/v1/products/uploaded-images",
      method: "post",
      data,
    });
  },
  // 获取二级类目
  getCategorySchemaLevel(data) {
    return request({
      url: "/api/v1/products/categorySchemaLevel",
      method: "post",
      data,
    });
  },
  // 查询词收集
  getCoreWords(params) {
    return request({
      url: "/api/v1/core-words",
      method: "get",
      params,
    });
  },
  // 图片翻译
  translateImage(data, uploadImage) {
    return request({
      url: "/api/v1/imageTranslation",
      method: "post",
      data,
      params: {
        uploadImage,
      },
    });
  },
  checkProductType(data) {
    return request({
      url: "/api/v1/products/product-type",
      method: "post",
      data,
    });
  },
  uploadImageByBase64(file) {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return request({
      url: "/api/v1/commons/files",
      method: "post",
      config,
      data: formData,
    });
  },
  quotePeerProduct(data) {
    return request({
      url: "/api/v1/products/peer-product/quote",
      method: "put",
      data,
    });
  },
  getProductImages(params) {
    return request({
      url: "/api/v1/products/proper-images",
      method: "post",
      data: params,
    });
  },
  getStealImageProductImageInfo(data) {
    return request({
      url: "/api/v1/products/images/steal-image/get",
      method: "post",
      data,
    });
  },
  getProductCheckScoreInfo(data) {
    return request({
      url: "/api/v1/products/productScoreInfos/get",
      method: "post",
      data,
    })
      .then((res) => {
        return getNested(res, "data");
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  },
  saveProductScoreInfoDetail(data) {
    return request({
      url: "/api/v1/products/ProductScoreInfoDetail",
      method: "put",
      data,
    });
  },
  modifyProductImageShieldInfo(data) {
    return request({
      url: "/api/v1/products/images/shieldInfo",
      method: "put",
      data,
    });
  },
  // 关键词库数据
  getKeywords(params) {
    return request({
      url: "/api/v1/keywords",
      method: "get",
      params,
    });
  },
  // 翻译当前页关键词
  translateKeywordsByPage(params) {
    return request({
      url: "/api/v1/keywords/translations",
      method: "put",
      params,
    });
  },
  autoDeleteProhibitedWords(data) {
    return request({
      url: "/api/v1/products/removeProhibitedWords",
      method: "put",
      data,
    });
  },
  // 软件功能使用次数
  getUserOperateCount() {
    return request({
      url: `/api/v1/operating-count/count`,
      method: "get",
    });
  },
  // AI资源包使用次数限制
  getFreeAiUseCountLimit(params) {
    return request({
      url: `/api/v1/operating-count/ai-use-count-limit`,
      method: "get",
      params,
    });
  },
  getAllCountryNames() {
    return request({
      url: `/api/v1/products/country-all`,
      method: "get",
    });
  },
  getAllLogisticsProperty() {
    return request({
      url: `/api/v1/products/options/logistics-property`,
      method: "get",
    });
  },
  // 新增采集品配置模板
  postCollectProductConfig(data) {
    return request({
      url: `/api/v1/collect-product-config`,
      method: "post",
      data,
    });
  },
  // 获取采集品配置模板
  getCollectProductConfig() {
    return request({
      url: `/api/v1/collect-product-config`,
      method: "get",
    });
  },
  // 获取单个采集品配置模板
  getCollectProductConfigById(id) {
    return request({
      url: `/api/v1/collect-product-config/${id}`,
      method: "get",
    });
  },
  // 修改采集品配置模板
  putCollectProductConfig(data) {
    return request({
      url: `/api/v1/collect-product-config`,
      method: "put",
      data,
    });
  },
  // 删除采集品配置模板
  deleteCollectProductConfig(id) {
    return request({
      url: `/api/v1/collect-product-config/${id}`,
      method: "delete",
    });
  },
  // 添加常用的国家
  postRecentUseCountry(data) {
    return request({
      url: `/api/v1/products/recent-use-country`,
      method: "post",
      data,
    });
  },
  // 获取近期常用的国家
  getRecentUseCountry() {
    return request({
      url: `api/v1/products/recent-use-country`,
      method: "get",
    });
  },
  // 获取所有国家
  getAllCountry() {
    return request({
      url: `/api/v1/products/country-all`,
      method: "get",
    });
  },
  // 设置爆款关联
  putProductBanner(data) {
    return request({
      url: `/api/v1/products/banner`,
      method: "put",
      data,
    });
  },
  // 删除爆款关联
  deleteProductBanner(data) {
    return request({
      url: `/api/v1/products/banner`,
      method: "delete",
      data,
    });
  },

  getNoEffectProducts() {
    return request({
      url: `/api/v1/products/no-effect`,
      method: "get",
    });
  },
};

export default api;
