import axios from "@/common/js/ajax";

const developApi = {
  updateCurrentUser(nick) {
    return axios({
      url: "/api/v1/develop/current-user",
      params: { nick },
      method: "post",
    });
  },
};

export default developApi;
