import request from "@/common/utils/request";

export default {
  // 添加到ma后台的报告筛选列表
  createMaReport(data) {
    return request({
      url: "https://ma.maoniux.com/api/v1/report",
      method: "POST",
      data,
      withCredentials: false,
    });
  },
  createReportDetail(data) {
    return request({
      url: "/api/v1/report/detail",
      method: "POST",
      data,
    });
  },
  updateReportDetail(id, data) {
    return request({
      url: `/api/v1/report/detail/${id}`,
      method: "PUT",
      data,
    });
  },
  getLastReportDetail(params) {
    return request({
      url: "/api/v1/report/detail/last",
      method: "get",
      params,
    });
  },
  getEmphasisKeyword() {
    return request({
      url: "/api/v1/keywords/emphasis",
      method: "get",
    });
  },
  addRecord(data, params) {
    return request({
      url: "/api/v1/report/detail/record",
      method: "post",
      data,
      params,
    });
  },
  putRecord(data, params) {
    return request({
      url: `/api/v1/report/detail/record`,
      method: "put",
      data,
      params,
    });
  },
  getStatus() {
    return request({
      url: "/api/v1/user/status",
      method: "get",
    });
  },
};
