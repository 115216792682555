const ROUTER_TYPES = {
  AI_HOME_PAGE: "ai-home-page",
  INTELLIGENT_DIAGNOSIS: "ai-landing-page",
  WEEKLY_DATA: "weekly-data",
  INDEX_TASK: "index-task",
  CUSTOM_REPORTS: "custom-reports",
  PEER_ANALYSIS: "peer-analysis",
  SELECT_PRODUCT_ANALYSIS: "selection-analysis",
  CONVERSION_ANALYSIS: "conversion-analysis",
  ADVERTISING: "advertising",
  // REPORT_CONCLUSION: "report-conclusion",
  REPORT: "report",
  // OPERATING_GUIDE: "operating-guide",
  INDUSTRY_HOT_PRODUCTS: "industry-hot-products",
  HOT_PRODUCTS_REPORT: "hot-products-report",
  VIDEO_COURSE: "video-course",

  CREDIT_USAGE: "credit-usage",
};

const AI_HOME_PAGE = {
  name: "AI首页",
  path: `/${ROUTER_TYPES.AI_HOME_PAGE}`,
};

const INTELLIGENT_DIAGNOSIS = {
  name: "核心功能",
  path: `/${ROUTER_TYPES.INTELLIGENT_DIAGNOSIS}`,
};
const VIDEO_COURSE = {
  name: "视频教程",
  path: `/${ROUTER_TYPES.VIDEO_COURSE}`,
};

const CREDIT_USAGE = {
  name: "点数使用明细",
  path: `/${ROUTER_TYPES.CREDIT_USAGE}`,
};

// const INDEX_TASK = {
//   name: "贸牛专家",
//   path: `/${ROUTER_TYPES.INDEX_TASK}`
// };

// const WEEKLY_DATA = {
//   name: "周数据报表",
//   path: `/${ROUTER_TYPES.WEEKLY_DATA}`,
// };

// const PEER_ANALYSIS = {
//   name: "同行分析",
//   path: `/${ROUTER_TYPES.ADVERTISING}`
// };

// const SELECTION_ANALYSIS = {
//   name: "选品分析",
//   path: `/${ROUTER_TYPES.ADVERTISING}`
// };

// const CONVERSION_ANALYSIS = {
//   name: "转化分析",
//   path: `/${ROUTER_TYPES.ADVERTISING}`
// };

// const REPORT_CONCLUSION = {
//   name: "店铺诊断",
//   path: `/${ROUTER_TYPES.REPORT_CONCLUSION}`
// };

// const REPORT = {
//   name: "360店铺诊断",
//   path: `/${ROUTER_TYPES.REPORT}`,
// };
// const INDUSTRY_HOT_PRODUCTS = {
//   name: "同行报告",
//   path: `/${ROUTER_TYPES.INDUSTRY_HOT_PRODUCTS}`,
// };

// const HOT_PRODUCTS_REPORT = {
//   name: "选品报告",
//   path: `/${ROUTER_TYPES.HOT_PRODUCTS_REPORT}`,
// };

// const OPERATING_GUIDE = {
//   name: "操作指南",
//   path: `/${ROUTER_TYPES.OPERATING_GUIDE}`
// };

const types = {
  AI_HOME_PAGE,
  INTELLIGENT_DIAGNOSIS,
  VIDEO_COURSE,
  CREDIT_USAGE,
  // INDEX_TASK,
  // WEEKLY_DATA,
  // PEER_ANALYSIS,
  // SELECTION_ANALYSIS,
  // CONVERSION_ANALYSIS,
  // REPORT_CONCLUSION,
  // REPORT,
  // OPERATING_GUIDE,
  // INDUSTRY_HOT_PRODUCTS,
  // HOT_PRODUCTS_REPORT,
};

export { ROUTER_TYPES };
export default types;
