import request from "common/utils/request";

const KEYWORD = {
  // 查询关键词
  getKeywords: "/api/v1/keywords",
  // 删除关键词
  deleteKeywords: "/api/v1/keywords",
  // 不可用
  deleteKeywordsEliminate: "/api/v1/keywords/eliminate",
  // 导出关键词
  getKeywordsExcel: "/api/v1/keywords/excel",
  // 导入关键词
  postKeywordsExcel: "/api/v1/keywords/excel",
  // 导出关键词 (流方式)
  getKeywordsExcelSteam: "/api/v1/keywords/excel-stream",
  // 下载关键词模板(流方式)
  getKeywordsExcelTemplate: "api/v1/keywords/keywordExcel-template",
  // 通过excel添加关键词
  postKeywordsByExcel: "api/v1/keywords/keyword-excels",
  // 收集并添加关键词 (webscoket)
  postKeywordsGather: "/api/v1/keywords/gather",
  postKeywordsGatherSub: "/user/{id}/reply/gather",
  // 导出关键词组
  getKeyWordGroup: "/api/v1/keywords/excel-group-stream",
  // 修改标签
  putKeywordsLabel: "/api/v1/keywords/label",
  // 刷新热度
  putKeywordHeat: "/api/v1/keywords/heat",
  // 手动添加关键词
  postKeywordsManually: "/api/v1/keywords/manually",
  // 修改匹配度
  putKeywordsMatchRate: "/api/v1/keywords/match-rate",
  // 生成产品发布建议
  postKeywordsProductsAdvice: "/api/v1/keywords/products/advice",
  // 检测标题是否应用
  refreshTileUseCount: "/api/v1/keywords/titleUseCounts",
  // 同步星级数据
  postKeywordsQsStar: "/api/v1/keywords/qsStar",
  // 同步计划基础信息
  syncCampaignBasicInfo: "/api/v1/campaigns/sync-basic-by-api",
  // 排名查询
  postKeywordsRank: "/api/v1/keywords/rank",
  // 修改是否橱窗
  putKeywordsShowcase: "/api/v1/keywords/showcase",
  // 智能排序
  postKeywordsSort: "/api/v1/keywords/sort",
  // 修改品类
  putKeywordsTradeName: "/api/v1/keywords/trade-name",
  // 获取品类列表（当前关键词）
  getKeywordsTrades: "/api/v1/keywords/trades",
  // 获取单词管理提示
  getWordManageHint: "/api/v1/user/word-manage-hint",
  // 获取所有品类
  getAllTradeNames: "/api/v1/keywords/trade-names",
  // 获取品类列表
  getLabels: "/api/v1/keywords/labels",
  // 获取类目列表
  getCategories: "/api/v1/keywords/categories",
  // 获取排名产品列表
  getRankProducts: "/api/v1/keywords/rankProducts",
  // 获取P4P分组列表
  getP4pTagLists: "/api/v1/keywords/p4p-tag-lists",
  // 加入直通车推广
  postJoinPromotion: "/api/v1/keywords/promotion",
  // 系统判定品类
  putAutoSetTradeName: "/api/v1/keywords/tradeName",
  // 关键词翻译
  putKeywordTranslate: "/api/v1/keywords/translate",
  // 调整P4P分组
  adjustP4pGroup: "/api/v1/keywords/p4p-tag",
  // 获取匹配度关键词数
  getMatchRateCount: "/api/v1/keywords/match-rate",
  // 获取关键词排名变化
  getKeywordRankTrend: "/api/v1/keywords/rank/trend",
  // 获取关键词排名变化(多个)
  getKeywordRankTrends: "/api/v1/keywords/rank/trends",
  // 所选关键词中是否包含存在于直通车中的词
  containsP4pWords: "/api/v1/keywords/containsP4pWords",
  // 获取不可用暂停推广提示
  getEliminateHint: "/api/v1/user/eliminate-hint",
  // 更新不可用暂停推广提示
  putEliminateHint: "/api/v1/user/eliminate-hint",
  // 同步类目
  putSyncCategory: "/api/v1/keywords/category",
  // 获取关键词热度
  getHeat: "/api/v1/keywords/heat",
  // 同步关键词推广计划信息
  putCampaignInfo: "/api/v1/keywords/campaign-info",
  // 一键加入推广计划
  postKeywordToCampaigns: "/api/v1/keywords/Keyword-to-campaigns",
  // 关键词采集记录
  collectRecords: "/api/v1/keywords/record",
  // 更新关键词排名信息
  updateRankInfo(data) {
    return request({
      url: "/api/v1/keywords/rankInfo",
      method: "put",
      data,
    });
  },
  // 翻译单个单词
  translateSingleWord(id) {
    return request({
      url: `/api/v1/keywords/${id}/translation`,
      method: "put",
    });
  },
  // 小语种翻译
  putSmallLanguagesTranslation(params, data) {
    return request({
      url: "/api/v1/keywords/translateToTargetLanguage",
      method: "put",
      params,
      data,
    });
  },
};

export default KEYWORD;
