import ability from "@/ability/template";
import { covertToPermission } from "@/ability/utils";

const config = covertToPermission(
  Object.assign({}, ability, {
    index: {
      aside: {
        indexTask: true,
      },
      intelligentDiagnosis: {},
      indexTask: {
        content: true,
      },
      weeklyData: {},
      peerAnalysis: {},
      selectionAnalysis: {},
      conversionAnalysis: {},
    },
  })
);

export default config;
