<!-- by LinHuiMing -->

<template>
  <div class="container">
    <el-container class="container-wrapper">
      <div v-if="showAside && !account.hideLeftMenu" class="aside-wrapper">
        <mu-aside :routes="routes" :active="active" :show-child="showChild">
        </mu-aside>
      </div>
      <el-container
        :class="account.hideLeftMenu || !showAside ? '' : 'router-wrapper'"
      >
        <router-view></router-view>
      </el-container>
    </el-container>
    <!-- <div class="market">
      <el-button
        class="btn"
        type="primary"
        size="small"
        icon="el-icon-user-solid"
        plain
        round
        @click="handleMarket">营销平台</el-button>
    </div> -->
  </div>
</template>

<script>
import MuAside from "components/mu-aside/mu-aside";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppContainer",

  components: {
    MuAside,
  },

  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: () => "",
    },
    showChild: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      flag: false,
      // showAside: true,
    };
  },
  computed: {
    ...mapGetters(["account", "showAside"]),
  },
  watch: {
    $route: {
      handler: function () {
        if (location.pathname.startsWith("/index.html")) {
          // this.showAside = false;
          this.setShowAside(false);
        }
      },
      immediate: true,
    },
  },

  created() {},

  mounted() {},
  methods: {
    ...mapMutations(["setShowAside"]),
    showSetting() {
      this.$refs.settingDialog.show();
    },
    modifyflag() {
      this.flag = !this.flag;
    },
    handleMarket() {
      const hostname = window.location.hostname;
      if (hostname === "localhost") {
        return window.open("http://localhost:9528/#/login", "_blank");
      }
      return window.open("https://market2.maoniux.com/", "_blank");
    },
  },
};
</script>

<style lang="stylus" scoped>
.container
  .container-wrapper
    .aside-wrapper
      // position fixed
      // left 0
      min-width 120px;
      .icon-wrapper
        text-align right
  .aside-wrapper
    // width 150px !important
    overflow hidden
  .router-wrapper
    // margin-left 150px
  .menu
    border-right none
    .menu-item
      &:hover
        background-color transparent
        color $color
        i
          color $color
      &:focus
        background-color transparent

@media screen and (max-width: $md-screen) {
  .container-wrapper {
    .aside-wrapper {
      display: none;
    }
  }
  .container {
    .router-wrapper {
      margin-left 0
    }
  }
}
//@media screen and (max-width: 1440px) {
//  .aside-wrapper {
//    display none !important
//  }
//  .router-wrapper {
//    margin-left 0 !important
//  }
//}
.market {
  position fixed
  left 1.5rem
  bottom 6rem
  .btn {
    opacity .7
  }
}
</style>
