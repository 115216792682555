import time from "@/common/js/time";
import { ALIBABA_IMG_LINK, GOOGLE_IMG_LINK } from "@/common/js/config";
import ws from "@/common/js/ws";
import Api from "api";
import store from "@/store";
import * as mutationTypes from "@/store/mutation-types";

export const getAliProductUrl = function (item) {
  const { subject, id, pcDetailUrl } = item;
  return (
    pcDetailUrl ||
    `https://www.alibaba.com/product-detail/${subject}_${id}.html`
  );
};

export const getAliEditProductUrl = function (item) {
  const { id } = item;
  return `https://post.alibaba.com/product/publish.htm?itemId=${id}`;
};

export const searchKeywordInGoogle = function (item) {
  const { name } = item;
  return `https://www.google.com/search?hl=zh-CN&tbm=isch&q=${name}&oq=${name}`;
};

export const searchKeywordInAlibaba = function (item) {
  const { name } = item;
  return `https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=${name}&viewtype=`;
};

export const getImgLink = function (item, account) {
  const hyperlinkHost = account.hyperlinkHost;
  switch (hyperlinkHost) {
    case ALIBABA_IMG_LINK: {
      return searchKeywordInAlibaba(item);
    }
    case GOOGLE_IMG_LINK: {
      return searchKeywordInGoogle(item);
    }
  }
};

export const getImgLinkReport = function (name, account) {
  const hyperlinkHost = account.hyperlinkHost;
  switch (hyperlinkHost) {
    case ALIBABA_IMG_LINK: {
      return `https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=${name}&viewtype=`;
    }
    case GOOGLE_IMG_LINK: {
      return `https://www.google.com/search?hl=zh-CN&tbm=isch&q=${name}&oq=${name}`;
    }
  }
};

export const useLinkExportExcel = function (fileLink, title, nick) {
  let link = document.createElement("a");
  link.href = fileLink;
  if (nick) {
    link.download = `${nick}_${title}_${time.getFormatDateTime()}.xlsx`;
  } else {
    link.download = `${title}_${time.getFormatDateTime()}.xlsx`;
  }
  link.click();
  return link.href; // 返回下载链接
};

export const useStreamExportExcel = function (stream, title, nick) {
  let blob = new Blob([stream], { type: "application/xlsx" });
  let link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  if (nick) {
    link.download = `${nick}_${title}_${time.getFormatDateTime()}.xlsx`;
  } else {
    link.download = `${title}_${time.getFormatDateTime()}.xlsx`;
  }
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(blob);
  }, 0);
  return link.href; // 返回下载链接
};

export const convertGroup = function (source) {
  if (source.length > 0) {
    return source.map((g) => {
      let res = {
        value: g.id,
        label: g.name,
        children: null,
      };
      if (g.children.length > 0) {
        res.children = convertGroup(g.children);
      }
      return res;
    });
  } else {
    return null;
  }
};

export function diagnosis(config, param) {
  const url = Api.postDiagnosis;
  const id = store.state.account.id;
  const subscribeUrl = Api.postDiagnosisSubscribeUrl.replace("{id}", id);

  return new Promise((resolve, reject) => {
    store.commit(mutationTypes.SET_ACCOUNT_DIAGNOSIS, true);
    ws({
      cacheApi: Api.postDiagnosis,
      url,
      subscribeUrl,
      param,
      options: {
        title: config.title,
      },
    })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        store.commit(mutationTypes.SET_ACCOUNT_DIAGNOSIS, false);
      });
  });
}
