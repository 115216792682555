<template>
  <el-pagination
    background
    :page-sizes="pageSizes"
    :pager-count="pagerCount"
    :page-size.sync="pageSize"
    :current-page.sync="currentPage"
    :layout="layout"
    :total="setting.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>

<script>
export default {
  name: "MuPagination",
  props: {
    setting: {
      type: Object,
      default: function () {
        return {
          pageSize: 10,
          currentPage: 1,
          total: 10,
        };
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      pageSize: this.setting.pageSize,
      currentPage: this.setting.currentPage,
      pageSizes: [10, 20, 50, 100, 200],
    };
  },
  methods: {
    handleSizeChange(value) {
      // this.setting.pageSize = value;
      // this.setting.currentPage = 1;
      this.$emit("update:setting", {
        ...this.setting,
        pageSize: value,
        currentPage: 1,
      });
      this.$emit("query");
    },
    handleCurrentChange(value) {
      // this.setting.currentPage = value;
      this.$emit("update:setting", { ...this.setting, currentPage: value });

      this.$emit("query");
    },
  },
};
</script>

<style scoped></style>
