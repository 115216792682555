<template>
  <el-dialog
    top="5vh"
    title="邀请有礼"
    :visible.sync="p_dialogVisible"
    width="40%"
    append-to-body
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="open"
  >
    <div class="container">
      <div class="header">
        <img src="@/assets/images/invite.png" alt="" />
      </div>
      <div class="content">
        <h3>邀请链接</h3>
        <p>请将以下链接发给被邀请者，成功后双方各获得600点</p>
        <div class="invite-info">
          <el-row :gutter="30">
            <el-col :span="12">
              <el-input v-model="getInviteGuideLink" :size="size"></el-input>
            </el-col>
            <el-col :span="12">
              <el-button
                type="success"
                :size="size"
                @click="handleToInviteGuide"
                >跳转
              </el-button>
              <el-button
                v-clipboard:copy="getInviteGuideLink"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                type="primary"
                :size="size"
                >复制链接
              </el-button>
            </el-col>
          </el-row>
        </div>
        <h3>历史邀请</h3>
        <el-table ref="elTable" :data="tableData" :size="size" border>
          <el-table-column prop="createdDate" label="邀请时间" align="center">
            <template #default="scope">
              {{ scope.row.createdDate | getDatetime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="customerNick"
            label="已邀用户"
            align="center"
          ></el-table-column>
          <el-table-column prop="customerLogin" label="登录状态" align="center">
            <template #default="scope">
              <el-tag v-if="scope.row.customerLogin" :size="size">是 </el-tag>
              <el-tag v-else :size="size">否 </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer" style="line-height: 1.2rem">
        <h3>被邀请用户需要完成以下步骤才算邀请成功</h3>
        <p>步骤一：需要在邀请页面上填写店铺的名称(即登录阿里时输入的账号)</p>
        <p>步骤二：在外贸服务市场上购买操盘手</p>
        <p>步骤三：登录一次操盘手</p>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button :size="size" @click="p_dialogVisible = false"
          >取 消</el-button
        >
        <el-button :size="size" type="primary" @click="p_dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { dialogMixin, tableMixin } from "@/common/js/mixin";
import notify from "@/common/js/notify";
import inviteUsers from "pages/tools/api/invite-users";
import moment from "moment";

const FORMAT_DATETIME = "YYYY-MM-DD:HH:mm:ss";

export default {
  name: "InviteUsers",
  components: {},
  filters: {
    getDatetime(val) {
      return moment(val).format(FORMAT_DATETIME);
    },
  },
  mixins: [dialogMixin, tableMixin],
  props: {},
  data() {
    return {
      size: "mini",
    };
  },
  computed: {
    getInviteGuideLink() {
      const userId = this.$store.state.account.id;
      return location.origin + `/tools.html#/invite-guide?userId=${userId}`;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    open() {
      inviteUsers
        .getHistoryInviteList()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(`获取历史邀请数据出错了:${err}`);
        });
    },
    handleToInviteGuide() {
      window.open(this.getInviteGuideLink, "blank");
    },
    onCopy(val) {
      notify.succ("你成功复制了: " + val.text);
    },
    onError() {
      notify.err("复制失败");
    },
  },
};
</script>

<style lang="stylus" scoped>

.container {
  .header {
    display flex
    justify-content center
    align-items center

    img {
      width 750px
      height 300px
    }
  }

  .content {
    .invite-info {
      margin .5rem 0
    }
  }

  h3 {
    margin .5rem 0
  }
}
</style>
