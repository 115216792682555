import request from "@/common/utils/request";

export default {
  gptChatCount() {
    return request({
      url: `/api/v1/operating-count/gpt-chat`,
      method: "put",
    });
  },
};
