import request from "@/common/utils/request";

const api = {
  // 列表
  getList(params) {
    return request({
      url: "/api/v1/detail-template",
      method: "get",
      params,
    });
  },
  getBriefList() {
    return request({
      url: "/api/v1/detail-template/title",
      method: "get",
    });
  },
  // 查看 详情
  getDetail(id) {
    return request({
      url: `/api/v1/detail-template/${id}`,
      method: "get",
    });
  },
  // 新增
  add(data) {
    return request({
      url: "/api/v1/detail-template",
      method: "post",
      data,
    });
  },
  // 修改
  modify(data) {
    return request({
      url: "/api/v1/detail-template",
      method: "put",
      data,
    });
  },
  // 删除
  delete(id) {
    return request({
      url: `/api/v1/detail-template/${id}`,
      method: "delete",
    });
  },
  // 获取内置模板
  getBuiltInTemplates() {
    return request({
      url: `/api/v1/detail-template/built-in`,
      method: "get",
    });
  },
};

export default api;
