import request from "@/common/utils/request";
// import {host} from "@/common/js/host";

export default {
  createReportConclusion(data) {
    return request({
      url: "/api/v1/report/conclusion",
      method: "POST",
      data,
    });
  },
  getLastReportConclusion() {
    return request({
      url: "/api/v1/report/conclusion/last",
      method: "get",
    });
  },
  getEmphasisKeyword() {
    return request({
      url: "/api/v1/keywords/emphasis",
      method: "get",
    });
  },
  getRecentTwoWeeks() {
    return request({
      url: "/api/v1/report/conclusion/recent-two-weeks",
      method: "get",
    });
  },
  getAllCompareData() {
    return request({
      url: "/api/v1/report/conclusion/all-compare-data",
      method: "get",
    });
  },
  getIpLock() {
    return request({
      url: "/api/v1/report/detail/ip-lock",
      method: "get",
    });
  },
  releaseIpLock() {
    return request({
      url: "/api/v1/report/detail/ip-lock",
      method: "delete",
    });
  },
  getLatestVersion() {
    return request({
      // url: `https://${host}/api/v1/plugin-version`,
      url: "https://ma.maoniux.com/api/v1/plugin-version",
      method: "get",
    });
  },
};
