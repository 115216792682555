<template>
  <el-dialog
    title="吐槽"
    :visible.sync="dialogLoad"
    top="4vh"
    width="900px"
    @open="handleOpen"
    @closed="handleClosed"
  >
    <!--<div>-->
    <!--<el-row>-->
    <!--<el-col :span="12">-->
    <!--<el-button :size="size" type="primary" @click="newFeedback">新增</el-button>-->
    <!--</el-col>-->

    <!--<el-col :span="12">-->
    <!--<el-pagination-->
    <!--class="align-right"-->
    <!--layout="prev, pager, next"-->
    <!--:page-size="3"-->
    <!--:current-page.sync="pagination.currentPage"-->
    <!--@current-change="handleCurrentChange"-->
    <!--:total="pagination.total">-->
    <!--</el-pagination>-->
    <!--</el-col>-->
    <!--</el-row>-->
    <!--</div>-->

    <!--<el-card-->
    <!--v-for="(item, index) in feedbackList"-->
    <!--:key="index"-->
    <!--class="box-card"-->
    <!--&gt;-->
    <!--<div class="content">-->
    <!--<div class="left">-->
    <!--<el-input-->
    <!--type="textarea"-->
    <!--:rows="5"-->
    <!--v-model="item.content"-->
    <!--:disabled="item.deal"-->
    <!--&gt;-->
    <!--</el-input>-->
    <!--</div>-->

    <!--<div class="right">-->
    <!--<div class="operate">-->
    <!--<el-button v-if="item.isNew" :size="size" @click="addFeedback(item)">提交</el-button>-->
    <!--<el-button v-else-if="!item.deal" :size="size" @click="modifyFeedback(item)">修改</el-button>-->
    <!--</div>-->

    <!--<div class="operate">-->
    <!--<el-button-->
    <!--:size="size"-->
    <!--type="danger"-->
    <!--@click="confirm('确定删除', deleteFeedback, [item, index])"-->
    <!--&gt;-->
    <!--删除-->
    <!--</el-button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="reply" v-if="!item.isNew">-->
    <!--<div class="left">-->
    <!--<el-card class="box-card" v-if="item.reply">-->
    <!--<div class="reply-title">回复于 {{ item.lastReplyDate | formatTimeStamp }}:</div>-->
    <!--<p>{{ item.reply }}</p>-->
    <!--</el-card>-->

    <!--<el-card class="box-card">-->
    <!--<p>暂无回复</p>-->
    <!--</el-card>-->
    <!--</div>-->

    <!--<div class="right">-->
    <!--<div class="operate">-->
    <!--<el-button-->
    <!--:size="size"-->
    <!--:type="item.deal ? 'success' : ''"-->
    <!--@click="confirm('确定已经帮您解决问题', solveFeedback, [item.id, true])"-->
    <!--:disabled="item.deal"-->
    <!--&gt;-->
    <!--已解决-->
    <!--</el-button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</el-card>-->

    <el-card class="box-card">
      <div class="content">
        <div>
          <el-form>
            <el-form-item label="吐槽内容">
              <el-input v-model="content" type="textarea" :rows="8"> </el-input>
            </el-form-item>
            <el-form-item
              label="联系邮箱 (我们将通过该邮箱对您进行反馈，请确保邮箱可用)"
            >
              <el-input v-model="email"></el-input>
            </el-form-item>
          </el-form>
        </div>

        <div>
          <div class="operate">
            <el-button :size="size" @click="addFeedback">提交</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </el-dialog>
</template>

<script>
import Api from "api";
import { prompt } from "@/common/js/el-notic";
import time from "@/common/js/time";
import axios from "axios";
import msgBox from "@/common/js/msgBox";
import { mapGetters } from "vuex";

export default {
  name: "SoftFeedbackDialog",

  filters: {
    formatTimeStamp(timestamp) {
      return time.getFormatDateTime(new Date(timestamp));
    },
  },
  data() {
    return {
      size: "mini",
      dialogLoad: false,
      feedbackList: [],
      pagination: {
        currentPage: 1,
        total: 1,
      },
      content: "",
      email: "",
    };
  },
  computed: {
    ...mapGetters(["account"]),
  },
  methods: {
    show() {
      // this.query();
      this.dialogLoad = true;
    },
    handleClosed() {
      this.pagination.currentPage = 1;
    },
    handleOpen() {
      let account = this.account;
      if (account) {
        let qq = account.qq;
        if (qq) {
          this.email = `${qq}@qq.com`;
        }
      }
    },
    handleCurrentChange(value) {
      this.pagination.currentPage = value;
      this.query();
    },
    async confirm(message, func, args = []) {
      const confirm = await msgBox.confirm({ message: message });
      if (confirm) {
        func(...args);
      }
    },

    // 后端交互
    query() {
      axios
        .get(Api.softFeedback, {
          params: {
            current: this.pagination.currentPage,
            size: 3,
            asc: false,
            orderByField: "lastModifiedDate",
          },
        })
        .then((res) => {
          this.feedbackList = res.data.records;
          this.feedbackList.forEach((f) => (f.isNew = false));
          this.pagination.total = res.data.total;
        })
        .catch(() => {});
    },
    newFeedback() {
      this.feedbackList.unshift({
        content: "",
        isNew: true,
      });
    },
    addFeedback() {
      let data = `${this.content} \n客户联系邮箱: ${this.email}`;
      axios
        .post(Api.softFeedback, {
          content: data,
        })
        .then(() => {
          // this.pagination.currentPage = 1;
          // this.query();
          prompt.successMsg("感谢您的反馈，负责人员会尽快与您取得联系");
          this.content = "";
          this.dialogLoad = false;
        })
        .catch(() => {
          prompt.errorMsg("提交失败");
        });
    },
    modifyFeedback(feedback) {
      axios
        .put(Api.softFeedback, {
          content: feedback.content,
          id: feedback.id,
        })
        .then(() => {
          this.pagination.currentPage = 1;
          this.query();
          prompt.successMsg("修改成功");
        })
        .catch(() => {
          prompt.errorMsg("修改失败");
        });
    },
    solveFeedback(id, isDeal) {
      axios
        .put(Api.softFeedback, {
          id: id,
          isDeal: isDeal,
        })
        .then(() => {
          this.pagination.currentPage = 1;
          this.query();
          prompt.successMsg("修改成功");
        })
        .catch(() => {
          prompt.errorMsg("修改失败");
        });
    },
    deleteFeedback(feedback, index) {
      if (feedback.isNew) {
        this.feedbackList.splice(index, 1);
      } else {
        axios
          .delete(Api.softFeedback, {
            params: {
              id: feedback.id,
            },
          })
          .then(() => {
            this.pagination.currentPage = 1;
            this.query();
            prompt.successMsg("删除成功");
          })
          .catch(() => {
            prompt.errorMsg("删除失败");
          });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.box-card
  margin-top 0.5rem
  margin-bottom 1rem
  line-height 1.5
  .content
    .left
      float left
      width 740px
      /*height 120px*/
      height 200px
    .right
      text-align center
      /*height 120px*/
      height 200px
  .reply
    .left
      float left
      width 740px
      min-height 90px
    .right
      padding-top 0.5rem
      text-align center
      min-height 90px
    .reply-title
      border-bottom 1px solid gainsboro
      margin-bottom 0.5rem
  .operate
    margin-bottom 0.5rem
.align-right
  text-align right
p
  text-indent 2rem
</style>
