import Axios from "@/common/js/ajax";

export const USER_CONTROLLER = {
  // 查看账户信息
  getUserStatus: "/api/v1/user/status",
  // 修改账户信息
  putUserStatus: "/api/v1/user/status",
  // 保存用户联系方式
  putUserConnectionInfo: "/api/v1/user/connection-info",

  putUserConnectionValidInfo: "/api/v1/user/connections/valid-info/",
  // 排名查询剩余次数
  getUserRankLimit: "/api/v1/user/rank/limit",

  // 发送验证码
  sendPhoneCode: (data) => {
    return Axios({
      method: "post",
      url: "/api/v1/verification/phone/send",
      data,
    });
  },
};
