import types from "pages/p4p-new/router/router-types";
export const P4P_NEW__ROUTES = [
  {
    name: "直通车",
    path: "p4p-new.html",
    children: [
      types.PROMOTION_PLAN_OVERVIEW,
      types.HIGH_TRAFFIC_KEYWORDS,
      types.HIGH_TRAFFIC_PRODUCTS,
      types.BID_LOG_NEW,
    ],
  },
];
