// websocket

import SockJs from "sockjs-client";
import Stomp from "stompjs";
// import notify from "@/common/js/notify";
import notification from "./notification";

import { SUCC_MSG, ERR_MSG } from "@/common/js/config";

const START = "start";
const WARN = "warn";
const INFO = "info";
const PROGRESS = "progress";
const END = "end";
const ERROR = "error";

const cache = {};
const api = "/ws";

// 做个缓存, 每个接口只允许请求一次, 结束之后才可以继续请求
// 按 url 做缓存, 刷新就会没了, 后续后端添加判断

function ws({ cacheApi, url, subscribeUrl, param = {}, options = {}, cb }) {
  return new Promise((resolve, reject) => {
    const title = options.title || "当前任务";
    const WS_MSG = title + ERR_MSG;
    const EXIT_MSG = title + " 已经在执行";

    if (cache[cacheApi]) {
      if (cacheApi !== null) {
        notification.warn({ message: EXIT_MSG });
      }
      reject();
      return;
    }

    let socket = new SockJs(api);
    cache[cacheApi] = Stomp.over(socket);
    const stompClient = cache[cacheApi];
    stompClient.connect(
      { message: " websocket is running!" },
      () => {
        // 订阅
        stompClient.subscribe(
          subscribeUrl,
          (msg) => {
            handleResponse(cacheApi, resolve, reject, options, msg, cb);
          },
          (err) => {
            handleError(err, cacheApi, reject, WS_MSG);
          }
        );

        // 发送
        if (url) {
          stompClient.send(url, {}, JSON.stringify(param));
        }
      },
      (err) => {
        handleError(err, cacheApi, reject, WS_MSG);
      }
    );
  });
}

function handleResponse(cacheApi, resolve, reject, options, msg, cb) {
  const res = JSON.parse(msg.body);
  const { status, data = "" } = res;
  let tip = "";
  if (res.data && typeof res.data === "string") {
    tip = res.data;
  }
  const title = options.title || "当前任务";

  switch (status) {
    case START: {
      const WS_MSG = title + " 开始执行";
      notification.info({ message: WS_MSG });
      break;
    }
    case WARN: {
      const WS_MSG = title + ": " + tip;
      notification.warn({ message: WS_MSG });
      break;
    }
    case INFO: {
      const WS_MSG = title + ": " + tip;
      notification.info({ message: WS_MSG });
      break;
    }
    case PROGRESS: {
      if (cb) {
        cb(data);
      }
      break;
    }
    case END: {
      const WS_MSG = tip || title + " " + SUCC_MSG;
      notification.succ({ message: WS_MSG });
      reset(cacheApi);
      resolve(data);
      break;
    }
    case ERROR: {
      const WS_MSG = tip || title + " " + ERR_MSG;
      notification.err({ message: WS_MSG });
      reset(cacheApi);
      reject();
      break;
    }
  }
}

function handleError(err, api, reject, msg) {
  console.error(err);
  if (err.headers.message) {
    notification.err({ message: err.headers.message });
  } else {
    if (msg) {
      notification.err({ message: msg });
    }
  }
  reset(api);
  reject(err);

  throw new Error(err);
}

function reset(cacheApi) {
  const stompClient = cache[cacheApi];
  stompClient.disconnect();
  delete cache[cacheApi];
}

export default ws;
