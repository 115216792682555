import { BASIC_VERSION, PLUS_VERSION, YEAR_VERSION } from "@/common/js/const";

export const account = (state) => state.account;

export const isYearType = (state) => {
  return state.account.accountVersion === YEAR_VERSION;
};

export const isSeasonType = (state) => {
  return state.account.accountVersion === "SEASON";
};

export const isMonthType = (state) => {
  return state.account.accountVersion === "MONTH";
};

export const isWeekType = (state) => {
  return state.account.accountVersion === "WEEK";
};

export const isBasicType = (state) => {
  return state.account.accountType === BASIC_VERSION;
};

export const isPlusType = (state) => {
  return state.account.accountType === PLUS_VERSION;
};

export const showAside = (state) => state.showAside;
export const showHeader = (state) => state.showHeader;
export const globalStatus = (state) => state.globalStatus;
export const marketingApp = (state) => state.marketingApp;

export const currentPluginVersion = (state) => state.currentPluginVersion;

export const newPluginVersion = (state) => state.newPluginVersion;

export const validPlugin = (state) => !!state.currentPluginVersion;

export const product = (state) => state.product;

export const logisticsPropertyOptions = (state) =>
  state.product.logisticsPropertyOptions;

export const boxPackagingOptions = (state) => state.product.boxPackagingOptions;

export const isDev = () => {
  return process.env.NODE_ENV === "development";
};
export const isProd = () => {
  return process.env.NODE_ENV === "production";
};

export const bookmarkAdded = (state) => {
  return state.bookmarkAdded;
};

export const currentProductColletConfig = (state) => {
  return state.product.currentProductColletConfig;
};
