/**
 * id 为 userId
 */

const WS_CONTROLLER = {
  // 全局ws
  userReplyMessage: "/user/{id}/reply/message",
  // 刷新优化建议
  userReplySuggestion: "/user/{id}/reply/suggestion",
  // 同步已发布产品数据(websocket)
  userReplyProductSync: "/user/{id}/reply/product-sync",
  // 新发产品
  userReplayProductRelease: "/user/{id}/reply/product-release",
  // 同步产品到阿里
  userReplySyncToAli: "/user/{id}/reply/product-update",
};

export default WS_CONTROLLER;
