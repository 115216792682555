import Api from "@/api";
import { getCreditUsage } from "@/pages/index/api/credit-usage";
import newProduct from "@/pages/product/api/newProduct";

// 模块名称
// const moduleNames = [
//   "releaseProductCount",
//   "updateProductCount",
//   "imageTranslationCount",
//   "keywordTranslationCount",
//   "cutoutCount",
//   "newKeywordBidCount",
//   "reportCount",
//   "rankCount",
//   GPT_SUBJECT_GENERATE_NAME,
//   GPT_SUBJECT_OPTIMIZE_NAME,
//   GPT_CHAT,
// ];

// function getModuleUseState(aiUseCountInfo, aiUseCountLimitInfo, name) {
//   for (const moduleName of moduleNames) {
//     if (name === moduleName) {
//       if (!isDef(aiUseCountLimitInfo[name])) {
//         return false;
//       }
//       return aiUseCountInfo[name] >= aiUseCountLimitInfo[name];
//     }
//   }
// }

export function getAiUseCountInfo() {
  return newProduct
    .getUserOperateCount()
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(`获取用户操作次数统计失败了: ${err}`);
    });
}

export function getAiUseCountLimitInfo() {
  return newProduct
    .getFreeAiUseCountLimit()
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(`获取用户操作次数统计失败了: ${err}`);
    });
}

const aiUseLimitUtils = {
  /**
   * Increments the GPT chat count by calling the Api.gptChatCount() function.
   *
   * @return {number} The updated GPT chat count.
   */

  icrementGptChatCount() {
    return Api.gptChatCount();
  },
  async isOverAiUseLimit() {
    return await getCreditUsage().then((res) => {
      const { creditLimit, creditUsage } = res.data;
      return creditLimit <= creditUsage;
    });

    // const aiUseCountInfo = await getAiUseCountInfo();
    // const aiUseCountLimitInfo = await getAiUseCountLimitInfo();
    // return getModuleUseState(aiUseCountInfo, aiUseCountLimitInfo, "CREDIT");
  },
};
export default aiUseLimitUtils;
