import { COLOR_TYPE } from "@/common/js/config";
import { isObject } from "@/common/js/utils";
import eventBus from "@/common/js/eventBus";

const handleConfig = function (config, type) {
  let c = {
    title: "提示",
    message: "信息",
    type,
    duration: 1000 * 60,
    showClose: true,
    center: true,
  };
  if (isObject(config)) {
    c = Object.assign(c, config);
  } else {
    c.message = config;
  }
  return c;
};

const notification = {
  default(config) {
    eventBus.$notify(handleConfig(config));
  },
  succ(config) {
    eventBus.$notify(handleConfig(config, COLOR_TYPE.SUCCESS));
  },
  warn(config) {
    eventBus.$notify(handleConfig(config, COLOR_TYPE.WARNING));
  },
  info(config) {
    eventBus.$notify(handleConfig(config, COLOR_TYPE.INFO));
  },
  err(config) {
    eventBus.$notify(handleConfig(config, COLOR_TYPE.ERROR));
  },
};

export default notification;
