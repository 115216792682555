const ELIMINATE_KEYWORD = {
  //查询不可用词
  getEliminateKeywords: "/api/v1/eliminate-keywords",
  // 删除不可用词
  deleteEliminateKeywords: "/api/v1/eliminate-keywords",
  //修改标签
  putEliminateKeywordsLabel: "/api/v1/eliminate-keywords/label",
  //导出excel
  getEliminateKeywordsExcel: "/api/v1/eliminate-keywords/excel-stream",
  //修改匹配度
  putEliminateKeywordsMatchRate: "/api/v1/eliminate-keywords/match-rate",
  //获取判定时间列表
  getEliminateKeywordsCreatedDate: "/api/v1/eliminate-keywords/created-date",
  //获取类目列表
  getEliminateKeywordsCategory: "/api/v1/eliminate-keywords/category",
  // 获取品类列表
  getEliminateKeywordsTrades: "/api/v1/eliminate-keywords/trades",
  //还原
  putEliminateKeywordsRevert: "/api/v1/eliminate-keywords/revert",
  //获取备注列表
  getEliminateKeywordsLabels: "/api/v1/eliminate-keywords/labels",
  //修改品类
  putEliminateKeywordsTradeName: "/api/v1/eliminate-keywords/trade-name",
  // 导入关键词进行修改
  postEliminateKeywordsExcel: "/api/v1/eliminate-keywords/excel",
};

export default ELIMINATE_KEYWORD;
