import request from "common/utils/request";

const api = {
  // 产品优化建议列表
  getList(params) {
    return request({
      url: "/api/v1/published-products/product-suggest",
      method: "get",
      params,
    });
  },
  // 建议优化产品总数
  getCount() {
    return request({
      url: "/api/v1/published-products/product-suggest/count",
      method: "get",
    });
  },
  // 刷新优化建议 ws
  refresh: "/api/v1/published-products/suggestion/refresh",
  // 自然流量集中
  naturalOptimization(productId) {
    const url = `/api/v1/product-suggest/${productId}/optimization/natural`;
    return request({
      url: url,
      method: "post",
    });
  },
  // 获取建议词
  adviceKeywords(productId) {
    const url = `/api/v1/product-suggest/${productId}/advice-keywords`;
    return request({
      url: url,
      method: "get",
    });
  },
};

export default api;
