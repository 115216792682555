import WEEKLY_DATA from "./weekly-data";
import INTELLIGENT_DIAGNOSIS from "./intelligent-diagnosis";
import SCHEDULES from "./index-task";
import MARKETING from "./marketing";
import operatingCount from "./operating-count";

export const DATA_ANALYSIS = {
  ...WEEKLY_DATA,
  ...INTELLIGENT_DIAGNOSIS,
  ...SCHEDULES,
  ...MARKETING,
  ...operatingCount,
};
