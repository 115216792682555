import request from "common/utils/request";

const api = {
  // banner 列表
  getList(params) {
    return request({
      url: "/api/v1/banners",
      method: "get",
      params,
    });
  },
  // 查看 banner 详情
  getDetail(id) {
    return request({
      url: `/api/v1/banners/${id}`,
      method: "get",
    });
  },
  // 新增 banner
  add(data) {
    return request({
      url: "/api/v1/banners",
      method: "post",
      data,
    });
  },
  // 修改 banner
  modify(data) {
    return request({
      url: "/api/v1/banners",
      method: "put",
      data,
    });
  },
  // 删除 banner
  delete(id) {
    return request({
      url: `/api/v1/banners/${id}`,
      method: "delete",
    });
  },
};

export default api;
