const CATEGORY_ELEMENT = {
  //查询类目元素
  getCategoryElements: "/api/v1/category-elements/",
  //查询筛选项可选值
  getOptionValues: "/api/v1/category-elements/options",
  //刷新关键词数
  putKeywordCount: "/api/v1/category-elements/count",
  //修改不可用过滤状态
  putCategoryElementFilter: "/api/v1/category-elements/{id}/filtered/{status}",
  //批量判定不可用
  putCategoryElementBatchFilter: "/api/v1/category-elements/filtered/batch",
};
export default CATEGORY_ELEMENT;
