import { KEYWORD_ROUTES } from "@/pages/keyword/router/config";
import { INDEX_ROUTES } from "@/pages/index/router/config";
import { PRODUCT_ROUTES } from "@/pages/product/router/config";
// import { SHOWCASE_ROUTES } from "pages/showcase/router/config";
import { DATA_REPORT_ROUTES } from "@/pages/data-report/router/config";
import { P4P_NEW__ROUTES } from "@/pages/p4p-new/router/config";

export const ROUTES = [
  ...INDEX_ROUTES,
  {}, // 教程占位
  ...DATA_REPORT_ROUTES,
  ...KEYWORD_ROUTES,
  ...PRODUCT_ROUTES,
  // ...SHOWCASE_ROUTES,
  ...P4P_NEW__ROUTES,
  // ...P4P_ROUTES,
];
