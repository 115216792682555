/**
 * 按页面分权限
 * 所有页面
 */
import vip from "./vip";
import customer from "./customer";
import * as role from "../role";

export default {
  [role.VIP]: vip.list,
  [role.CUSTOMER]: customer.list,
};
