<template>
  <el-tooltip effect="light" :placement="placement">
    <template #content> {{ content }}</template>
    <slot> <i :class="iconClass" :style="styles"></i> </slot>
  </el-tooltip>
</template>

<script>
export default {
  name: "MuTooltip",
  props: {
    icon: {
      type: String,
      default: "info",
    },
    styles: {
      type: Object,
      default: () => {},
    },
    placement: {
      type: String,
      default: "top",
    },
    content: {
      type: String,
      default: "",
    },
  },
  computed: {
    iconClass() {
      const className = "el-icon-" + this.icon;
      return { [className]: true, "icon-style": true };
    },
  },
};
</script>
