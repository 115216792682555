import types from "@/pages/index/router/router-types";

const AI_HOME_PAGE = types.AI_HOME_PAGE;
const INTELLIGENT_DIAGNOSIS = types.INTELLIGENT_DIAGNOSIS;

// const INDEX_TASK = types.INDEX_TASK;
// const INDEX_TASK_CUSTOMER = Object.assign(
//   {
//     active: true
//   },
//   types.INDEX_TASK
// );

const WEEKLY_DATA = types.WEEKLY_DATA;
const REPORT = types.REPORT;
const INDUSTRY_HOT_PRODUCTS = types.INDUSTRY_HOT_PRODUCTS;
const HOT_PRODUCTS_REPORT = types.HOT_PRODUCTS_REPORT;
// const REPORT = Object.assign(
//   {
//     active: false,
//     isNew: true,
//     till: "2020-03-01",
//     tip: "仅限高级版用户使用"
//   },
//   types.REPORT
// );

const VIDEO_COURSE = Object.assign(
  {
    active: false,
    isNew: true,
    till: "2099-01-01",
  },
  types.VIDEO_COURSE
);

export const INDEX_ROUTES = [
  {
    name: "AI首页",
    path: "index.html",
    children: [
      AI_HOME_PAGE,
      INTELLIGENT_DIAGNOSIS,
      VIDEO_COURSE,
      // WEEKLY_DATA,
      // INDEX_TASK_CUSTOMER,
      // REPORT_CONCLUSION,
      // REPORT,
      // PEER_ANALYSIS,
      // CONVERSION_ANALYSIS
      // OPERATING_GUIDE,
      // INDUSTRY_HOT_PRODUCTS,
      // HOT_PRODUCTS_REPORT,
    ],
  },
];

export function initAbility(app, ability) {
  const $can = app.$can;
  const account = app.account;
  const aside = ability.index.aside;
  const firstChild = INDEX_ROUTES[0];

  if ($can(aside.indexTask, account)) {
    firstChild.children = [
      AI_HOME_PAGE,
      INTELLIGENT_DIAGNOSIS,
      VIDEO_COURSE,
      // INDEX_TASK,
      WEEKLY_DATA,
      // REPORT_CONCLUSION,
      REPORT,
      // PEER_ANALYSIS,
      // CONVERSION_ANALYSIS
      // OPERATING_GUIDE,
      INDUSTRY_HOT_PRODUCTS,
      HOT_PRODUCTS_REPORT,
    ];
  } else {
    // firstChild.children = [];
  }
}
