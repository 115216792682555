import request from "common/utils/request";

const api = {
  // 条件查询
  getList(params) {
    return request({
      url: "/api/v1/product-attributes",
      method: "get",
      params,
    });
  },
  // 返回所有型号名称
  allModel() {
    return request({
      url: "/api/v1/product-attributes/models",
      method: "get",
    });
  },
  getModelWithId(params) {
    return request({
      url: "/api/v1/product-attributes/models-with-id",
      method: "get",
      params,
    });
  },
  getCategoryWithId() {
    return request({
      url: "/api/v1/product-attributes/categories-with-id",
      method: "get",
    });
  },
  // 获取筛选条件的值
  getOptions() {
    return request({
      url: "/api/v1/product-attributes/options-values",
      method: "get",
    });
  },
  // 查询图片
  getImage(attributeId) {
    return request({
      url: `/api/v1/product-attributes/${attributeId}/images`,
      method: "get",
    });
  },
  // 保存图片
  saveImage(attributeId, data) {
    return request({
      url: `/api/v1/product-attributes/${attributeId}/images`,
      method: "put",
      data,
    });
  },
  // 修改备注
  setRemark(data) {
    return request({
      url: "/api/v1/product-attributes/remark",
      method: "put",
      data,
    });
  },
  refreshImage(data) {
    return request({
      url: "/api/v1/product-attributes/images",
      method: "put",
      data,
    });
  },
  // 修改品类
  setTradeName(data) {
    return request({
      url: "/api/v1/product-attributes/trade-name",
      method: "put",
      data,
    });
  },
  // 获取型号详情
  getDetail(id) {
    return request({
      url: "/api/v1/product-attributes/details",
      method: "get",
      params: { attributeId: id },
    });
  },
  // 修改型号详情
  modifyDetail(data) {
    return request({
      url: "/api/v1/product-attributes/details",
      method: "put",
      data,
    });
  },
  // 批量修改（类目属性和其他信息）
  batchModify(data) {
    return request({
      url: "/api/v1/product-attributes/information",
      method: "put",
      data,
    });
  },
  // 批量修改（类目属性）
  batchModifyCategory(data) {
    return request({
      url: "/api/v1/product-attributes/category/data",
      method: "put",
      data,
    });
  },
  // 删除
  delete(data) {
    return request({
      url: "/api/v1/product-attributes",
      method: "delete",
      data,
    });
  },
  // 导入excel
  importExcel: "/api/v1/product-attributes/excel/import",
  // 导出excel
  exportExcel(data) {
    return request({
      url: "/api/v1/product-attributes/excel/export",
      method: "post",
      data,
      responseType: "arraybuffer",
    });
  },
  // 新增型号(excel导出)
  newAttribute(categoryId) {
    return request({
      url: `/api/v1/product-attributes/category/${categoryId}`,
      method: "post",
      responseType: "arraybuffer",
    });
  },
  // 查询店铺关键词库列表中包含的品类
  getShopTradeName() {
    return request({
      url: "/api/v1/keywords/trades",
      method: "get",
      params: {
        size: 100,
        current: 1,
      },
    });
  },
  // 查询类目信息
  getCategoryInfo(categoryId) {
    return request({
      url: `/api/v1/categories/${categoryId}`,
      method: "get",
    });
  },
  getCategoryFullName(categoryId) {
    return request({
      url: `/api/v1/categories/fullName/${categoryId}`,
      method: "get",
    });
  },
  addSimilarModel(data) {
    return request({
      url: "/api/v1/product-attributes/models",
      method: "post",
      data,
    });
  },

  getCateL(params) {
    return request({
      url: "/api/v1/keywords/no-cover/categories",
      method: "get",
      params,
    });
  },
  // 关联视频
  relateVideos(data) {
    return request({
      url: "/api/v1/product-attributes/videos",
      method: "put",
      data,
    });
  },
};

export default api;
