import types from "./router-types";

// const WORD_COLLECTION = Object.assign(
//   {
//     isNew: true,
//     till: "2020-03-01",
//     isDot: true,
//   },
//   types.WORD_COLLECTION
// );
// const KEYWORD_LIBRARY = Object.assign(
//   {
//     isNew: true,
//     till: "2020-03-01",
//     isDot: true,
//   },
//   types.KEYWORD_LIBRARY
// );

export const KEYWORD_ROUTES = [
  {
    name: "关键词",
    path: "keyword.html",
    children: [...Object.values(types)],
  },
];
