const countries = [
  {
    label: "北美",
    name: "NorthAmerica",
    options: [
      {
        value: "Canada",
        label: "加拿大",
      },
      {
        value: "America",
        label: "美国",
      },
      {
        value: "Mexico",
        label: "墨西哥",
      },
      {
        value: "Guatemala",
        label: "危地马拉",
      },
      {
        value: "NaOhters",
        label: "北美其他",
      },
    ],
  },
  {
    label: "欧洲",
    name: "Europe",
    options: [
      {
        value: "Russia",
        label: "俄罗斯",
      },
      {
        value: "U.K",
        label: "英国",
      },
      {
        value: "Spain",
        label: "西班牙",
      },
      {
        value: "Germany",
        label: "德国",
      },
      {
        value: "Italy",
        label: "意大利",
      },
      {
        value: "France",
        label: "法国",
      },
      {
        value: "Netherlands",
        label: "荷兰",
      },
      {
        value: "Portugal",
        label: "葡萄牙",
      },
      {
        value: "Ukraine",
        label: "乌克兰",
      },
      {
        value: "Poland",
        label: "波兰",
      },
      {
        value: "Romania",
        label: "罗马尼亚",
      },
      {
        value: "Belgium",
        label: "比利时",
      },
      {
        value: "Sweden",
        label: "瑞典",
      },
      {
        value: "Switzerland",
        label: "瑞士",
      },
      {
        value: "Greece",
        label: "希腊",
      },
      {
        value: "Czech Republic",
        label: "捷克",
      },
      {
        value: "Austria",
        label: "奥地利",
      },
      {
        value: "Hungary",
        label: "匈牙利",
      },
      {
        value: "Bulgaria",
        label: "保加利亚",
      },
      {
        value: "Norway",
        label: "挪威",
      },
      {
        value: "Ireland",
        label: "爱尔兰",
      },
      {
        value: "EuOthers",
        label: "欧洲其他",
      },
    ],
  },
  {
    label: "中南美",
    name: "SouthAmerica",
    options: [
      {
        value: "Brazil",
        label: "巴西",
      },
      {
        value: "Chile",
        label: "智利",
      },
      {
        value: "Colombia",
        label: "哥伦比亚",
      },
      {
        value: "Argentina",
        label: "阿根廷",
      },
      {
        value: "Peru",
        label: "秘鲁",
      },
      {
        value: "Ecuador",
        label: "厄瓜多尔",
      },
      {
        value: "Plurinational State of Bolivia",
        label: "玻利维亚",
      },
      {
        value: "Dominican Republic",
        label: "多米尼加共和国",
      },
      {
        value: "Venezuela",
        label: "委内瑞拉",
      },
      {
        value: "Uruguay",
        label: "乌拉圭",
      },

      {
        value: "SaOthers",
        label: "中南美其他",
      },
    ],
  },
  {
    label: "大洋洲",
    name: "Oceania",
    options: [
      {
        value: "Australia",
        label: "澳大利亚",
      },
      {
        value: "new Zealand",
        label: "新西兰",
      },
      {
        value: "OcOthers",
        label: "大洋洲其他",
      },
    ],
  },
  {
    label: "非洲",
    name: "Africa",
    options: [
      {
        value: "Egypt",
        label: "埃及",
      },
      {
        value: "South Africa",
        label: "南非",
      },
      {
        value: "Algeria",
        label: "阿尔及利亚",
      },
      {
        value: "Morocco",
        label: "摩洛哥",
      },
      {
        value: "Tunisia",
        label: "突尼斯",
      },
      {
        value: "Kenya",
        label: "肯尼亚",
      },
      {
        value: "Ghana",
        label: "加纳",
      },
      {
        value: "Mauritius",
        label: "毛里求斯",
      },
      {
        value: "Libya",
        label: "利比亚",
      },
      {
        value: "United Republic of Tanzania",
        label: "坦桑尼亚",
      },
      {
        value: "Ethiopia",
        label: "埃塞俄比亚",
      },
      {
        value: "Cameroon",
        label: "喀麦隆",
      },

      {
        value: "Mozambique",
        label: "莫桑比克",
      },
      {
        value: "Senegal",
        label: "塞内加尔",
      },
      {
        value: "Zimbabwe",
        label: "津巴布韦",
      },

      {
        value: "Uganda",
        label: "乌干达",
      },
      {
        value: "Cote d'Ivoire",
        label: "科特迪瓦",
      },
      {
        value: "Zambia",
        label: "赞比亚",
      },
      {
        value: "Sudan",
        label: "苏丹",
      },
      {
        value: "Democratic Republic of Congo",
        label: "刚果民主共和国",
      },

      {
        value: "AfOthers",
        label: "非洲其他",
      },
    ],
  },
  {
    label: "中东",
    name: "MiddleEast",
    options: [
      {
        value: "Turkey",
        label: "土耳其",
      },
      {
        value: "Saudi Arabia",
        label: "沙特",
      },
      {
        value: "United Arab Emirates",
        label: "阿联酋",
      },
      {
        value: "Israel",
        label: "以色列",
      },
      {
        value: "Iran (Islamic Republic of)",
        label: "伊朗",
      },
      {
        value: "Iraq",
        label: "伊拉克",
      },
      {
        value: "Kuwait",
        label: "科威特",
      },
      {
        value: "Jordan",
        label: "约旦",
      },
      {
        value: "Qatar",
        label: "卡塔尔",
      },
      {
        value: "Lebanon",
        label: "黎巴嫩",
      },
      {
        value: "Oman",
        label: "阿曼",
      },
      {
        value: "Cyprus",
        label: "塞浦路斯",
      },
      {
        value: "State of Palestine",
        label: "巴勒斯坦",
      },
      {
        value: "Bahrain",
        label: "巴林",
      },
      {
        value: "Yemen",
        label: "也门",
      },
      {
        value: "Syrian Arab Republic",
        label: "叙利亚",
      },
    ],
  },
  {
    label: "亚洲",
    name: "Asia",
    options: [
      {
        value: "India",
        label: "印度",
      },
      {
        value: "Pakistan",
        label: "巴基斯坦",
      },
      {
        value: "South Korea",
        label: "韩国",
      },
      {
        value: "the Philippines",
        label: "菲律宾",
      },
      {
        value: "Malaysia",
        label: "马来西亚",
      },
      {
        value: "Indonesia",
        label: "印尼",
      },
      {
        value: "Thailand",
        label: "泰国",
      },
      {
        value: "Japan",
        label: "日本",
      },
      {
        value: "Vietnam",
        label: "越南",
      },
      {
        value: "Singapore",
        label: "新加坡",
      },
      {
        value: "China Hong Kong",
        label: "中国香港(地区)",
      },
      {
        value: "Bangladesh",
        label: "孟加拉国",
      },
      {
        value: "Taiwan, China",
        label: "中国台湾(地区)",
      },
      {
        value: "Sri Lanka",
        label: "斯里兰卡",
      },
      {
        value: "Kazakhstan",
        label: "哈萨克斯坦",
      },
      {
        value: "Cambodia",
        label: "柬埔寨",
      },
      {
        value: "Uzbekistan",
        label: "乌兹别克斯坦",
      },
      {
        value: "AsOthers",
        label: "亚洲其他",
      },
    ],
  },
  {
    label: "其他",
    name: "Others",
    options: [
      {
        value: "Others",
        label: "其他",
      },
    ],
  },
  // popularCountries: [
  //   {
  //     value: "United States",
  //     label: "美国",
  //   },
  //   {
  //     value: "United States Minor Outlying Islands",
  //     label: "美国本土外小岛屿",
  //   },
  //   {
  //     value: "United Kingdom",
  //     label: "英国",
  //   },
  //   {
  //     value: "Canada",
  //     label: "加拿大",
  //   },
  //   {
  //     value: "Russian Federation",
  //     label: "俄罗斯",
  //   },
  //   {
  //     value: "Germany",
  //     label: "德国",
  //   },
  //   {
  //     value: "France",
  //     label: "法国",
  //   },
  //   {
  //     value: "France Metropolitan",
  //     label: "法属美特罗波利坦",
  //   },
  //   {
  //     value: "Mexico",
  //     label: "墨西哥",
  //   },
  //   {
  //     value: "Brazil",
  //     label: "巴西",
  //   },
  //   {
  //     value: "Spain",
  //     label: "西班牙",
  //   },
  //   {
  //     value: "Italy",
  //     label: "意大利",
  //   },
  //   {
  //     value: "Philippines",
  //     label: "菲律宾",
  //   },
  //   {
  //     value: "Australia",
  //     label: "澳大利亚",
  //   },
  //   {
  //     value: "Pakistan",
  //     label: "巴基斯坦",
  //   },
  //   {
  //     value: "Malaysia",
  //     label: "马来西亚",
  //   },
  //   {
  //     value: "Saudi Arabia",
  //     label: "沙特阿拉伯",
  //   },

  //   {
  //     value: "Netherlands Antilles",
  //     label: "荷属安的列斯群岛",
  //   },
  //   {
  //     value: "Thailand",
  //     label: "泰国",
  //   },
  //   {
  //     value: "Turkey",
  //     label: "土耳其",
  //   },
  //   {
  //     value: "Peru",
  //     label: "秘鲁",
  //   },
  //   {
  //     value: "Indonesia",
  //     label: "印度尼西亚",
  //   },
  //   {
  //     value: "India",
  //     label: "印度",
  //   },
  //   {
  //     value: "British Indian Ocean Territory",
  //     label: "英属印度洋领地",
  //   },
  //   {
  //     value: "Chile",
  //     label: "智利",
  //   },
  //   {
  //     value: "Egypt",
  //     label: "埃及",
  //   },
  //   {
  //     value: "North Korea",
  //     label: "朝鲜",
  //   },
  //   {
  //     value: "South Korea",
  //     label: "韩国",
  //   },
  //   {
  //     value: "Colombia",
  //     label: "哥伦比亚",
  //   },
  //   {
  //     value: "Japan",
  //     label: "日本",
  //   },
  //   {
  //     value: "Vietnam",
  //     label: "越南",
  //   },
  //   {
  //     value: "Ukraine",
  //     label: "乌克兰",
  //   },
  //   {
  //     value: "South Africa",
  //     label: "南非",
  //   },
  //   {
  //     value: "Argentina",
  //     label: "阿根廷",
  //   },
  //   {
  //     value: "Morocco",
  //     label: "摩洛哥",
  //   },
  //   {
  //     value: "Bangladesh",
  //     label: "孟加拉国",
  //   },
  //   {
  //     value: "United Arab Emirates",
  //     label: "阿拉伯联合酋长国",
  //   },
  //   {
  //     value: "New Zealand",
  //     label: "新西兰",
  //   },
  // ],
];
export default countries;
