// 重复使用的 tip

const KEYWORD_TITLE_TIP = "点击关键词, 跳转到 Google 图片搜索页面";
const PRODUCT_TITLE_TIP =
  "标红的单词代表目前并未给该产品带来流量\n 如果需要对产品标题进行修改，建议优先修改标红的单词\n 而标题中的其他单词若要修改请慎重，以免影响流量";
const MATCH_WORD_COUNT_TIP =
  "匹配的四五星关键词总数（ 四星匹配词数 / 五星匹配词数 ）";
const MAIN_IMAGE = "点击图片选择该型号图片库";
const PRODUCT_TITLE = "双击标题进行编辑";

const Tip = {
  关键词: KEYWORD_TITLE_TIP,
  产品标题: PRODUCT_TITLE_TIP,
  匹配词数: MATCH_WORD_COUNT_TIP,
  主图: MAIN_IMAGE,
  标题: PRODUCT_TITLE,
};

export default Tip;
