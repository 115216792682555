import request from "common/utils/request";
import Axios from "@/common/js/ajax";

const api = {
  // 图片列表
  getPhotoList(params) {
    return request({
      url: "/api/v1/photo-bank",
      method: "get",
      params,
    });
  },
  // 分组列表
  getGroupList(params) {
    return request({
      url: "/api/v1/photo-bank/group",
      method: "get",
      params,
    });
  },
  // 同步图片分组
  sync() {
    return request({
      url: "/api/v1/photo-bank/sync",
      method: "post",
    });
  },
  syncImage() {
    return request({
      url: "/api/v1/photo-bank/image/sync",
      method: "post",
    });
  },
  // 获取同步进度
  getSynProgress() {
    return request({
      url: "/api/v1/photo-bank/getPhotoSyncProgress",
      method: "get",
    });
  },

  // 添加分组
  addGroup(data) {
    return request({
      url: "/api/v1/photo-bank/group",
      method: "post",
      data,
    });
  },
  // 删除分组
  deleteGroup(data) {
    return request({
      url: "/api/v1/photo-bank/group",
      method: "post",
      data,
    });
  },
  // 上传
  uploadPhoto: "/api/v1/photo-bank/upload",
  // 上传，重复图片报错
  uploadPhotoWithError: "/api/v1/photo-bank/upload-with-error",
  // 上传，重复图片返回当前分组
  uploadPhotoWithErrorGroup: "/api/v1/photo-bank/upload-with-error-group",
  // 手写上传图片参数
  uploadImageBank(file, groupId) {
    const formData = new FormData();
    formData.append("groupId", groupId);
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return request({
      url: "/api/v1/photo-bank/upload-with-error",
      method: "post",
      config,
      data: formData,
    });
  },
  uploadToDefaultGroup(file) {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return request({
      url: "/api/v1/photo-bank/upload/to/default-group",
      method: "post",
      config,
      data: formData,
    });
  },
  // 同步指定分组图片
  postSyncGroupImage(params) {
    return request({
      url: `/api/v1/photo-bank/sync/assign-group`,
      method: "post",
      params,
    });
  },
  deleteImages(ids) {
    return Axios({
      url: "/api/v1/photo-bank/images",
      method: "delete",
      data: ids,
    });
  },
};

export default api;
