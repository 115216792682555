<template>
  <el-dialog
    custom-class="setting-dialog"
    title=""
    width="800px"
    :visible.sync="p_dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleOpen"
  >
    <div class="content">
      <el-col :span="6">
        <div class="logo">
          <img src="@/assets/images/hello.png" width="200px" />
        </div>
      </el-col>
      <el-col :span="18">
        <div class="top">
          <div class="title">欢迎您使用AI操盘手</div>
          <div class="tip-text">
            <span>
              请绑定您的手机号码，后续该手机号可通过 ai.maoniunet.com
              直接登陆AI操盘手
            </span>
          </div>
        </div>
        <div class="form">
          <el-form
            ref="ruleForm"
            :model="originAccount"
            :rules="rules"
            label-position="top"
            label-width="160px"
          >
            <!-- <el-form-item label="公司名" prop="cnName">
              <el-input v-model="originAccount.cnName" :size="size"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="姓名" prop="name">
              <el-input v-model="originAccount.name" :size="size"></el-input>
            </el-form-item> -->
            <el-form-item prop="telephone">
              <el-input
                v-model="originAccount.telephone"
                :size="size"
              ></el-input>
              <template #label> 手机 </template>
            </el-form-item>
            <el-form-item prop="code" label="验证码">
              <el-input v-model="originAccount.code" :size="size">
                <template #append>
                  <el-button
                    :loading="phoneCodeBtnLoading"
                    style="color: #409eff"
                    :disabled="disabledPhoneCodeBtn"
                    @click="sendPhoneCode"
                  >
                    {{ phoneCodeBtnText || "发送验证码" }}
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          :size="size"
          type="primary"
          :loading="saveButtonLoad"
          @click="submitForm"
          >保 存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { dialogMixin } from "@/common/js/mixin";
import { SASS_LOGIN_URL } from "@/common/js/config";
import { mapGetters } from "vuex";
import notify from "@/common/js/notify";
import Api from "@/api";
import axios from "@/common/js/ajax";

export default {
  name: "WelcomePage",
  mixins: [dialogMixin],
  data() {
    return {
      disabledPhoneCodeBtn: false,
      phoneCodeBtnText: "发送验证码",
      phoneCodeBtnTimer: 60,
      phoneCodeBtnLoading: false,

      saveButtonLoad: false,
      originAccount: {
        diagnosisRunning: false,
        id: null,
        init: false,
        sameIndustryUrls: "",
        keywordChannel: {
          hotWord: true,
          myWord: true,
          p4pSearch: true,
        },
        cnName: null,
        name: "",
        nick: null,
        hyperlinkHost: "1",
        password: "",
        qq: "",
        weChat: "",
        telephone: "",
        code: "",
        photoBankGroup: null,
        ignoredPhotoBankGroup: null,
        effectFeedbackConfig: "",
        useNormalization: true,
      },
      imgUrl: "@/assets/images/seat-left.png",
      size: "",
      rules: {
        cnName: [
          {
            required: true,
            message: "请输入公司名",
            trigger: ["blur"],
            validator: this.contactValidator,
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["blur"],
            validator: this.contactValidator,
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur", "change"],
            validator: this.contactValidator,
          },
          // {
          //   pattern:
          //     /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/,
          //   trigger: ["blur", "change"],
          //   message: "请输入正确的手机号",
          // },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    globalInit() {
      return this.account.init;
    },
    getRegQq() {
      const reg = /^\d*$/;
      return reg.test(this.originAccount.qq) || !this.originAccount.qq;
    },
    getRegWeChat() {
      const reg = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
      return reg.test(this.originAccount.weChat) || !this.originAccount.weChat;
    },
    supermanLogo() {
      return this.marketingApp["superman"]?.softwareLogo;
    },
    getLoginUrl() {
      return `${SASS_LOGIN_URL}&oem=${this.account.belongsCorp}`;
    },
    ...mapGetters(["account", "marketingApp"]),
  },
  methods: {
    sendPhoneCode() {
      const { telephone: phoneNumber } = this.originAccount;

      this.$refs["ruleForm"]?.validateField("telephone", (errorMessage) => {
        if (errorMessage) {
          return;
        }
        this.phoneCodeBtnLoading = true;
        Api.sendPhoneCode({ phoneNumber })
          .then(() => {
            this.disabledPhoneCodeBtn = true;

            this.phoneCodeBtnText = `重新发送 ${this.phoneCodeBtnTimer} s`;

            const t = setInterval(() => {
              if (this.phoneCodeBtnTimer <= 0) {
                clearInterval(t);
                this.disabledPhoneCodeBtn = false;
                this.phoneCodeBtnText = "发送验证码";
                return;
              }
              this.phoneCodeBtnText = `重新发送 ${--this.phoneCodeBtnTimer} s`;
            }, 1000);
          })
          .finally(() => (this.phoneCodeBtnLoading = false));
      });
    },
    handleClose() {
      if (!this.globalInit) {
        notify.warn("初次登录请您填写好相关信息哦");
        return;
      }
    },
    submitForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.saveFormData();
        } else {
          return false;
        }
      });
    },
    saveFormData() {
      this.saveButtonLoad = true;
      const url = Api.putUserConnectionValidInfo;
      let { telephone, code } = this.originAccount;
      let data = { telephone, code };
      return axios({
        method: "put",
        url: url,
        data,
      })
        .then(() => {
          notify.succ("保存成功");
          this.$emit("showToVideoGuide");
          this.closeDialog();
        })
        .catch((err) => {
          // notify.warn(err.message | "出现了一些异常, 保存失败了");
          console.error(err);
        })
        .finally(() => {
          this.saveButtonLoad = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.content{
  max-height 480px;
}
.top{
  margin-bottom 20px;
  .title{
    font-size 30px
  }
  .tip-text {
    margin-top 10px
    color: $info
  }

}
</style>
