import moment from "moment";
const cookies = {
  setCookie: function (name, value) {
    //设置截止到当天23：59:59
    const curDate = new Date();
    //当前时间戳
    const curTamp = curDate.getTime();
    //当前日期
    const curDay = moment(curDate).format();
    let curWeeHours = 0;
    curWeeHours = new Date(curDay).getTime() - 1;
    //当日已经过去的时间（毫秒）
    const passedTamp = curTamp - curWeeHours;
    //当日剩余时间
    const leftTamp = 24 * 60 * 60 * 1000 - passedTamp;
    const leftTime = new Date();
    leftTime.setTime(leftTamp + curTamp);
    //创建cookie
    document.cookie =
      name + "=" + value + ";expires=" + leftTime.toUTCString() + ";path=/";
  },
  getCookie: function (name) {
    //name 为想要取到的键值的键名
    const reg = /\s/g;
    const result = document.cookie.replace(reg, "");
    const resultArr = result.split(";");
    for (let i = 0; i < resultArr.length; i++) {
      const nameArr = resultArr[i].split("=");
      if (nameArr[0] === name) {
        return nameArr[1];
      }
    }
  },
  removeCookie: function (name) {
    //name为想要删除的Cookie的键名
    const oDate = new Date(); //时间对象
    oDate.setDate(new Date().getDate() - 1);
    document.cookie = name + "=123;expires=" + oDate + ";path=/";
  },
};
export default cookies;
