<!-- by LinHuiMing -->

<template>
  <i class="el-icon-info icon-style" :style="styles"></i>
</template>

<script>
// import { COLOR } from "@/common/js/config";

export default {
  name: "MuQuestion",

  components: {},

  props: {
    // effect: {
    //   type: String,
    //   default: "light"
    // },
    // content: {
    //   type: String,
    //   default: "给点提示吧！"
    // },
    // placement: {
    //   type: String,
    //   default: "top"
    // },
    // color: {
    //   type: String,
    //   default: COLOR.blue
    // },
    // "hover-color": {
    //   type: String,
    //   default: COLOR.succ
    // }
    styles: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },

  computed: {
    // style() {
    //   return {
    //     color: this.color
    //   };
    // }
  },

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style lang="stylus" scoped>

i
  margin 0
</style>
