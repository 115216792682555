import eventBus from "@/common/js/eventBus";

const msgBox = {
  prompt({ title = "提示", message }) {
    return eventBus
      .$prompt(message, title, {
        beforeClose: (action, instance, done) => {
          done();
        },
      })
      .catch(() => {});
  },
  confirm({ title = "提示", message }) {
    return eventBus
      .$confirm(message, title, {
        beforeClose: (action, instance, done) => {
          done();
        },
      })
      .catch(() => {});
  },
};

export default msgBox;
