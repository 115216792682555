import { render, staticRenderFns } from "./tabs.vue?vue&type=template&id=5a0c3a4a&scoped=true&"
import script from "./tabs.vue?vue&type=script&lang=js&"
export * from "./tabs.vue?vue&type=script&lang=js&"
import style0 from "./tabs.vue?vue&type=style&index=0&id=5a0c3a4a&prod&style=stylus&lang=css&"
import style1 from "./tabs.vue?vue&type=style&index=1&id=5a0c3a4a&prod&lang=stylus&scoped=true&"
import style2 from "./tabs.vue?vue&type=style&index=2&id=5a0c3a4a&prod&lang=stylus&"
import style3 from "./tabs.vue?vue&type=style&index=3&id=5a0c3a4a&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a0c3a4a",
  null
  
)

export default component.exports