import { extend, isDef } from "@/common/js/utils";
import MuTooltip from "@/components/mu-tooltip/mu-tooltip.vue";
import MuPagination from "@/components/mu-pagination/mu-pagination.vue";
import MuQuestion from "@/components/mu-question/mu-question.vue";
import R from "ramda";

import VueFab from "vue-float-action-button";

export default function ininVueGlobalConfig(vm) {
  vm.config.productionTip = false;

  vm.config.optionMergeStrategies.methods = function (parentVal, childVal) {
    if (!childVal) return parentVal;
    if (!parentVal) return childVal;
    const ret = Object.create(null);
    const originSearch = parentVal.search;
    const search = childVal.search;
    extend(ret, parentVal);
    extend(ret, childVal); //  child的会覆盖parent的
    if (isDef(originSearch) && isDef(search)) {
      ret.search = R.compose(search, originSearch);
      ret.originSearch = search;
    }
    return ret;
  };

  vm.component("MuTooltip", MuTooltip);
  vm.component("MuQuestion", MuQuestion);
  vm.component("MuPagination", MuPagination);

  vm.use(VueFab);
}

import "./loki";
