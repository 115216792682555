export const covertToPermission = function (pages = {}) {
  const permission = { ...pages };
  const parentKeys = Reflect.ownKeys(pages);
  const list = [];

  parentKeys.forEach((parentKey) => {
    const parentVal = pages[parentKey];

    if (typeof parentVal === "object") {
      const childKeys = Reflect.ownKeys(parentVal);
      childKeys.forEach((childKey) => {
        const childVal = parentVal[childKey];

        if (typeof childVal === "object") {
          const sonKeys = Reflect.ownKeys(childVal);
          sonKeys.forEach((sonKey) => {
            const sonVal = childVal[sonKey];
            const val = [parentKey, childKey, sonKey].join(".");
            if (sonVal) {
              list.push(val);
            }
            permission[parentKey][childKey][sonKey] = val;
          });
        }
      });
    }
  });
  return { permission, list };
};
