import * as types from "./mutation-types";
import cookies from "@/common/js/cookie";

const mutations = {
  [types.SET_ACCOUNT](state, account) {
    state.account = account;
    sessionStorage.setItem("nick", account.nick);
    localStorage.setItem("oem", account.belongsCorp);
    cookies.setCookie("nick", account.nick);
  },
  [types.SET_ACCOUNT_DIAGNOSIS](state, status) {
    state.account.diagnosisRunning = status;
  },
  [types.SET_GLOBAL_LOAD](state, globalLoad) {
    state.globalStatus.globalLoad = globalLoad;
  },
  [types.SET_MARKETING_APP](state, val) {
    state.marketingApp = val;
  },
  [types.SET_CURRENT_PLUGIN_VERSION](state, val) {
    state.currentPluginVersion = val;
  },
  [types.SET_NEW_PLUGIN_VERSION](state, val) {
    state.newPluginVersion = val;
  },
  updateLogisticsPropertyOptions(state, val) {
    state.product.logisticsPropertyOptions = val;
  },
  updateBoxPackagingOptions(state, val) {
    const regex =
      /id:(\d+);name:(.+);length:(.+);width:(.+);height:(.+);weight:(.+)/;

    state.product.boxPackagingOptions = val?.map((item) => {
      const { displayName, dependGroup } = item;
      // eslint-disable-next-line no-unused-vars
      const [_, id, name, length, width, height, weight] =
        item.displayName?.match(regex);
      return {
        id,
        name,
        length,
        width,
        height,
        weight,
        displayName,
        dependGroup,
      };
    });
  },
  setShowAside(state, val) {
    state.showAside = val;
  },
  setShowHeader(state, val) {
    state.showHeader = val;
  },
  setBookmarkAdded(state, val) {
    state.bookmarkAdded = val;
  },
  setCurrentProductColletConfig(state, val) {
    state.product.currentProductColletConfig = val;
  },
};

export default mutations;
