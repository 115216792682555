import { OEM_URL } from "@/common/js/config";

/**
 * 外贸超人api
 */
export default {
  /**
   * 获取软件贴牌信息
   * @returns {Promise<{data: {code: number, data: {}, msg: string}}>}
   */
  getSoftwareApp(oem) {
    return fetch(`${OEM_URL}?oem=${oem}`);
  },
};
