const ROUTER_TYPES = {
  KEYWORD_LIBRARY: "keyword-library",
  WORD_COLLECTION: "word-collection",
  UNAVAILABLE_WORD: "unavailable-word",
  CATEGORY_ELEMENT: "category-element",
  BRAND_WORD: "brand-word",
};

const KEYWORD_LIBRARY = {
  name: "关键词库",
  path: `/${ROUTER_TYPES.KEYWORD_LIBRARY}`,
};

const WORD_COLLECTION = {
  name: "收集关键词",
  path: `/${ROUTER_TYPES.WORD_COLLECTION}`,
};

const UNAVAILABLE_WORD = {
  name: "屏蔽关键词",
  path: `/${ROUTER_TYPES.UNAVAILABLE_WORD}`,
};

const CATEGORY_ELEMENT = {
  name: "按类目屏蔽",
  path: `/${ROUTER_TYPES.CATEGORY_ELEMENT}`,
};

const BRAND_WORD = {
  name: "按单词屏蔽 ",
  path: `/${ROUTER_TYPES.BRAND_WORD}`,
};

// aside 左侧菜单项
const types = {
  KEYWORD_LIBRARY,
  WORD_COLLECTION,
  UNAVAILABLE_WORD,
  CATEGORY_ELEMENT,
  BRAND_WORD,
};

// header 下拉菜单项
export const newTypes = {
  ...types,
};

export { ROUTER_TYPES };
export default types;
