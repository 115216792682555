<template>
  <el-dialog
    class="plugin-dialog-wrapper"
    :visible.sync="p_dialogVisible"
    :width="dialogWidth"
    :title="type === 'install' ? '插件安装提示' : '插件更新提示'"
    append-to-body
    :show-close="false"
    :close-on-click-modal="false"
    :top="dialogTop"
  >
    <div v-if="!currentPluginVersion">
      <el-result style="padding: 20px 15px" icon="error">
        <template #title>
          未安装插件
          <!-- ，请点击链接到<el-link
            href="https://chrome.google.com/webstore/detail/eiloldiefkfjmhfcoghlddibiopnfmco"
            type="primary"
            target="__blank"
          >
            谷歌应用商店 </el-link
          >安装插件 -->
        </template>
        <template slot="extra">
          <result-extra></result-extra>
        </template>
      </el-result>
    </div>
    <div v-else-if="currentPluginVersion < newPluginVersion">
      <el-result style="padding: 20px 15px" icon="warning">
        <template #title>
          插件版本过低
          <!-- ，请点击链接到<el-link
            href="https://chrome.google.com/webstore/detail/eiloldiefkfjmhfcoghlddibiopnfmco"
            type="primary"
            target="__blank"
          >
            谷歌应用商店</el-link
          >更新插件 -->
        </template>
        <template slot="extra"> <result-extra></result-extra> </template>
      </el-result>
    </div>
    <div v-else>
      <el-result
        style="padding: 20px 15px"
        icon="success"
        title="插件已经是最新版本了"
      >
        <template slot="extra">
          <result-extra></result-extra>
        </template>
      </el-result>
    </div>
    <!-- <iframe
          style="width: 100%; height: 60vh; overflow: hidden"
          scrolling="auto"
          frameborder="0"
          :src="pluginCourseUrl"
        ></iframe> -->
    <div v-if="invalidPluginState" class="plugin-install-course-div">
      <!-- <h3>如您无法访问谷歌应用商店，请按如下步骤进行：</h3> -->
      <p v-if="type === 'update'">
        <span style="color: lightcoral">
          注意：为避免跟新的插件产生冲突，请先删除旧的插件
        </span>
        <!-- <el-popover
          placement="top-start"
          width="500"
          trigger="hover"
          class="operate-image-popover"
        >
          <el-link slot="reference" type="primary">对应操作附图</el-link>
          <el-image
            :src="require('@/assets/images/plugin/4.png')"
            fit="contains"
          ></el-image>
        </el-popover> -->
      </p>
      <p>
        第一步：点击右侧链接下载<el-link
          href="https://oss.maoniux.com/__assets/superman/plugin/ai-plugin.zip"
          type="primary"
        >
          最新版插件
        </el-link>

        <!-- <el-popover
          placement="top-start"
          width="500"
          trigger="hover"
          class="operate-image-popover"
        >
          <el-link slot="reference" type="primary">对应操作附图</el-link>
          <el-image
            :src="require('@/assets/images/plugin/1.png')"
            fit="contains"
          ></el-image>
        </el-popover> -->
      </p>
      <p>
        第二步：先打开谷歌浏览器点右上角设置，进入扩展程序页面，并打开开发者模式

        <!-- <el-popover
          placement="top-start"
          width="500"
          trigger="hover"
          class="operate-image-popover"
        >
          <el-link slot="reference" type="primary">对应操作附图</el-link>
          <el-image
            :src="require('@/assets/images/plugin/2.png')"
            fit="contains"
          ></el-image>
        </el-popover> -->
      </p>
      <p>
        第三步：将下载好的插件包（注意不要解压缩）直接拖到浏览器扩展程序页面，即可自动安装。点击刷新即可。
        <!-- <el-popover
          placement="top-start"
          width="500"
          trigger="hover"
          class="operate-image-popover"
        >
          <el-link slot="reference" type="primary">对应操作附图</el-link>
          <el-image
            :src="require('@/assets/images/plugin/3.png')"
            fit="contains"
          ></el-image>
        </el-popover> -->
      </p>
      <p>
        <el-link type="primary" @click="() => (showVideoPlayerDialog = true)">
          查看插件安装视频
        </el-link>
      </p>

      <br />

      <p>
        tips1: 如果你电脑有vpn，可以直接访问
        <el-link
          target="__blank"
          type="primary"
          href="https://chromewebstore.google.com/detail/ai%E5%8A%A9%E6%89%8Baihelper/eiloldiefkfjmhfcoghlddibiopnfmco"
          >谷歌应用商店 </el-link
        >安装插件。
      </p>
      <p>
        tips2:
        如果已装插件但是多次刷新仍提示未安装，请尝试移除原插件后再重新安装。
      </p>
    </div>

    <template slot="footer">
      <div>
        <el-checkbox
          :value="notShowInThisWeek"
          style="float: left"
          @change="notShowCheckboxChanged"
        >
          一周内不再提示
        </el-checkbox>
        <el-button size="small" type="primary" @click="confirmPluginCheckDialog"
          >确 定</el-button
        >
      </div>
    </template>
    <div>
      <video-player-dialog
        video-path="https://assets.maoniux.com/videos/%E6%8F%92%E4%BB%B6%E6%95%99%E7%A8%8B.mp4"
        :dialog-visible.sync="showVideoPlayerDialog"
        :append-to-body="true"
      ></video-player-dialog>
    </div>
  </el-dialog>
</template>

<script>
// import videoPlayerDialog from "@/pages/product/views/video-bank/dialog/videoPlayerDialog.vue";
import videoPlayerMixin from "@/common/mixins/video-player-mixin";
import resultExtra from "./result-extra.vue";
import { pluginCourseUrl } from "@/common/js/config";
import { dialogMixin } from "@/common/js/mixin";
import { mapGetters } from "vuex";

export default {
  name: "PluginDialog",
  components: { resultExtra },
  mixins: [dialogMixin, videoPlayerMixin],
  props: {
    notShowInThisWeek: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pluginCourseUrl,
      activeName: "state",
      dialogWidth: "45%",
      dialogTop: "10vh",
      // type: "install",
    };
  },
  computed: {
    ...mapGetters(["currentPluginVersion", "newPluginVersion"]),
    // install or update

    type() {
      if (!this.currentPluginVersion) {
        return "install";
      } else if (this.currentPluginVersion < this.newPluginVersion) {
        return "update";
      }
      return "";
    },
    invalidPluginState() {
      return (
        !this.currentPluginVersion ||
        this.currentPluginVersion < this.newPluginVersion
      );
    },
  },
  methods: {
    pluginCourseCollapsed(activeName) {
      if (activeName == "course") {
        this.dialogWidth = "75%";
        this.dialogTop = "5vh";
      } else {
        this.dialogWidth = "40%";
        this.dialogTop = "10vh";
      }
    },
    notShowCheckboxChanged(val) {
      this.$emit("update:notShowInThisWeek", val);
    },
    confirmPluginCheckDialog() {
      this.$emit("confirmPluginCheckDialog");
    },
  },
};
</script>

<style lang="stylus" scoped>

.plugin-dialog-wrapper {
  .el-link {
    vertical-align: baseline;
  }
}
.plugin-install-course-div {
  p {
    line-height: 1.5rem;
    margin: 0.25rem 0;


  }

  .operate-image-popover {
    float: right
    // font-size: 12px;
    // margin-left: 3rem;
    // white-space: nowrap;

    .el-link {
      font-size: 12px
    }
  }

}
</style>
