// import { getRange } from "@/common/js/utils";
import time from "@/common/js/time";
import moment from "moment";

export const JS_TYPE = {
  NULL: "[object Null]",
  OBJECT: "[object Object]",
  ARRAY: "[object Array]",
  DATE: "[object Date]",
};

export const COLOR_TYPE = {
  DEFAULT: "primary",
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
  INFO: "info",
  ERROR: "error",
};

export const COLOR = {
  blue: "#409EFF",
  succ: "#67C23A",
  err: "#F56C6C",
  warn: "#E6A23C",
  info: "#909399",
};

export const ERR = "400";

export const WARN_MSG = "请至少选择一项";
export const NOT_ALL_WARN_MSG = "不支持全选";

export const SUCC_MSG = "执行成功";
export const PEER_KEYWORD_SUCC_MSG = "收集同行关键词成功";
export const ERR_MSG = "执行失败";

export const DETAIL_TEMPLATE_INFO =
  "图片数量超出限制，最多上传15张图片, 最多50000字";

export const website = "https://www.maoniunet.com/software";
export const websiteDocument =
  "教学文档：" + "https://docs.qq.com/doc/DREFpQWFKbGhwQ3Ft";

// 操作文档
export const userCourseUrl =
  "https://www.yuque.com/docs/share/f725cd2f-1f50-4f08-b364-1ae096579271";

// 产品发布文档
export const productCourseUrl = "https://www.yuque.com/yogurt/kb/cnskku?#L3vYY";
// export const pluginCourseUrl =
//   "https://docs.qq.com/doc/p/426a652d3f2fe5a76ed97e2e232dff78f99253e7?dver=3.0.27536109";
export const pluginCourseUrl =
  "https://www.yuque.com/yogurt/kb/zx2usuzlwmmdm61o?singleDoc#%20%E3%80%8A%E6%93%8D%E7%9B%98%E6%89%8B%E6%8F%92%E4%BB%B6%E5%AE%89%E8%A3%85/%E6%9B%B4%E6%96%B0%E6%96%B9%E6%B3%95%E3%80%8B";
export const buySoftwareUrl = `https://fuwu.alibaba.com/product/buy.htm?code=ISVXX5B0105`;
export const AI_SPIRIT_URL =
  "https://docs.qq.com/doc/p/b44106ac0e97250c9b52e276d9bf9a0413fb5c4d?dver=3.0.27575334";
export const serviceQQ = 3045453334;
export const serviceWeChat = "maoniuchaoren";
export const YU_QUE_URL_PREFIX =
  "https://www.yuque.com/docs/share/e84742c5-f2a1-42df-a883-06eaf5fee07f?#";

// 语雀新版使用教程链接
// 整体发品逻辑
export const PRODUCT_DOCUMENT_URL =
  "https://www.yuque.com/docs/share/f725cd2f-1f50-4f08-b364-1ae096579271#lMl4a";
// 关键词收集添加
export const KEYWORD_COLLECT_URL =
  "https://www.yuque.com/docs/share/f725cd2f-1f50-4f08-b364-1ae096579271#TeQFR";

export const SASS_LOGIN_URL =
  "https://saas.maoniunet.com/login?system=superman";

export const OEM_URL = "https://ma.maoniux.com/api/v1/companies/oem";

export const OEM_REPORT_URL = `${OEM_URL}/report`;

// 布尔通用类型
export const BOOLEAN = [
  { label: "是", value: true },
  { label: "否", value: false },
];

// 全选 全不选
export const CHOOSE = [
  { label: "本页选中", value: "current" },
  { label: "本页反选", value: "inverse" },
  { label: "全部选择", value: "all" },
  { label: "取消选择", value: "cancel" },
];

// 匹配度
export const MATCH_RATE = [
  { label: "top100", value: "HIGH" },
  { label: "可用", value: "MID" },
  { label: "待判定", value: "NONE" },
];

// 热度
export const HEAT = [
  { label: "大于1", value: "ONE_MORE" },
  { label: "1", value: "ONE" },
  { label: "0", value: "EMPTY" },
];

// 星级
export const QS_STAR = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];
export const KEYWORD_QS_STAR = [
  { label: "零星", value: 0 },
  { label: "一星", value: 1 },
  { label: "二星", value: 2 },
  { label: "三星", value: 3 },
  { label: "四星", value: 4 },
  { label: "五星", value: 5 },
  { label: "未推广", value: -1 },
];

// 排名
// const RANK_NUMBER = getRange(1, 20, true);
export const RANK = [
  { label: "第一页", value: 1 },
  { label: "第二页", value: 2 },
  { label: "第三页", value: 3 },
  { label: "非首页", value: -2 },
  { label: "未查询", value: -3 },
  { label: "无排名", value: 0 },
  { label: "查询失败", value: -1 },
  { label: "今日未查询", value: -9 },
];

const HOT_WORD = "hotWord";
const P4P_SEARCH = "p4pSearch";
const MY_WORD = "myWord";
// 词收集
export const WORD_COLLECTION = [
  { label: "关键词指数热门搜索词", value: HOT_WORD },
  { label: "p4p搜索相关词", value: P4P_SEARCH },
  { label: "引流关键词", value: MY_WORD },
];

// 推广状态
export const STATUS = [
  { label: "启动", value: true },
  { label: "暂停", value: false },
];

// 时段选择
export const INTERVAL = [
  { label: "最近 30 天", value: 30 },
  { label: "最近 7 天", value: 7 },
  { label: "最近 1 天", value: 1 },
];

export const NEW_RULE_NAME = "new";
export const STOP_RULE_NAME = "stop";
export const STAY_RULE_NAME = "stay";

export const P4P_RULE = [
  { label: "不竞价", value: STAY_RULE_NAME },
  { label: "暂停推广", value: STOP_RULE_NAME },
  { label: "新规则", value: NEW_RULE_NAME },
];

export const P4P_RESERVE_PRICE = 0;
export const P4P_RULE_RANK = [
  { label: "第一名", value: 1 },
  { label: "第二名", value: 2 },
  { label: "第三名", value: 3 },
  { label: "第四名", value: 4 },
  { label: "第五名", value: 5 },
  { label: "底价", value: P4P_RESERVE_PRICE },
];

export const P4P_RULE_OPERATOR = [
  { label: "加", value: "+" },
  { label: "减", value: "-" },
  { label: "乘", value: "*" },
];

// 每个月几周
const shortcuts = time.getWeekOfMonth().map((arr, index) => {
  return {
    text: `第${index + 1}周`,
    onClick(picker) {
      picker.$emit("pick", arr);
    },
  };
});
// const fourWeeks = time.getFourWeeksAgo().map((arr) => {
//   let startDay = time.getFormatDate(arr[0]);
//   let endDay = time.getFormatDate(arr[1]);
//   const option = {
//     value: `${startDay} 至 ${endDay}`,
//     label: `${startDay} 至 ${endDay}`,
//   };
//   return option;
// });
export const DATE_RANG = {
  shortcuts: shortcuts,
};
// export const FOUR_WEEK_RANG = {
//   ...fourWeeks,
// };

// 星期一 ~ 星期天
export const WEEK = [
  { label: "星期一", value: 1 },
  { label: "星期二", value: 2 },
  { label: "星期三", value: 3 },
  { label: "星期四", value: 4 },
  { label: "星期五", value: 5 },
  { label: "星期六", value: 6 },
  { label: "星期日", value: 7 },
];

export const ALIBABA_IMG_LINK = "1";
export const GOOGLE_IMG_LINK = "2";
export const IMG_LINK = [
  { label: "国际站搜索", value: ALIBABA_IMG_LINK },
  { label: "谷歌图片搜索", value: GOOGLE_IMG_LINK },
];

export const PERFERENTIAL = [
  { label: "已优推", value: true },
  { label: "未优推", value: false },
];

export const KEYWORD_CHANNEL = [
  { label: "数据管家-热门搜索词", value: true, key: HOT_WORD },
  { label: "数据关键词-引流关键词", value: true, key: MY_WORD },
  { label: "直通车-p4p搜索词", value: true, key: P4P_SEARCH },
];

export const PRODUCT_TYPE = {
  SOURCING_FOB: "SOURCING_FOB",
  SOURCING_LADDER: "SOURCING_LADDER",
  WHOLESALE_LADDER: "WHOLESALE_LADDER",
  WHOLESALE_SKU: "WHOLESALE_SKU",
  MIX_TYPE: "MIX_TYPE",
};
// 最近7天
export const startWeek = moment().subtract(8, "days").format("YYYY-MM-DD");

//最近30天
export const startMonth = moment().subtract(1, "month").format("YYYY-MM-DD");

//当前月减2天
export const end = moment().subtract(2, "days").format("YYYY-MM-DD");

export const SHORTCUTS = {
  shortcuts: [
    {
      text: "最近一周",
      onClick(picker) {
        picker.$emit("pick", [startWeek, end]);
      },
    },
    {
      text: "最近一个月",
      onClick(picker) {
        picker.$emit("pick", [startMonth, end]);
      },
    },
    {
      text: "最近三个月",
      onClick(picker) {
        const start = moment().subtract(3, "month").format("YYYY-MM-DD");
        picker.$emit("pick", [start, end]);
      },
    },
  ],
};

export const OPTIONS_TIMES = [
  { label: `${startWeek}~${end}`, value: `${startWeek}~${end}` },
  { label: `${startMonth}~${end}`, value: `${startMonth}~${end}` },
];

export const PRODUCT_AND_PRICE_TYPE = [
  {
    value: "SOURCING_FOB",
    label: "Customization 区间价",
    text: "Customization 区间价",
  },
  {
    value: "SOURCING_LADDER",
    label: "Customization 阶梯价",
    text: "Customization 阶梯价",
  },
  {
    value: "SOURCING_SKU",
    label: "Customization SKU价",
    text: "Customization SKU价",
  },
  { value: "WHOLESALE_LADDER", label: "RTS 阶梯价", text: "RTS 阶梯价" },
  { value: "WHOLESALE_SKU", label: "RTS SKU价", text: "RTS SKU价" },
];

export const PRODUCT_SOURCE_TYPE = [
  // { value: "all", label: "全部", text: "全部" },
  { value: "WEB_PRODUCT", label: "采集品", text: "采集品" },
  // { value: "NEW_PRODUCT", label: "新发品", text: "新发品" },
  // { value: "OLD_PRODUCT", label: "老产品类似", text: "老产品类似" },
  { value: "ZERO_EFFECT_PRODUCT", label: "零效果", text: "零效果" },
];
