<template>
  <el-dialog
    :visible="p_dialogVisible"
    :width="width"
    :append-to-body="appendToBody"
    top="5vh"
    @close="closeDialog"
  >
    <div style="padding: 0.5rem">
      <video-player
        ref="videoPlayer"
        class="video-player vjs-custom-skin"
        :playsinline="true"
        :options="playerOption"
      >
      </video-player>
      <h2 style="text-align: center; margin: 0.5rem 0" v-html="videoName"></h2>
    </div>
  </el-dialog>
</template>

<script>
import { dialogMixin } from "../../../../../common/js/mixin";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "VideoPlayerDialog",
  components: {
    videoPlayer,
  },
  mixins: [dialogMixin],
  props: {
    videoPath: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "70%",
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    videoName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sources: "",
    };
  },
  computed: {
    playerOption() {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: this.sources,
          },
        ],
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      };
    },
  },
  watch: {
    videoPath: {
      handler: function (Val) {
        this.sources = Val;
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog() {
      this.$refs?.videoPlayer?.player?.pause();
      this.p_dialogVisible = false;
    },
  },
};
</script>

<style scoped></style>
