import request from "common/utils/request";

const WEEKLY_DATA = {
  // 获取数据报表数据
  getMyDataList(params) {
    return request({
      url: `/api/v1/mydatas`,
      method: "get",
      params,
    });
  },
  // 导出excel
  exportExcel(data) {
    return request({
      url: "/api/v1/mydatas/exportExcel",
      method: "post",
      data,
      responseType: "blob",
    });
  },
  // 新增数据报表数据
  postMyDataListByShopId(shopId, data, params) {
    return request({
      url: `/api/v1/mydatas/${shopId}/plugins`,
      method: "post",
      data,
      params,
    });
  },
  postMyDataList() {
    return request({
      url: `/api/v1/mydatas`,
      method: "post",
    });
  },
  // 距离上次同步的月份
  getPreviousMonthsNum() {
    return request({
      url: `/api/v1/mydatas/previousMonths/num`,
      method: "get",
    });
  },
};
export default WEEKLY_DATA;
