export const SET_ACCOUNT = "SET_ACCOUNT";

export const SET_ACCOUNT_DIAGNOSIS = "SET_ACCOUNT_DIAGNOSIS";

export const SET_GLOBAL_LOAD = "SET_GLOBAL_LOAD";

export const SET_MARKETING_APP = "SET_MARKETING_APP";

export const SET_CURRENT_PLUGIN_VERSION = "SET_CURRENT_PLUGIN_VERSION";

export const SET_NEW_PLUGIN_VERSION = "SET_NEW_PLUGIN_VERSION";
