import request from "common/utils/request";

const api = {
  // 获取产品详情图片
  getPhoto(params) {
    return request({
      url: "/api/v1/published-products/description-images",
      method: "get",
      params,
    });
  },
  // 替换产品详情图片
  replacePhoto(data) {
    return request({
      url: "/api/v1/published-products/description-images",
      method: "put",
      data,
    });
  },
  // 替换产品详情文字
  replaceText(data) {
    return request({
      url: "/api/v1/published-products/detail/text",
      method: "put",
      data,
    });
  },
  //替换详情图片链接
  replacePhotoLink(data) {
    return request({
      url: "/api/v1/published-products/description-images-links",
      method: "put",
      data,
    });
  },
  //替换详情图片为代码
  replacePhotoToCode(data) {
    return request({
      url: "/api/v1/published-products/description-images-code",
      method: "put",
      data,
    });
  },
  //替换详情
  replaceDescription(data) {
    return request({
      url: "/api/v1/published-products/description",
      method: "put",
      data,
    });
  },
  //部分替换
  replacePortion(data) {
    return request({
      url: "/api/v1/published-products/description-except-product",
      method: "put",
      data,
    });
  },
};

export default api;
