import types from "./router-types";

export const DATA_REPORT_ROUTES = [
  {
    name: "数据",
    path: "data-report.html",
    children: [
      types.REPORT,
      types.INDUSTRY_HOT_INQUIRIES,
      types.INDUSTRY_HOT_PRODUCTS,
      types.HOT_PRODUCTS_REPORT,
      types.WEEKLY_DATA,
    ],
  },
];
