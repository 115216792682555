import request from "common/utils/request";

const api = {
  // 查询分组列表
  getList(params) {
    return request({
      url: "api/v1/product-group",
      method: "get",
      params,
    });
  },
  // 产品子分组信息
  getChild(params) {
    return request({
      url: "api/v1/product-group/child",
      method: "get",
      params,
    });
  },
  // 同步产品分组
  sync() {
    return request({
      url: "/api/v1/product-group/sync",
      method: "post",
    });
  },
};

export default api;
