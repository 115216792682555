<template>
  <el-dialog
    top="4vh"
    custom-class="setting-dialog"
    title="全局设置"
    :visible.sync="dialogLoad"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleOpen"
  >
    <div class="dialog-content">
      <div class="text-align-center">
        <!-- <img :src="supermanLogo" width="100" height="100" /> -->
      </div>
      <div class="center-wrapper">
        <div class="setting-wrapper">
          <el-form
            ref="ruleForm"
            :model="originAccount"
            :rules="rules"
            label-width="160px"
          >
            <el-form-item label="联系方式">
              <div>
                <el-button
                  v-if="!isContactFlag"
                  :size="size"
                  @click="isContactFlag = true"
                >
                  显示
                </el-button>

                <el-button v-else :size="size" @click="isContactFlag = false">
                  隐藏
                </el-button>
              </div>
              <div
                v-if="
                  !isContactFlag &&
                  !originAccount.telephone &&
                  !originAccount.qq
                "
                style="color: darkorange"
              >
                请留下具体操作人员的联系方式，方便后续工作人员跟进指导。也可添加客服微信:
                maoniuchaoren，添加时请注明店铺ID：{{ originAccount.nick }}
              </div>
            </el-form-item>

            <el-collapse-transition>
              <div v-show="isContactFlag" class="contact">
                <el-form-item label="公司名" prop="cnName">
                  <el-input
                    v-model="originAccount.cnName"
                    :size="size"
                  ></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="originAccount.name"
                    :size="size"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="telephone">
                  <el-input
                    v-model="originAccount.telephone"
                    disabled
                    :size="size"
                  ></el-input>
                  <template #label>
                    <span>
                      手机
                      <el-tooltip
                        effect="light"
                        :content="telephoneTip"
                        placement="top"
                      >
                        <mu-question></mu-question>
                      </el-tooltip>
                    </span>
                  </template>
                </el-form-item>
              </div>
            </el-collapse-transition>

            <el-form-item label="账号">
              <span>{{ account.nick }}</span>
            </el-form-item>

            <!-- <el-collapse-transition>
              <div class="contact">
                <el-form-item
                  label="密码"
                  prop="password"
                >
                  <el-input
                    :size="size"
                    v-model="originAccount.password"
                    show-password
                    placeholder="默认为123456"></el-input>
                </el-form-item>
              </div>
            </el-collapse-transition> -->

            <el-form-item prop="hyperlinkHost">
              <el-radio-group v-model="originAccount.hyperlinkHost">
                <el-radio
                  v-for="imgLink in imgLinkOption"
                  :key="imgLink.value"
                  :label="imgLink.value"
                >
                  {{ imgLink.label }}
                </el-radio>
              </el-radio-group>

              <template #label>
                关键词跳转判定
                <el-tooltip
                  effect="light"
                  :content="imgLinkTip"
                  placement="top"
                >
                  <mu-question></mu-question>
                </el-tooltip>
              </template>
            </el-form-item>
            <el-form-item label="关键词归一词逻辑" prop="useNormalization">
              <el-switch
                v-model="originAccount.useNormalization"
                active-text="启用"
                inactive-text="不启用"
              >
              </el-switch>
            </el-form-item>
            <!-- <el-form-item label="对标同行">
              <el-input
                v-model="originAccount.sameIndustryUrls"
                type="textarea"
                :rows="5"
                :placeholder="placeholder"
              ></el-input>
            </el-form-item> -->
            <el-form-item prop="effectInquiry">
              <template #label>
                有效商机
                <el-tooltip
                  effect="light"
                  :content="effectInquiry"
                  placement="top"
                >
                  <mu-question></mu-question>
                </el-tooltip>
              </template>
              <!-- <el-button v-if="isShowChange" size="mini" @click="handleChange">
                显示
              </el-button>
              <el-button v-else size="mini" @click="handleChange">
                隐藏
              </el-button> -->
              <div class="effect" style="display: inline-block">
                <el-checkbox v-model="effectFeedbackConfigData.isUserNewLevel1">
                  L1+
                </el-checkbox>
                <span>或</span>
                <el-checkbox v-model="effectFeedbackConfigData.isUserNewLevel3">
                  L3
                </el-checkbox>
                <span>或</span>
                <el-checkbox v-model="effectFeedbackConfigData.isUserNewLevel4">
                  L4
                </el-checkbox>
                <!-- <span>或</span> -->
                <!-- <el-checkbox
                      v-model="
                        effectFeedbackConfigData.isShowSecondResponseRate
                      "
                      >二次回复</el-checkbox
                    > -->
                <span>或</span>
                <el-checkbox v-model="effectFeedbackConfigData.isShowFlagMark">
                  红色旗标
                </el-checkbox>
                <!-- <el-row>
                      <el-input
                        v-model="effectFeedbackConfigData.count"
                        onkeyup="value=value.replace(/[^\d]/g,'')"
                        size="mini"
                        style="width: 10vw !important"
                        placeholder="最小起订量大于等于"
                      >
                        数量
                      </el-input>
                    </el-row> -->
              </div>
            </el-form-item>
            <div style="margin-left: 5rem">
              <el-tabs v-model="tabs.activeCountryName">
                <el-tab-pane
                  v-for="item of optionsCountry"
                  :key="item.label"
                  :label="item.label"
                  :name="item.name"
                >
                  <el-checkbox
                    class="checkbox-item"
                    label="全选"
                    @change="(v) => selectAll(item.options, v)"
                  ></el-checkbox>
                  <el-checkbox-group
                    v-model="effectFeedbackConfigData.chooseCountry"
                    class="checkbox-group"
                  >
                    <country-tabs
                      :options-country="item.options"
                    ></country-tabs>
                  </el-checkbox-group>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button :size="size" @click="hide(false)">关 闭</el-button>
        <el-button :size="size" :type="defaultColor" @click="submitForm"
          >保 存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import axios from "@/common/js/ajax";
import {
  COLOR_TYPE,
  IMG_LINK,
  KEYWORD_CHANNEL,
  WEEK,
} from "@/common/js/config";
import countries from "@/common/js/country";
import notify from "@/common/js/notify";
import { deepCopy, isDef, isJson } from "@/common/js/utils";
import MuQuestion from "@/components/mu-question/mu-question";
import Api from "api";
import _ from "lodash";
import { mapGetters, mapMutations } from "vuex";
import { SASS_LOGIN_URL } from "../../common/js/config";
import countryTabs from "./country-tabs.vue";

const HOUR = new Array(25).fill(0).map((k, index) => {
  const label = index + "";
  return {
    label: `${label.padStart(2, 0)}:00`,
    value: index,
  };
});

export default {
  components: { MuQuestion, countryTabs },
  props: {},
  data() {
    return {
      size: "mini",
      defaultColor: COLOR_TYPE.DEFAULT,

      switchColor: "#13ce66",
      dialogLoad: false,

      originAccount: {
        diagnosisRunning: false,
        id: null,
        init: false,
        // sameIndustryUrls: "",
        keywordChannel: {
          hotWord: true,
          myWord: true,
          p4pSearch: true,
        },
        cnName: null,
        name: "",
        nick: null,
        hyperlinkHost: "1",
        password: "",
        qq: "",
        weChat: "",
        telephone: "",
        photoBankGroup: null,
        ignoredPhotoBankGroup: null,
        effectFeedbackConfig: "",
        useNormalization: true,
      },
      effectFeedbackConfigData: {
        isUserNewLevel1: true,
        isUserNewLevel3: true,
        isUserNewLevel4: true,
        isShowSecondResponseRate: false,
        isShowFlagMark: true,
        chooseCountry: [],
        count: null,
      },
      coreWordList: [],
      rules: {
        cnName: [
          {
            required: true,
            message: "请输入公司名",
            trigger: ["blur"],
            validator: this.contactValidator,
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["blur"],
            validator: this.contactValidator,
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: this.validatePass,
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur"],
            validator: this.contactValidator,
          },
          // {
          //   pattern:
          //     /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/,
          //   trigger: ["blur"],
          //   message: "请输入正确的手机号",
          // },
        ],
        qq: [
          {
            required: true,
            message: "请输入QQ",
            trigger: ["blur"],
            validator: this.contactValidator,
          },
        ],
        weChat: [
          {
            required: true,
            message: "请输入WeChat",
            trigger: ["blur"],
            validator: this.contactValidator,
          },
        ],
        hyperlinkHost: [
          {
            required: true,
            message: "请选择关键词跳转链接",
            trigger: ["blur"],
          },
        ],
        keywordChannel: [
          {
            required: true,
            trigger: ["blur"],
            validator: this.keywordChannelValidator,
          },
        ],
        useNormalization: [
          {
            required: true,
            trigger: ["blur"],
            message: "请选择是否启动归一词逻辑",
          },
        ],
      },

      telephoneTip:
        "请留下联系方式，方便客服人员与您联系，协助您快速上手使用软件",
      accountTip: "p4p推广状态",
      imgLinkTip:
        "【关键词库】界面点击关键词可自动跳转搜索结果，用于帮助判断关键词可用与否。若有翻墙，建议选择谷歌图片。",
      ignoreGroupHint: "同步图片银行信息时，将不会抓取以下分组的图片",
      coreWordTip:
        "核心词: 某类产品的最核心的叫法, 用于收集该类别所有的关键词, 一般由 1-2 个单词组成\n品类: 可做产品的大类, 核心词大致相同",
      placeholder:
        "请输入对标同行首页网址，一行一个，最多五个，以回车符进行分隔。\n",
      // 是否显示联系方式
      isContactFlag: false,
      isShowFlag: false,
      isAccountSettingFlag: false,
      photoBankGroupOptions: [],
      p4pMatchRate: "NONE",
      modelForm: "",
      optionsCountry: countries,
      optionsCountryCopy: null,
      count: "",
      isShowInquiry: true,
      isShowChange: true,
      effectInquiry:
        "在我的店铺360复盘报告及软件店铺周数据记录中将按此规则记录每周有效商机数，其中买家层级和国家是“且”的关系，需同时满足才计为有效商机。",
      chatUtilOptions: [
        { label: "QQ", value: "QQ" },
        { label: "WeChat", value: "WeChat" },
      ],
      chartMode: "QQ",
      tabs: {
        activeCountryName: "NorthAmerica",
      },
    };
  },
  computed: {
    globalInit() {
      return this.account.init;
    },
    getRegQq() {
      const reg = /^\d*$/;
      return reg.test(this.originAccount.qq) || !this.originAccount.qq;
    },
    getRegWeChat() {
      const reg = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
      return reg.test(this.originAccount.weChat) || !this.originAccount.weChat;
    },
    supermanLogo() {
      return this.marketingApp["superman"]?.softwareLogo;
    },
    getLoginUrl() {
      return `${SASS_LOGIN_URL}&oem=${this.account.belongsCorp}`;
    },
    ...mapGetters(["account", "marketingApp"]),
  },
  watch: {},

  created() {
    this.init();
    // console.log(this.originAccount);
  },

  mounted() {
    this.optionsCountryCopy = _.cloneDeep(this.optionsCountry);
  },

  methods: {
    selectAll(items, v) {
      const values = items.map((m) => m.label);
      if (v) {
        this.effectFeedbackConfigData.chooseCountry.push(...values);
      } else {
        this.effectFeedbackConfigData.chooseCountry =
          this.effectFeedbackConfigData.chooseCountry.filter(
            (i) => !values.includes(i)
          );
      }
    },
    init() {
      this.weekOption = WEEK;
      this.hourOption = HOUR;
      this.imgLinkOption = IMG_LINK;
      this.keywordChannelOption = KEYWORD_CHANNEL;
    },
    reset() {
      this.originAccount = deepCopy(this.account);
    },
    // 保存账号信息
    changeAccount() {
      const url = Api.putUserStatus;
      const data = this.originAccount;
      data.vip = false;
      const effectFeedbackConfigCopy = this.effectFeedbackConfigData;
      const checkList = Object.values(this.optionsCountry)
        .map((m) => m.options)
        .flat()
        .filter((i) =>
          this.effectFeedbackConfigData.chooseCountry.some((s) => s === i.label)
        );
      this.effectFeedbackConfigData.chooseCountry = checkList.map(
        (i) => i.value
      );
      data.effectFeedbackConfig = JSON.stringify(effectFeedbackConfigCopy);
      return axios({
        method: "put",
        url: url,
        data,
      }).then(() => {
        this.setAccount(data);
      });
    },
    submitForm() {
      if (!this.checkSameIndustryUrlValid()) {
        return;
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.saveAllFormData();
        } else {
          return false;
        }
      });
    },
    show() {
      this.dialogLoad = true;
      this.originAccount = deepCopy(this.account);
    },
    showBusiness() {
      this.dialogLoad = true;
      this.isShowInquiry = true;
      this.originAccount = deepCopy(this.account);
    },
    hide() {
      this.reset();
      this.dialogLoad = false;
    },
    handleOpen() {
      if (
        this.originAccount.effectFeedbackConfig &&
        isJson(this.originAccount.effectFeedbackConfig)
      ) {
        const effectFeedbackConfigStr = JSON.parse(
          this.originAccount.effectFeedbackConfig
        );
        if (effectFeedbackConfigStr) {
          effectFeedbackConfigStr.chooseCountry = Object.values(
            this.optionsCountry
          )
            .map((m) => m.options)
            .flat()
            .filter((i) =>
              effectFeedbackConfigStr.chooseCountry.some((s) => s === i.value)
            )
            .map((i) => i.label);
          this.effectFeedbackConfigData = effectFeedbackConfigStr;
          this.effectFeedbackConfigData.chooseCountry = [
            ...new Set(this.effectFeedbackConfigData.chooseCountry),
          ];
        }
      }
    },
    handleClose(done) {
      if (!this.globalInit) {
        this.warn();
        return;
      }
      this.reset();
      done();
    },
    warn() {
      notify.warn("第一次使用, 需要填写相关信息!");
    },
    contactValidator(rule, value, callback) {
      if (!isDef(value)) {
        if (!this.isContactFlag) {
          this.isContactFlag = true;
        }
        callback(new Error(rule.message));
      } else {
        callback();
      }
    },
    validatePass(rule, value, callback) {
      if (value && value.length < 6) {
        callback(new Error("密码不能小于6位"));
      }
      callback();
    },
    keywordChannelValidator(rule, value, callback) {
      const { hotWord, myWord, p4pSearch } = value;
      if (!hotWord && !myWord && !p4pSearch) {
        callback(new Error("请至少选择一个核心词收集渠道"));
      } else {
        callback();
      }
    },
    async saveAllFormData() {
      this.setGlobalLoad(true);
      try {
        await this.changeAccount();
        location.reload();
        notify.succ("全局设置保存成功");
      } catch (e) {
        console.error(e);
        notify.err("全局设置保存失败");
      } finally {
        this.hide();
        this.setGlobalLoad(false);
        this.$emit("finish");
      }
    },
    checkSameIndustryUrlValid() {
      if (this.originAccount.sameIndustryUrls) {
        const arr = this.originAccount.sameIndustryUrls
          .split("\n")
          .map((item) =>
            item.substring(0, item.indexOf(".com") + ".com".length)
          )
          .filter((f) => f);
        const status = arr.every(
          (i) => i.startsWith("http") || i.startsWith("https")
        );
        if (!status) {
          notify.warn("对标同行网址必须以http或https开头");
          return false;
        }
        const aliUrl = "www.alibaba.com";
        const isAliUrl = arr.some((i) => i.includes(aliUrl));
        if (isAliUrl) {
          notify.warn(`对标同行不支持${aliUrl}域名`);
          return false;
        }
        this.originAccount.sameIndustryUrls = arr.join("\n");
      }
      return true;
    },
    handleChange() {
      this.isShowInquiry = !this.isShowInquiry;
      this.isShowChange = !this.isShowChange;
    },
    ...mapMutations({
      setAccount: "SET_ACCOUNT",
      setAccountDiagnosis: "SET_ACCOUNT_DIAGNOSIS",
      setGlobalLoad: "SET_GLOBAL_LOAD",
    }),
  },
};
</script>

<style lang="stylus">
.setting-dialog
  min-width 60rem
  .dialog-content
    .image-wrapper
      display flex
      justify-content center
      align-items center
    .center-wrapper
      padding $space 4rem
</style>

<style lang="stylus">
.dialog-content
  .el-dialog.setting-dialog
    width 60%

  .el-select {
    width: auto !important;
  }

  .el-input {
    width: auto
  }

  .select-width
    width: 90px !important;
    margin-right 10px

 .effect {
   .el-checkbox {
     margin-right 8px
   }
   span {
     margin-left 5px
   }
 }
</style>
