import { covertToPermission } from "@/ability/utils";
import { deepCopy } from "@/common/js/utils";

const abilityTemp = {
  index: {
    aside: {
      indexTask: true,
    },
    intelligentDiagnosis: {},
    indexTask: {
      content: true,
    },
    weeklyData: {},
    peerAnalysis: {},
    selectionAnalysis: {},
    conversionAnalysis: {},
  },
};

export const ability = covertToPermission(deepCopy(abilityTemp)).permission;

// export { ability };
export default abilityTemp;
