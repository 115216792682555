<template>
  <div style="padding: 1rem">
    <div v-for="result of handleResult" :key="result.index">
      <div
        v-if="result.handleResultData.showcaseRemoveCount"
        class="record-tiem"
      >
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          由于
          <span
            v-for="item of result.handleResultData.showcaseRemoveCount"
            :key="item.index"
            >" {{ item }} " ,</span
          >
          橱窗产品效果较差，当前已移除，以便空出橱窗位给数据较好的产品。
          （执行时间：{{ result.handleResultTime.showcaseRemoveCount }}）
        </div>
      </div>
      <div v-if="result.handleResultData.showcaseAddCount" class="record-tiem">
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          将
          <span
            v-for="item of result.handleResultData.showcaseAddCount"
            :key="item.index"
            >" {{ item }} " ,</span
          >
          设置为橱窗产品 （执行时间：{{
            result.handleResultTime.showcaseAddCount
          }}）
        </div>
      </div>
      <div v-if="result.handleResultData.budgetUsedRate">
        <div
          v-if="result.handleResultData.budgetUsedRate.rate < 0.5"
          class="record-tiem"
        >
          <div class="record-item-title">
            <i class="header-icon el-icon-success"></i>
            当前出价上限较低（检测时间：{{
              result.handleResultTime.budgetUsedRate
            }}），预计无法满足每日预算执行率达到80%以上
          </div>
          <div class="record-item-content">
            <span
              v-for="item of result.handleResultData.budgetUsedRate"
              :key="item.index"
            >
              <i class="el-icon-star-on"></i>
              现已将 " {{ item.ruleName }} " 出价策略上限提高至
              {{ item.price }} 元。
              <br />
            </span>
          </div>
        </div>
        <div
          v-if="result.handleResultData.budgetUsedRate.rate > 0.8"
          class="record-tiem"
        >
          <div class="record-item-title">
            <i class="header-icon el-icon-success"></i>
            当前出价上限较高（检测时间：{{
              result.handleResultTime.budgetUsedRate
            }}），使得日预算过快消耗，预计无法推广20小时以上覆盖目标国家的上班时间。
          </div>
          <div class="record-item-content">
            <span
              v-for="item of result.handleResultData.budgetUsedRate"
              :key="item.index"
            >
              <i class="el-icon-star-on"></i>
              现已将 " {{ item.ruleName }} " 出价策略上限降低为
              {{ item.price }} 元。
              <br />
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="result.handleResultData.potentialProductCount"
        class="record-tiem"
      >
        <div
          v-for="item of result.handleResultData.potentialProductCount"
          :key="item.index"
        >
          <div class="record-item-title">
            <i class="header-icon el-icon-success"></i>
            将潜力产品 " {{ item.subject }} " 设置关键词优先推广（执行时间：{{
              result.handleResultTime.potentialProductCount
            }}）。
          </div>
          <div class="record-item-content">
            <span v-for="word of item.keyword" :key="word.index"
              >{{ word }} |</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="result.handleResultData.lowConversionProductCount"
        class="record-tiem"
      >
        <div
          v-for="item of result.handleResultData.lowConversionProductCount"
          :key="item.index"
          class="record-tiem"
        >
          <div class="record-item-title">
            <i class="header-icon el-icon-success"></i>
            将低转化产品 " {{ item.subject }} " 取消以下关键词的优先推广。
            （执行时间：{{
              result.handleResultTime.lowConversionProductCount
            }}）
          </div>
          <div class="record-item-content">
            <span v-for="keyword of item.keyword" :key="keyword.index"
              >{{ keyword }} |</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="result.handleResultData.abnormalKeywordCount"
        class="record-tiem"
      >
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          以下 关键词
          p4p推广异常，异常引流导致点击率下降或反馈率下降，目前已自动暂停推广(执行时间：{{
            result.handleResultTime.abnormalKeywordCount
          }})
        </div>
        <div class="record-item-content">
          <span
            v-for="item of result.handleResultData.abnormalKeywordCount"
            :key="item.index"
            >{{ item }} |</span
          >
        </div>
      </div>
      <div
        v-if="result.handleResultData.abnormalProductCount"
        class="record-tiem"
      >
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          以下 产品
          p4p推广异常，异常引流导致点击率下降，目前已自动暂停推广。(执行时间：{{
            result.handleResultTime.abnormalProductCount
          }})
        </div>
        <div class="record-item-content">
          <span
            v-for="item of result.handleResultData.abnormalProductCount"
            :key="item.index"
            >{{ item }} |</span
          >
        </div>
      </div>
      <div
        v-if="result.handleResultData.showcaseKeywordCount"
        class="record-tiem"
      >
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          现已挑选以下关键词作为重点关注。(执行时间：{{
            result.handleResultTime.showcaseKeywordCount
          }})
        </div>
        <div class="record-item-content">
          <span
            v-for="item of result.handleResultData.showcaseKeywordCount"
            :key="item.index"
            >{{ item }} |</span
          >
        </div>
      </div>
      <div
        v-if="result.handleResultData.highMidNoPromotionKeywordCount"
        class="record-tiem"
      >
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          存在
          {{ result.handleResultData.highMidNoPromotionKeywordCount.length }}
          个精准/可用匹配度关键词未加入直通车推广。现已将以下关键词加入直通车推广（执行时间：{{
            result.handleResultTime.highMidNoPromotionKeywordCount
          }}）
        </div>
        <div class="record-item-content">
          <span
            v-for="item of result.handleResultData
              .highMidNoPromotionKeywordCount"
            :key="item.index"
            >{{ item }} |</span
          >
        </div>
      </div>
      <div v-if="result.handleResultData.refreshProduct" class="record-tiem">
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          自动刷新
          {{ result.handleResultData.refreshProduct.length }}
          个产品（执行时间：{{ result.handleResultTime.refreshProduct }}）
        </div>
        <div class="record-item-content">
          <span
            v-for="item of result.handleResultData.refreshProduct"
            :key="item.index"
            >{{ item }} |</span
          >
        </div>
      </div>
      <div
        v-if="result.handleResultData.refreshPromotionProduct"
        class="record-tiem"
      >
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          自动调整
          {{ result.handleResultData.refreshPromotionProduct.length }}
          个推广产品（执行时间：{{
            result.handleResultTime.refreshPromotionProduct
          }}）
        </div>
        <div class="record-item-content">
          <span
            v-for="item of result.handleResultData.refreshPromotionProduct"
            :key="item.index"
          >
            {{ item }} |
          </span>
        </div>
      </div>
      <div
        v-if="result.handleResultData.updateKeywordMatchRateMid"
        class="record-tiem"
      >
        <div class="record-item-title">
          <i class="header-icon el-icon-success"></i>
          根据排名情况已将下列关键词判定可用（执行时间：{{
            result.handleResultTime.updateKeywordMatchRateMid
          }}，共{{
            result.handleResultData.updateKeywordMatchRateMid.length
          }}个)：
        </div>
        <div class="record-item-content">
          <div>
            <span
              v-for="item of result.handleResultData.updateKeywordMatchRateMid.slice(
                0,
                50
              )"
              :key="item.index"
              >{{ item }} |</span
            >
          </div>
          <span
            v-if="result.handleResultData.updateKeywordMatchRateMid.length > 50"
          >
            <el-button type="text" @click="exportKeyword(result)"
              >导出更多关键词</el-button
            >
          </span>
        </div>
      </div>
    </div>
    <div class="text-align-center">
      <el-pagination
        layout="prev, pager, next"
        :total="totalSize"
        :page-size="size"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <div v-if="haveResults" class="text-align-center">暂无记录</div>
  </div>
</template>
<script>
// import { isDef } from "@/common/js/utils";
import time from "@/common/js/time";
import axios from "@/common/js/ajax";
import Api from "api";
import { dataMixin } from "@/common/js/mixin";
import { useStreamExportExcel } from "@/common/js/common";
import { mapGetters } from "vuex";

export default {
  mixins: [dataMixin],
  props: {},
  data() {
    return {
      diagnosis: "",
      handleResult: {},
      handleResultData: {},
      handleResultTime: {},
      totalSize: 0,
      size: 5,
      currentPage: 1,
      haveResults: false,
    };
  },
  computed: {
    ...mapGetters(["account"]),
  },
  created() {
    this.init();
  },

  mounted() {},

  methods: {
    init() {
      this.search();
    },
    originSearch() {
      const query = `?current=${this.currentPage}&size=${this.size}`;
      const url = Api.getDiagnosisHandleResult + query;
      axios({
        method: "get",
        url: url,
      })
        .then((res) => {
          if (!res.records) {
            this.haveResults = true;
          }
          this.handleResult = {};
          this.dataFormatConversion(res.records);
          this.totalSize = res.total;
          this.currentPage = res.current;
        })
        .catch(() => {});
    },
    dataFormatConversion(model) {
      model.forEach((result, index) => {
        let key = Object.keys(result);
        this.handleResultData = {};
        this.handleResultTime = {};
        key.forEach((item) => {
          let value = result[item];
          this.handleResultData[item] = value.data;
          if (value.rate) {
            this.handleResultData.budgetUsedRate.rate = value.rate;
          }
          this.handleResultTime[item] = time.getFormatDateTime(value.time);
        });
        this.handleResult[index] = {
          handleResultData: this.handleResultData,
          handleResultTime: this.handleResultTime,
        };
      });
    },
    exportKeyword(result) {
      const keywords = result.handleResultData.updateKeywordMatchRateMid;
      axios({
        url: "/api/v1/keywords/excel-stream-by-name",
        method: "post",
        data: keywords,
        responseType: "arraybuffer",
      }).then((res) => {
        useStreamExportExcel(res, "关键词", this.account.nick);
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.header-icon {
  color: $succ;
}

.record-tiem {
  margin-bottom: 1rem;
}

.record-item-content {
  color: $info;
  width: 95%;
  margin: 0 auto;
  line-height: 1.3rem;
}

.record-item-title {
  line-height: 2rem;
  font-size: 0.9rem;
}
</style>
