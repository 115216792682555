<!-- by LinHuiMing -->

<template>
  <div class="mu-aside">
    <el-aside class="aside" width="auto">
      <slot></slot>
      <el-menu
        :default-active="currentPath"
        :default-openeds="defaultOpen"
        class="menu"
      >
        <div v-for="item in data" :key="item.name">
          <el-submenu
            v-if="item.hasChild"
            :key="item.name"
            :index="item.path"
            class="submenu-item"
          >
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>

            <el-menu-item
              v-for="child in item.children"
              :key="child.name"
              :index="getCurrentPath(item, child)"
              class="menu-item"
            >
              <a
                :class="getClass(child)"
                :href="getCurrentPath(item, child)"
                @click="selectItem(getCurrentPath(item, child))"
              >
                <span v-if="child.tip">
                  <i :class="child.icon"></i>
                  {{ child.name }}
                  <el-tooltip
                    effect="light"
                    :content="child.tip"
                    placement="right"
                  >
                    <template #content>
                      <div
                        v-for="(t, index) in child.tip.split('\n')"
                        :key="index"
                      >
                        {{ t }}
                      </div>
                    </template>
                    <mu-question></mu-question>
                  </el-tooltip>
                </span>
                <span v-else>
                  <i :class="child.icon"></i>
                  <span>{{ child.name }}</span>
                </span>
              </a>
            </el-menu-item>
          </el-submenu>

          <el-menu-item
            v-else
            :key="item.name"
            :index="getCurrentPath(routes[0], item)"
            class="menu-item"
          >
            <a
              :class="getClass(item)"
              :href="getCurrentPath(routes[0], item)"
              @click="selectItem(getCurrentPath(routes[0], item))"
            >
              <span v-if="item.tip">
                {{ item.name }}
                <el-tooltip effect="light" placement="right">
                  <template #content>
                    <div
                      v-for="(t, index) in item.tip.split('\n')"
                      :key="index"
                    >
                      {{ t }}
                    </div>
                  </template>

                  <mu-question></mu-question>
                </el-tooltip>
              </span>
              <span v-else>
                <span v-if="item.isNew && new Date() < new Date(item.till)">
                  <span v-if="item.isDot">
                    <i :class="item.icon"></i>
                    <span>{{ item.name }}</span>
                    <el-badge is-dot type="success" class="newDot"> </el-badge>
                  </span>
                  <span v-else>
                    <i :class="item.icon"></i>
                    <span>{{ item.name }}</span>
                    <el-badge
                      v-if="item.isNew"
                      value="New"
                      class="newBadge"
                      type="success"
                    >
                    </el-badge>
                  </span>
                </span>
                <span v-else>
                  <i :class="item.icon"></i>
                  <span>{{ item.name }}</span>
                </span>
              </span>
            </a>
          </el-menu-item>
        </div>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
import { isArrayLength } from "@/common/js/utils";
import MuQuestion from "components/mu-question/mu-question";
import ElBadge from "element-ui/packages/badge/src/main";

export default {
  components: {
    ElBadge,
    // Scroll,
    MuQuestion,
  },

  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: () => "",
    },
    showChild: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      data: [],
      defaultOpen: [],
    };
  },

  computed: {
    currentPath() {
      return `${location.pathname}#${this.$route.path}`.substring(1);
    },
  },
  watch: {},

  created() {
    if (isArrayLength(this.routes)) {
      if (!this.showChild) {
        this.data = this.routes[0].children;
      } else {
        const children = this.routes.map((route) => {
          const child = route.children;
          const obj = { ...route };
          this.defaultOpen.push(route.path);
          if (child) {
            obj.children = child.slice();
            obj.hasChild = true;
          } else {
            obj.hasChild = false;
          }
          return obj;
        });
        this.data = children;
      }
    }
  },

  mounted() {},

  methods: {
    getClass(item) {
      if (item.active) {
        return "link-active";
      } else {
        return "";
      }
    },
    selectItem(path) {
      location.href = path;
    },
    getCurrentPath(item, child) {
      const { active } = child;
      if (active) {
        return `index.html#${child.path}`;
      }
      return `${item.path}#${child.path}`;
    },
  },
};
</script>

<style lang="stylus" scoped>
.aside {
  // width: 150px !important;

  .menu {
    border-right: none;
    background-color: transparent;

    .menu-item {
      overflow: hidden;

      &:hover {
        background-color: transparent;
        cursor: pointer;
        color: $color;
      }

      &:focus {
        background-color: transparent;
      }

      &.is-active {
        a {
          color: $color;
        }
      }

      a {
        display: block;
        transition: 0.3s;
        // padding: 0 1rem;

        &:hover {
          color: $color;
        }
      }
    }
  }

  .link-active {
    color: $err !important;
  }
}
.newBadge {
  margin-left: 5px
  margin-bottom: 10px
}
.newDot{
  margin-top: 0
  margin-left: 0
}

.el-menu-item i, .el-menu-item.is-active i {
  color: $blue;
}
//@media screen and (max-width: 1440px) {
//  .mu-aside {
//    display none !important
//  }
//}
</style>
