import Axios from "@/common/js/ajax";

const baseUrl = "/api/v1/loginStatus";

const LoginStatusApi = {
  login(data) {
    return Axios({
      url: baseUrl,
      method: "post",
      data,
    });
  },
  logOut() {
    return Axios({
      url: baseUrl,
      method: "delete",
    });
  },
};
export default LoginStatusApi;
