import time from "@/common/js/time";
import { isDef, isNaN } from "@/common/js/utils";
import { P4P_RULE_OPERATOR } from "@/common/js/config";

export function findMatch(val, option) {
  const rest = option.filter((o) => {
    return o.value === val;
  });
  return rest.length ? rest[0].label : "";
}

export function formatDate(val) {
  return time.getFormatDate(val);
}

export function formatDateTime(val) {
  return time.getFormatDateTime(val);
}

export function formatDateTimeWithoutSecond(val) {
  let formatVal = time.getFormatDateTime(val, true);
  return formatVal;
}

export function formatBool(val) {
  if (!isDef(val)) return "";
  return val ? "是" : "否";
}

export function formatStatus(val) {
  if (!isDef(val)) return "";
  return val ? "启动" : "暂停";
}

export function formatPrecent(val) {
  val = val - 0;
  if (isNaN(val)) return "";
  return (val * 100).toFixed(2) + "%";
}

export function formatOperator(val) {
  const operator = P4P_RULE_OPERATOR.filter((o) => o.value === val);
  return operator.length ? operator[0].label : "";
}

export function filterInputVal(value, separator) {
  const keys = Reflect.ownKeys(value);
  let strList = [];
  keys.forEach((key) => {
    if (!key.includes("_")) {
      const val = value[key];
      if (isDef(val)) {
        strList.push(`${val}`);
      }
    }
  });
  return strList.join(separator);
}

/**
 * Formats a small image URL by appending the desired size to the URL.
 *
 * @param {string} url - The URL of the image.
 * @param {number} size - The desired size of the image. Defaults to 100.
 * @return {string} The formatted image URL.
 */
export function formatSmallImage(url, size = 100) {
  if (!url || url.includes("cdn.translate")) {
    return url;
  } else {
    return url + `_${size}x${size}.` + url.split(".").reverse()[0];
  }
}
