<template>
  <el-dialog :visible="p_dialogVisible" width="600px" @close="closeDialog">
    <div class="content">
      <el-row type="flex">
        <el-col :span="10">
          <div class="logo">
            <img src="@/assets/images/seat.png" width="200px" />
          </div>
        </el-col>
        <el-col :span="14">
          <div class="tip-text">
            我们为您准备了视频教程,如果这是您第一次使用的话，建议您先观看下哦
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button :size="size" @click="closeDialog">先不看</el-button>
        <el-button type="primary" :size="size" @click="toVideoCoursePage"
          >前往学习</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { dialogMixin } from "@/common/js/mixin";

export default {
  name: "ToVideoGuideDialog",
  mixins: [dialogMixin],
  data() {
    return {
      size: "small",
    };
  },
  methods: {
    toVideoCoursePage() {
      this.closeDialog();
      window.open(location.origin + "/index.html#/video-course", "_blank");
    },
  },
};
</script>

<style lang="stylus" scoped>
.content{
  .tip-text{
    padding-top 50px
    font-size 22px
  }
}
</style>
