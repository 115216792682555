import WEEKLY_DATA from "./weekly-data";
import reportConclusion from "./report-conclusion";
import reportDetail from "./report-detail";
import hotRankProduct from "./hot-rank-product";

export default {
  ...WEEKLY_DATA,
  ...reportConclusion,
  ...reportDetail,
  ...hotRankProduct,
};
