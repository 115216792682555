<!-- by LinHuiMing -->

<template>
  <div class="tabs">
    <div class="tabs-wrapper">
      <div class="left">
        <icon class="icon" name="bars" @click.native="showAside"></icon>
        <img class="software-icon" src="@/assets/images/AI.jpg" height="35px" />
        <div style="margin-left: 0.5rem">
          <el-popover
            ref="popperRef"
            class="item"
            trigger="hover"
            :disabled="isBasicType"
            effect="light"
            placement="bottom"
            popper-class="handle-history-popover"
          >
            <div class="handle-slot">
              <div
                v-for="item in childHandle"
                :key="item.name"
                class="second-handle"
              >
                <el-switch
                  v-if="item.seen"
                  v-model="item.status"
                  active-color="#13ce66"
                  inactive-color="#DCDFE6"
                  :disabled="item.disabled"
                  :active-text="item.content"
                  @change="handleChildChange"
                >
                </el-switch>
                <el-badge
                  v-if="item.badgeTip"
                  :value="item.badgeTip"
                  type="success"
                ></el-badge>
              </div>
              <el-popover
                title="自动优化记录"
                trigger="hover"
                width="600"
                placement="right"
                popper-class="handle-history-popover"
              >
                <div style="max-height: 360px; overflow: auto">
                  <processing-records></processing-records>
                </div>
                <template #reference>
                  <div style="text-align: center; margin: 0.25rem 0">
                    <el-button :size="size">优化记录</el-button>
                  </div>
                </template>
              </el-popover>
            </div>

            <template #reference>
              <el-switch
                v-model="handleStatus"
                :disabled="isBasicType"
                active-color="#13ce66"
                inactive-color="#DCDFE6"
                active-text="AI优化"
                :width="30"
                style="font-size: 12px"
                @change="modifyStatus('handleStatus')"
              >
              </el-switch>
              <mu-tooltip :content="getAiOptimizeContent"></mu-tooltip>
            </template>
          </el-popover>
        </div>
        <!--  方便切换测试账号 -->
        <div v-if="isDev" style="margin-left: 0.5rem">
          <el-select
            v-model="testAccount"
            placeholder="测试账号"
            :size="size"
            style="width: 100px"
            @change="changeDevelopUser"
          >
            <el-option
              v-for="item in developTestAccounts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="logo-wrapper">
        <!-- <img src="@/assets/images/logo_text.png" height="35px"> -->
        <!-- <span>外贸超人</span> -->
      </div>

      <div class="list-wrapper">
        <ul class="list">
          <li
            v-for="(item, index) in computedData"
            :key="item.name"
            class="item"
          >
            <a
              v-if="item.href"
              :class="{ active: index === currentIndex }"
              :href="item.href"
              :target="item.target"
            >
              <el-menu mode="horizontal">
                <el-menu-item v-if="item.name.startsWith('AI首页')">{{
                  item.name
                }}</el-menu-item>
                <el-menu-item v-else-if="item.name.startsWith('教程')">{{
                  item.name
                }}</el-menu-item>

                <el-submenu v-else :index="currentIndex + ''" text-color="#fff">
                  <template #title>
                    <!-- <span v-if="item.name == '橱窗'">
                      <span :class="{ 'title-active': index === currentIndex }">
                        {{ item.name }}
                        <el-badge
                          value="即将下线"
                          class="newBadge"
                          type="warning"
                        >
                        </el-badge>
                      </span>
                    </span> -->
                    <span>
                      <span
                        :class="{ 'title-active': index === currentIndex }"
                        >{{ item.name }}</span
                      >
                    </span>
                  </template>
                  <div
                    v-for="(child, index2) in getRouteData(item)"
                    :key="index2"
                  >
                    <el-menu-item :index="getCurrentPath(item, child)">
                      <a :href="getCurrentPath(item, child)">{{
                        child.name
                      }}</a>
                    </el-menu-item>
                  </div>
                </el-submenu>
              </el-menu>
            </a>
          </li>
        </ul>
      </div>

      <div class="right">
        <!-- <el-tooltip effect="light" placement="bottom" content="自动优化记录">
          <i
            :size="size"
            :disabled="isBasicType"
            @click="status.recordDialogVisible = true"
            class="el-icon-chat-dot-round"
          ></i>
        </el-tooltip> -->

        <div class="use-guide">
          <!-- <el-popover
            class="item"
            effect="light"
            placement="bottom"
            width="250"
            trigger="hover"
          >
            <div class="online-service">
              <p style="color: #409eff">有任何问题或需求请联系我们</p>
              <p>工作日8:30～12:00，13:30～18:00</p>
              <img
                style="width: 9rem; height: 9rem"
                :src="SERVICE_QRCODE"
                alt="暂无图片"
              />
            </div>
            <template #reference>
              <div style="display: flex; vertical-align: middle">
                客服<img
                  style="color: grey; margin-left: 4px"
                  src="@/assets/images/wechat-logo.png"
                  width="20"
                />
              </div>
            </template>
          </el-popover> -->
          <span @click="openCoursePage('plugin')">
            插件
            <el-tooltip
              effect="light"
              placement="top"
              :disabled="!pluginPrompt"
              :content="pluginPrompt"
            >
              <i
                v-if="pluginPrompt"
                class="el-icon-warning orange-color"
                :class="
                  pluginPrompt.indexOf('建议更新') > 0 ? 'active-color' : ''
                "
              >
              </i>
              <i v-else class="el-icon-success green-color"></i>
            </el-tooltip>
          </span>
          <!-- <span @click="openCoursePage('course')">使用教程</span> -->
        </div>
        <!--        <el-popover-->
        <!--          placement="bottom"-->
        <!--          width="260"-->
        <!--          trigger="click">-->
        <!--          <div>-->
        <!--            <el-divider>常用功能</el-divider>-->
        <!--            <div class="tools-group">-->
        <!--              <div class="tools-item" @click="handleTools">-->
        <!--                <i class="el-icon-suitcase icon"></i>-->
        <!--                <span class="title">工具箱</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div #reference>-->
        <!--            <i-->
        <!--              style="margin-right: 1rem;color: #409EFF"-->
        <!--              class="el-icon-menu"></i>-->
        <!--          </div>-->
        <!--        </el-popover>-->

        <el-popover
          placement="bottom"
          width="260"
          trigger="click"
          popper-class="user-right-info-popover-wrapper"
        >
          <div class="popover-info">
            <div class="popover-info-top">
              <div class="version">{{ getUsernameBadge }}</div>
              <span class="nick">{{ nick }}</span>
              <div class="vip-time-group">
                <div class="vip-time-left">
                  <span>到期时间</span>
                  <span>{{ erpEndDate || paymentEndDate }}</span>
                </div>
                <div class="vip-time-right">
                  <el-tag size="mini" @click="openCoursePage('buy')"
                    >续费</el-tag
                  >
                </div>
              </div>
            </div>
            <div class="popover-info-bottom">
              <div class="dropdown-list">
                <ul>
                  <li @click="showCreditUsage">
                    <!-- <el-popover
                      placement="left"
                      width="300"
                      trigger="click"
                      @show="handleShow"
                    >
                      <use-count-info
                        :ai-use-count-info="aiUseCountInfo"
                        :ai-use-count-limit-info="aiUseCountLimitInfo"
                      ></use-count-info>
                      <template #reference>
                        <div>
                          <p>
                            <i class="el-icon-document"></i>
                            <span>使用明细</span>
                          </p>
                        </div>
                      </template>
                    </el-popover> -->
                    <div>
                      <p>
                        <i class="el-icon-document"></i>
                        <span>使用明细</span>
                      </p>
                    </div>
                  </li>
                  <li @click="showSetting">
                    <p>
                      <i class="el-icon-setting"></i>
                      <span>全局设置</span>
                    </p>
                  </li>
                  <!-- <li @click="handleCourse">
                    <p>
                      <i class="el-icon-discover"></i>
                      <span>使用教程</span>
                    </p>
                  </li> -->
                  <li @click="handleInviter">
                    <p>
                      <i class="el-icon-circle-plus-outline"></i>
                      <span>邀请有礼</span>
                    </p>
                  </li>
                  <li @click="handleLogOut">
                    <p>
                      <i class="el-icon-switch-button"></i>
                      <span>退出账号</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <template #reference>
            <div class="popover-item">
              <i style="margin-right: 0.2rem" class="el-icon-user"></i>
              <span>{{ nick }}</span>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
    <invite-users :dialog-visible.sync="status.showInviteUsers"></invite-users>
    <!-- <el-dialog
      :visible.sync="status.recordDialogVisible"
      width="40%"
      title="处理记录"
      append-to-body
    >
      <processing-records></processing-records>
    </el-dialog> -->
    <plugin-dialog
      :dialog-visible.sync="showPluginUpgradeInfo"
      :not-show-in-this-week.sync="notShowInThisWeek"
      @confirmPluginCheckDialog="confirmPluginCheckDialog"
    ></plugin-dialog>
  </div>
</template>

<script>
import developApi from "@/api/developer";
import Api from "@/api";
import Axios from "@/common/js/ajax";
import {
  getCurrentPluginVersion,
  getNewPluginVersion,
} from "@/common/js/chromeExtension";
import {
  SASS_LOGIN_URL,
  buySoftwareUrl,
  pluginCourseUrl,
  serviceQQ,
  serviceWeChat,
  userCourseUrl,
} from "@/common/js/config";
import notify from "@/common/js/notify";
import {
  getAiUseCountInfo,
  getAiUseCountLimitInfo,
} from "@/common/utils/aiUseLimitUtils";
import InviteUsers from "@/components/container/dialog/invite-users";
import processingRecords from "@/pages/index/view/intelligent-diagnosis/processing-records.vue";
import {
  SET_CURRENT_PLUGIN_VERSION,
  SET_NEW_PLUGIN_VERSION,
} from "@/store/mutation-types";
import _ from "lodash";
import moment from "moment";
import LoginStatusApi from "pages/login/api";
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/bars";
import { mapGetters, mapMutations } from "vuex";
import pluginDialog from "./plugin-dialog/index.vue";
// import useCountInfo from "./use-count-info.vue";
import { SERVICE_QRCODE } from "@/common/js/const";
import { ROUTER_TYPES as INDEX_ROUTER_TYPES } from "@/pages/index/router/router-types";

export default {
  name: "AppTabs",
  components: {
    pluginDialog,
    InviteUsers,
    // FreeAiGuide,
    // MuNumber,
    // ElBadge,
    Icon,
    processingRecords,
    // useCountInfo,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      SERVICE_QRCODE,
      size: "mini",
      showPluginUpgradeInfo: false,
      pluginCourseUrl,

      notShowInThisWeek: false,
      nick: "",
      website: "",
      currentIndex: 0,
      accountType: "",
      paymentEndDate: "",
      erpEndDate: "",
      serviceTip:
        "QQ：2354817798\nWeChat：aicaopanshou2022\n工作日8:30～12:00，13:30～18:00。",
      globalMessages: [
        "sadasdasdasdas",
        "asaaaaaaa",
        "asdaasdasdasd",
        "asdsdadadd",
      ],
      testAccount: "",
      aiUseCountInfo: {},
      aiUseCountLimitInfo: {},
      defaultOpened: [],
      customStyle: {
        color: "red",
      },
      status: {
        showInviteUsers: false,
        showProductQuotationImageDialog: false,
        showBuyAiPackageDialog: false,
        recordDialogVisible: false,
      },
      no_limit: "不限",

      pluginPrompt: "",
      qq: serviceQQ,
      weChat: serviceWeChat,
      locationPathname: "",
      handleStatus: false,

      childHandle: [
        {
          name: "refreshProduct",
          status: false,
          disabled: false,
          seen: true,
          content: "自动刷新产品保持店铺活跃度",
        },
        {
          name: "refreshPromotionProduct",
          status: false,
          disabled: false,
          // forceDisabled: true,
          badgeTip: "New",
          seen: true,
          content: "自动优化推广产品",
        },
      ],

      developTestAccounts: [
        { label: "xmfengmao", value: "xmfengmao" },
        { label: "达磊", value: "xmdlstone" },

        { label: "老乐博", value: "cnpvcwindows" },
        { label: "宁夏圣宇", value: "nxsyf" },
        { label: "福州弘和", value: "fzhonghe" },
        { label: "胜德", value: "shengdecn" },
        { label: "胜德A2", value: "shengdeyoga" },
        { label: "宝恒晟", value: "bohanson" },
        // { label: "中博瑞", value: "ipackrich" },
        // { label: "硅宜能", value: "mekhome" },
        // { label: "永明亮", value: "xmyml" },
        { label: "恒创", value: "handschain" },
        { label: "标科汽车", value: "aecoauto" },
        { label: "聚美工艺", value: "decorwellarts" },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "currentPluginVersion",
      "newPluginVersion",
      "isDev",
      "isBasicType",
      "isPlusType",
    ]),

    currentChildHandle() {
      let options = {};
      this.childHandle
        .filter((ch) => ch.seen)
        .forEach((ch) => {
          options[ch.name] = ch.status;
        });
      return options;
    },
    getAiOptimizeContent() {
      if (this.isBasicType) {
        return "当前版本不支持该功能，请联系客服升级为 Plus 版本";
      }
      return "当前版本为 Plus 版本，支持 AI 优化";
    },
    computedData() {
      return this.data;
    },
    getUsernameBadge() {
      if (this.isBasicType) {
        return "基础版";
      }
      if (this.isPlusType) {
        return "Plus 版";
      }
      return "试用版";
    },
    getServiceQQHref() {
      return `tencent://message/?uin=${serviceQQ}&site=qq&menu=yes`;
    },
    ...mapGetters(["account"]),
  },

  watch: {
    $route() {
      this.locationPathname = location.pathname;
    },
    currentPluginVersion() {
      const date = localStorage.getItem("notShowPluginCheckDate");
      if (date) {
        const diffDays = moment().diff(moment(date), "days");
        if (diffDays >= 7) {
          localStorage.removeItem("notShowPluginCheck");
          localStorage.removeItem("notShowPluginCheckDate");
          this.showPluginUpgradeInfo = true;
        } else {
          this.showPluginUpgradeInfo = false;
        }
      } else {
        if (
          !this.currentPluginVersion ||
          this.currentPluginVersion < this.newPluginVersion
        ) {
          this.showPluginUpgradeInfo = true;
        }
      }
    },
  },

  async created() {
    const { nick, accountType, paymentEndDate, erpEndDate } =
      this.$store.state.account;
    this.nick = nick;
    this.accountType = accountType;
    this.paymentEndDate = paymentEndDate;
    this.erpEndDate = erpEndDate;
    this.currentIndex = this.active;
    this.getHandleStatus();
    this.notShowPluginCheck();
    await this.getPluginPromptContent();
  },

  mounted() {},

  methods: {
    ...mapMutations({
      setCurrentPluginVersion: SET_CURRENT_PLUGIN_VERSION,
      setNewPluginVersion: SET_NEW_PLUGIN_VERSION,
    }),
    showCreditUsage() {
      if (location.href?.includes("index.html")) {
        this.$router.push({
          path: INDEX_ROUTER_TYPES.CREDIT_USAGE,
        });
      } else {
        location.href = "/index.html#" + INDEX_ROUTER_TYPES.CREDIT_USAGE;
      }
    },
    changeDevelopUser(val) {
      developApi.updateCurrentUser(val).then(() => {
        location.reload();
      });
    },
    notShowPluginCheck() {},
    showAside() {
      this.$emit("translate");
    },
    showSetting() {
      this.$emit("globalConfig");
    },
    showSoftFeedback() {
      this.$emit("softFeedback");
    },
    getCurrentPath(item, child) {
      const { active } = child;
      if (active) {
        return `index.html#${child.path}`;
      }
      return `${item.href}#${child.path}`;
    },
    getRouteData(item) {
      if (item.name === "关键词") {
        item.href = "/keyword.html";
      }
      const childS = Object.values(item.children);
      return childS;
    },
    getTooltipContent(index) {
      if (index === 4) {
        return "旧版关键词推广在此页面进行相关操作与数据查看。";
      }
      if (index === 5) {
        return "新版关键词推广、智能搜索推广、推荐推广等在词页面进行相关操作与数据查看。";
      }
    },
    getDisabled(index) {
      const arr = [0, 1, 2, 3];
      if (arr.includes(index)) {
        return true;
      }
      return false;
    },
    openCoursePage(type) {
      if (type === "plugin") {
        this.showPluginUpgradeInfo = true;
        // return window.open(pluginCourseUrl, "blank");
      }
      if (type === "course") {
        return window.open(
          location.origin + "/index.html#/video-course",
          "_blank"
        );
      }
      if (type === "buy") {
        return window.open(buySoftwareUrl, "blank");
      }
    },
    handleLogOut() {
      this.$confirm("是否确认退出当前账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          LoginStatusApi.logOut().then(() => {
            location.replace(SASS_LOGIN_URL);
          });
        })
        .catch(() => {});
    },
    async handleShow() {
      this.aiUseCountInfo = await getAiUseCountInfo();
      this.aiUseCountLimitInfo = await getAiUseCountLimitInfo();
    },
    handleTools() {
      location.replace(location.origin + "/tools.html#/tools");
    },
    handleCourse() {
      window.open(userCourseUrl, "_blank");
    },
    handleInviter() {
      this.status.showInviteUsers = true;
    },
    async getPluginPromptContent() {
      const newPluginVersion = await getNewPluginVersion().then((res) => {
        this.setNewPluginVersion(res);
        return res;
      });
      const currentPluginVersion = await getCurrentPluginVersion().then(
        (res) => {
          this.setCurrentPluginVersion(res);
          return res;
        }
      );
      if (!currentPluginVersion) {
        this.pluginPrompt = "未安装";
        return;
      }
      this.diffPluginVersion(currentPluginVersion, newPluginVersion);
    },
    diffPluginVersion(currentPluginVersion, newPluginVersion) {
      let currentPluginVersionCopy = _.cloneDeep(currentPluginVersion);
      let newPluginVersionCopy = _.cloneDeep(newPluginVersion);
      currentPluginVersionCopy = currentPluginVersionCopy.split(".").join("");
      newPluginVersionCopy = newPluginVersionCopy.split(".").join("");
      if (Number(currentPluginVersionCopy) < Number(newPluginVersionCopy)) {
        this.pluginPrompt = `当前版本 ${currentPluginVersion}, 建议更新到 ${newPluginVersion}`;
      }
    },
    confirmPluginCheckDialog() {
      if (this.notShowInThisWeek) {
        localStorage.setItem("notShowPluginCheck", this.notShowInThisWeek);
        localStorage.setItem("notShowPluginCheckDate", moment().toISOString());
      }
      this.showPluginUpgradeInfo = false;
    },
    modifyChildHandle(url, data) {
      Axios({
        method: "put",
        url: url,
        data: data,
      })
        .then(() => {
          this.getHandleStatus();
        })
        .catch(() => {
          notify.err({ message: "修改失败" });
          this.getHandleStatus();
        });
    },
    modifyStatus(type) {
      let url;
      if (type === "handleStatus") {
        url = Api.putDiagnosisHandleStatus.replace(
          "{status}",
          this.handleStatus
        );
        if (!this.handleStatus) {
          this.childHandle.forEach((ch) => {
            ch.status = false;
          });
        }
      }
      // 控制大开关
      this.modifyChildHandle(url);
      this.$refs.popperRef.setExpectedState(true);
    },
    handleChildChange() {
      const closeAll = this.childHandle.some((op) => op.status);
      if (!closeAll) {
        this.handleStatus = false;
      } else if (!this.handleStatus) {
        this.handleStatus = true;
      }
      this.modifyChildHandle(
        Api.getDiagnosisHandleOptions,
        this.currentChildHandle
      );
    },
    getHandleStatus() {
      const url = Api.getDiagnosisHandle;
      Axios({
        method: "get",
        url: url,
      })
        .then((res) => {
          this.handleStatus = res.status;
          const options = res.options;
          this.childHandle.forEach((ch) => {
            const option = options[ch.name];
            if (option != null) {
              ch.seen = true;
            }
          });
          if (this.handleStatus) {
            this.childHandle.forEach((ch) => {
              ch.status = options[ch.name];
              ch.disabled = ch.forceDisabled || false;
            });
          } else {
            this.childHandle.forEach((ch) => {
              ch.status = false;
              ch.disabled = true;
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style style="stylus">
.handle-history-popover {
  padding: 1rem 1rem 0 1rem !important;
}
</style>

<style lang="stylus" scoped>
.tabs
  position fixed
  z-index 10
  top 0
  left 0
  right 0
  background-color white
  .tabs-wrapper
    width 100%
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px #ebeef5 solid
    position relative
    padding 0 1rem
    .left
      display flex
      align-items center
      .icon
        display none
        &:hover
          cursor pointer
    .logo-wrapper
      display none
      align-items center
      color $color-text-active
      span
        padding 0 $space
    .list-wrapper
      .list
        display flex
        justify-content center
        .item
          position relative
          padding 0
          list-style none
          >>>.el-menu--horizontal
            border-bottom 0
          >>>.el-submenu__title
            border-bottom 0
          &:hover
            cursor pointer
            color $color-text-active
          a
            padding 0
            &.active
                color $color-text-active
                &:after
                  content ''
                  position absolute
                  width 60%
                  height 3px
                  background-color $color
                  left 50%
                  bottom 0.3rem
                  transform translate(-50%)
                .title-active
                  color $color
                >>>.el-icon-arrow-down
                  color $color

    .right {
      display flex
      justify-content center
      align-items center
      font-size 1rem
      .use-guide {
        display flex
        justify-content center
        align-items center
        margin-right 1rem
        cursor pointer
        span {
          margin-left 0.75rem
          vertical-align middle
          .orange-color {
            color $err
          }
          .active-color {
            color $warn
          }
          .green-color {
            color $succ
          }
        }
      }
      .popover-item {
        display flex
        justify-content center
        align-items center
        margin-right 1rem
        cursor pointer
      }
    }
@media screen and (max-width: $md-screen) {
  .tabs {
    .tabs-wrapper {
      .left {
        .icon {
          display block
        }
      }
      .logo-wrapper {
        display: block
      }

      .software-icon, .list-wrapper, .right span {
        display none
      }
    }
  }
}
</style>
<style lang="stylus">

.user-right-info-popover-wrapper {
  padding: 0 !important;
}
</style>
<style lang="stylus" scoped>
.el-popover {
  text-align center
  .title-header {
    //margin-top .5rem
  }
  p {
    line-height 28px
    margin .25rem 0
    cursor pointer
    span {
      margin 0 .1rem
    }
    i {
      color $prim
    }
  }
  p:hover {
    background-color #F5F6F8
  }
  .title-footer {
    display flex
    justify-content space-between
    align-items center
    padding 1rem 0
  }

 .global-message {
   .message-tab {
     margin .5rem 1rem 0
     h4 {
       background linear-gradient(50deg, #1890ff, #41b584, #6954f0)
       -webkit-background-clip text
       color transparent
     }
   }
 }
 .popover-info {
   .popover-info-top {
     background: linear-gradient(50deg, #41b584, #1890ff);
     border-top-left-radius .5rem
     border-top-right-radius .5rem
     .nick {
       line-height 28px
       margin .8rem 0 0 .5rem
       font-size 1.2rem
       font-weight 500
       white-space nowrap
       color white
     }
     .version {
       width 100px
       height 25px
       line-height 25px
       text-align center
       color white
       background linear-gradient(50deg, #1890ff, #6954f0)
       border-top-left-radius .5rem
       border-bottom-right-radius .5rem
     }
     .vip-time-group {
       display flex
       justify-content space-between
       align-items center
       hight 40px
       margin .5rem
       padding 0 1rem
       font-size .8rem
       color white
       background linear-gradient(50deg, #1890ff, #6954f0)
       border-top-left-radius .5rem
       border-top-right-radius .5rem
       cursor pointer
       .vip-time-left {
         display flex
         flex-direction column
         justify-content center
         align-items flex-start
         height 40px
         padding-top 5px
       }
     }
   }
   .popover-info-bottom {
     .dropdown-list {
       margin 1rem
     }
   }
 }
//  .tools-group {
//    display flex
//    justify-content flex-start
//    align-items center
//    flex-wrap wrap
//    padding .5rem
//    .tools-item {
//      display flex
//      flex-direction column
//      justify-content center
//      align-items center
//      width 120px
//      height 120px
//      border 1px #F5F6F8 solid
//      cursor pointer
//      transition all .5s
//      .icon {
//        font-size 2rem
//        color #409EFF
//      }
//    }
//    .tools-item:hover {
//      border 1px #409EFF solid
//    }
//  }
}
.online-service {
  display flex
  flex-direction column
  justify-content center
  align-items center
  flex-wrap wrap
}

.handle-slot {
  // width: 400px
  // background-color: #f1f1f163

  .top-handle {
    text-align: center
    margin: 0.5rem auto
  }

  .second-handle {
    margin: 0.5rem 2rem
  }
}
</style>
