import Axios from "@/common/js/ajax";

const collectProducts = {
  listCollectProductRecords(params) {
    return Axios({
      url: "/api/v1/collect/products/record",
      params,
    });
  },
};

export default collectProducts;
