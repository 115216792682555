import request from "@/common/utils/request";

export const getCreditUsageDetail = (params) => {
  return request({
    url: `/api/v1/users/credit/detail`,
    method: "get",
    params,
  }).then((res) => res?.data);
};

export const getCreditUsage = () => {
  return request({
    url: `/api/v1/users/credit`,
    method: "get",
  }).then((res) => res?.data);
};
